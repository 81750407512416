import React from "react";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import Button from "app/components/Button";
import { SubscriptionStatus } from "services/graphql";
import { usePrimarySubscription } from "hooks/Subscriptions/usePrimarySubscription";
import { useSubscriptionReactivate } from "app/routes/Account/Subscription/hooks";
import styles from "./styles.module.scss";

export const ReactivateSubscriptionFlow = () => {
  const {
    primarySubscription,
    loadingPrimarySubscription,
    refetchPrimarySubscription,
  } = usePrimarySubscription();

  const {
    subscriptionReactivate,
    subscriptionReactivateResults,
  } = useSubscriptionReactivate({
    onCompleted() {
      refetchPrimarySubscription();
    },
  });

  if (loadingPrimarySubscription) {
    return <LoaderCentered />;
  }

  const {
    id: subscriptionId,
    status: subscriptionStatus,
    plan,
  } = primarySubscription;
  const isSpecialPlan = plan.isSpecial;
  const isOnLegacyPlan = plan.isLegacy;

  const handleSubscriptionReactivate = () => {
    subscriptionReactivate({
      variables: {
        subscriptionId,
      },
    });
  };

  return (
    <div className={styles.container}>
      <h2>Removed Scheduled Cancelation</h2>
      <p>
        Your <strong>{plan.accessType}</strong> plan is currently scheduled to
        cancel. If you&apos;d like to upgrade your plan, please remove the
        scheduled cancelation to proceed.
      </p>
      <Button
        variant="warning"
        width={{ _: "100%", md: "60%" }}
        color="black"
        disabled={
          subscriptionReactivateResults.loading ||
          isSpecialPlan ||
          (isOnLegacyPlan &&
            subscriptionStatus !== SubscriptionStatus.NonRenewing)
        }
        onClick={handleSubscriptionReactivate}
      >
        Remove Scheduled Cancelation
      </Button>
    </div>
  );
};
