import React from "react";
import Modal, { CloseModalButton } from "app/components/Modal";
import styled from "styled-components";
import Div from "app/components/Div";
import { Class } from "services/graphql";
import { userPreviewingContentAction } from "modules/content";
import { useDispatch } from "react-redux";
import ResponsiveVideoPlayer from "app/components/ResponsiveVideoPlayer";

const StyledModal = styled(Modal)`
  width: 100vw;
  height: auto;
  padding: 0;
  margin: 0;
  background: rgba(0, 0, 0, 40%);
`;

interface Props {
  classData: Class;
  closeModal(): void;
  isOpen: boolean;
}

export function MobilePreviewPlayerModal({
  isOpen = false,
  classData,
  closeModal,
}: Props) {
  const dispatch = useDispatch();
  return (
    <StyledModal
      isOpen={isOpen}
      onBackgroundClick={closeModal}
      onEscapeKeydown={closeModal}
    >
      <ResponsiveVideoPlayer
        playing
        controls
        volume={classData.isPreviewVideoMuted ? 0 : 1}
        url={classData.preview_url}
        onProgress={(videoProgress: any) => {
          dispatch(
            userPreviewingContentAction({
              contentData: classData,
              component: "PreviewPlayerModal",
              previewDuration: Math.floor(videoProgress.playedSeconds),
            })
          );
        }}
        loop
        showLoader={false}
        position="absolute"
        height="100%"
        width="auto"
      />
      <Div position="absolute" right="16px" top="16px">
        <CloseModalButton onClick={closeModal} />
      </Div>
    </StyledModal>
  );
}
