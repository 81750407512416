import styled from "styled-components";
import { P1 } from "app/components/Typography";

export const ControlWrapper = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
`;

export const HoverAdjustmentTitle = styled.p`
  padding: 5px 20px;
  margin: 0;
  font-weight: bold;

  ${({ theme }) => theme.mediaQueries.ltmd} {
    padding: 5px 10px;
  }
`;

export const ViewPageButton = styled.button`
  color: ${({ theme }) => theme.colors.white};
  border: none;
  background: none;
  align-items: center;
  display: flex;
  margin: 0 5px;
  cursor: pointer;
`;

export const StyledViewProgramText = styled(P1).attrs({
  display: { _: "none", md: "block" },
})`
  white-space: nowrap;
`;

interface SettingOptionProps {
  selected: boolean;
}

export const SettingOption = styled.div<SettingOptionProps>`
  padding: 5px 0;
  cursor: pointer;
  text-align: center;
  ${({ selected, theme }) =>
    selected && `font-weight: bold; color: ${theme.colors.primary[0]}`};

  :hover {
    background: ${({ theme }) => theme.colors.darkGrey};
  }
`;
