import { useEffect, useState } from "react";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import ReactPlayer from "react-player/lazy";
import { useLocation } from "react-router-dom";
import CustomDrmHlsReactPlayer from "app/components/CustomDrmHlsReactPlayer";
import CustomDrmDashReactPlayer from "app/components/CustomDrmDashReactPlayer";
import usePlayerSettings from "hooks/usePlayerSettings";

export {
  useBeginClassEvents,
  useEndClassEvent,
  useTakingClassEvent,
  useClassEventData,
} from "./eventCalls";

/* 
  Video Player Functionality
  https://www.figma.com/file/3Rtg9lAAxTaUbiGpIBNUke/Video-Player-Flows-%26-Logic?node-id=0%3A1&t=K6ULIdwQAGgJgvWq-0
*/

interface Props {
  classType: string;
  isSingleView: boolean;
}

export interface ClassPlayerFeatures {
  isMirrorAvailable: boolean;
  isLoopingAvailable: boolean;
  isCameraAvailable: boolean;
  isBackViewAvailable: boolean;
}

export function useClassPlayerFeatures({
  classType,
  isSingleView,
}: Props): ClassPlayerFeatures {
  const { isFamilyModeOn } = useFamilyModeSetting();
  const [classPlayerFeatures, setClassPlayerFeatures] = useState<
    ClassPlayerFeatures
  >({
    isMirrorAvailable: true,
    isLoopingAvailable: true,
    isCameraAvailable: true,
    isBackViewAvailable: true,
  });

  useEffect(() => {
    if (!classType) {
      return;
    }

    const playerFeatures = { ...classPlayerFeatures };

    if (classType === "Tips & Lectures") {
      playerFeatures.isMirrorAvailable = false;
      playerFeatures.isLoopingAvailable = false;
      playerFeatures.isCameraAvailable = false;
    }

    if (classType === "Follow Alongs" && isSingleView) {
      playerFeatures.isMirrorAvailable = false;
    }

    if (isSingleView) {
      playerFeatures.isBackViewAvailable = false;
    }

    if (isFamilyModeOn) {
      playerFeatures.isCameraAvailable = false;
    }

    setClassPlayerFeatures(playerFeatures);
  }, [classType]);

  return classPlayerFeatures;
}

export function useCustomPlayers() {
  useEffect(() => {
    ReactPlayer.addCustomPlayer(CustomDrmDashReactPlayer as any);
    ReactPlayer.addCustomPlayer(CustomDrmHlsReactPlayer as any);

    return () => {
      ReactPlayer.removeCustomPlayers();
    };
  }, []);
}

export function useRouterQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useVideoRotation(classPlayerFeatures: ClassPlayerFeatures) {
  const { isFrontView, mirrored } = usePlayerSettings();
  const [rotate, setRotate] = useState("rotateY(0deg)");
  const { isMirrorAvailable, isBackViewAvailable } = classPlayerFeatures;

  useEffect(
    function onViewStateChange() {
      let updatedRotate;

      /*
        Player state currently persists when switching between classes
        We need to prevent any view transformations when a user in mirrored mode
        tries to switch to a class with no mirror feature
        We also need to reset the rotation if mirrored and not available
      */
      if (!isMirrorAvailable && mirrored) {
        setRotate("rotateY(0deg)");
        return;
      }

      /*
        Player state currently persists when switching between classes
        We need to prevent any view transformations when a user in back view mode
        tries to switch to a class with only front view
      */
      if (!isBackViewAvailable && !isFrontView) {
        return;
      }

      if (isFrontView) {
        updatedRotate = mirrored ? "rotateY(0deg)" : "rotateY(180deg)";
      } else {
        updatedRotate = mirrored ? "rotateY(180deg)" : "rotateY(0deg)";
      }

      setRotate(updatedRotate);
    },
    [isFrontView, isBackViewAvailable, mirrored, isMirrorAvailable]
  );

  return { rotate };
}
