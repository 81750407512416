import styled from "styled-components";
import Slider, { createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";

export const SpeedOption = styled.div`
  padding: 8px 12px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;

  :hover {
    background-color: ${({ theme }) => theme.colors.monochrome[4]};
  }
`;

export const SpeedSlider = styled(createSliderWithTooltip(Slider))`
  &.rc-slider {
    height: 8px;
  }

  .rc-slider-rail {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0;
    opacity: 0.4;
    height: 100%;
  }
  .rc-slider-step {
    height: 100%;
  }
  .rc-slider-track {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0;
    height: 100%;
  }
  .rc-slider-handle {
    background-color: ${({ theme }) => theme.colors.white};
    border: none;
    height: 20px;
    width: 20px;
  }
  .rc-slider-handle:focus {
    border-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.white};
  }
  .rc-slider-handle:hover {
    border-color: ${({ theme }) => theme.colors.white};
  }
  .rc-slider-handle:active {
    border-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 3px ${({ theme }) => theme.colors.white};
  }
`;
