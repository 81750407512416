import { useRef, useEffect } from "react";
import isServer from "helpers/isServer";
import PerformanceTracker from "./PerformaceTracker";
import { PlayerLoadEvent } from "./constants";

const HLS_JS_MANIFEST_TYPE = "manifest";
const HLS_JS_PLAYLIST_TYPE = "level";

const CacheResults = {
  HIT: "hit",
  MISS: "miss",
  UNKNOWN: "unknown",
};

/**
 * getCacheResult
 *
 * Returns the type of cache header from an AJAX request for tracking purposes.
 *
 * Since both Fastly and Cloudfront use the non-standard x-cache header, we can
 * keep it fairly simple:
 *
 * * Any x-cache containing the string "miss" is considered a miss. This includes:
 *   "MISS, MISS" from Fastly--a total miss
 *   "Miss from cloudfront" from AWS--total miss
 *
 * * Conversely, any x-cache containing the string "hit" is considered a hit. This includes:
 *   "HIT, HIT" from Fastly--edge hit
 *   "MISS, HIT" from Fastly--edge cache missed, but edge sheild hit
 *      https://docs.fastly.com/en/guides/understanding-cache-hit-and-miss-headers-with-shielded-services
 *   "Hit from cloudfront" from AWS--edge hit
 *   "Refresh hit from cloudfront" from AWS--checked S3 for changes but didn't retrieve the object
 *
 * @param {XMLHttpRequest} xhrRequest
 * @returns [string, string]
 */
const getCacheResult = xhrRequest => {
  if (xhrRequest && typeof xhrRequest.getResponseHeader === "function") {
    const xCache = xhrRequest.getResponseHeader("x-cache");
    const xCacheString = xCache?.toLowerCase() || "";
    if (xCacheString.indexOf("hit") >= 0) {
      return [CacheResults.HIT, xCache];
    }
    if (xCacheString.indexOf("miss") >= 0) {
      return [CacheResults.MISS, xCache];
    }
  }

  return [CacheResults.UNKNOWN, null];
};

/**
 * getPlaylistLoader
 *
 * This overrides the pLoader used by HLS.js so we can measure timings
 * around downloading the HLS manifests. It takes the meaurements, and then
 * defers to the default loader:
 *
 * https://github.com/video-dev/hls.js/blob/master/docs/API.md#loader
 *
 * @param {Function} trackLoadEvent Callback that expects a load event type
 * @returns {Function} pLoader override for HLS.js
 */
export const getPlaylistLoader = trackLoadEvent => {
  return function pLoader(outerConfig) {
    /* eslint-disable new-cap */
    const loader = new window.Hls.DefaultConfig.loader(outerConfig);

    this.abort = () => loader.abort();
    this.destroy = () => loader.destroy();
    this.load = (context, config, callbacks) => {
      if (context.type === HLS_JS_MANIFEST_TYPE) {
        trackLoadEvent(PlayerLoadEvent.REQUEST_MANIFEST);
      } else if (context.type === HLS_JS_PLAYLIST_TYPE) {
        trackLoadEvent(PlayerLoadEvent.REQUEST_PLAYLIST);
      }
      loader.load(context, config, callbacks);
    };
  };
};

/**
 * getFragmentLoader
 *
 * Same as getPlaylistLoader, but for actual video content (fragments) instead
 * of the manifests.
 *
 * @param {Function} trackLoadEvent Callback that expects a load event type
 * @returns {Function} fLoader override for HLS.js
 */
export const getFragmentLoader = trackLoadEvent => {
  return function fLoader(outerConfig) {
    /* eslint-disable new-cap */
    const loader = new window.Hls.DefaultConfig.loader(outerConfig);

    this.abort = () => loader.abort();
    this.destroy = () => loader.destroy();
    this.load = (context, config, callbacks) => {
      trackLoadEvent(PlayerLoadEvent.REQUEST_FRAGMENT_START);
      const onSuccess = (...args) => {
        const xhrRequest = args[3];
        const [result, stringValue] = getCacheResult(xhrRequest);

        trackLoadEvent(PlayerLoadEvent.REQUEST_FRAGMENT_END, {
          edge_cache_result: result,
          edge_cache_string: stringValue,
        });
        return callbacks.onSuccess(...args);
      };

      loader.load(context, config, {
        ...callbacks,
        onSuccess,
      });
    };
  };
};

/**
 * usePerformanceTracker
 *
 * React hook that creates a stateful track function for tracking initial
 * load events of an HLS.js player. It intentionally tracks just the first
 * instance of each load event to measure initial timings, discarding
 * subsequent occurances
 *
 * @param {Performance} performanceApi Allows us to mark performance events
 * @param {Object} metadata additional fields common to all alaytics calls
 * @returns {Function} track function meant to be called in loadEvent-order
 */
export const usePerformanceTracker = (playerRef, metadata) => {
  const perfTrackerRef = useRef(null);

  useEffect(() => {
    if (isServer) {
      return;
    }
    perfTrackerRef.current = new PerformanceTracker({
      performanceApi: window.performance,
      playerRef,
      metadata,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isServer) {
      return;
    }
    if (perfTrackerRef.current) {
      perfTrackerRef.current.setMetadata(metadata);
    }
  }, [metadata]);

  return {
    trackLoadEvent(loadEvent, eventMetadata) {
      perfTrackerRef.current.trackLoadEvent(loadEvent, eventMetadata);
    },
    trackBufferStart() {
      perfTrackerRef.current.trackBufferStart();
    },
    trackBufferEnd() {
      perfTrackerRef.current.trackBufferEnd();
    },
    trackSeek(additionalMetadata) {
      perfTrackerRef.current.trackSeek(additionalMetadata);
    },
  };
};

export { PlayerLoadEvent };
export default {};
