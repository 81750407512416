import React from "react";
import { Speed } from "app/components/Icon";
import Flex from "app/components/Flex";
import { H7 } from "app/components/Typography";
import usePlayerSettings from "hooks/usePlayerSettings";
import TooltipWithTimer from "app/components/Tooltip/TooltipWithTimer";
import { SpeedSlider } from "./styles";
import { ControlButton, ControlIcon } from "../../components";
import { HoverAdjustmentTitle } from "../styles";
import { SpeedOptions } from "./SpeedOptions";

interface Props {
  isShowingSpeedAdjustment: boolean;
  playerWrapperRef: any;
  toggleSpeedAdjustment: (isShowing: boolean) => void;
  trackSpeedEvent: (speed: number) => void;
}

export function SpeedTooltip({
  isShowingSpeedAdjustment,
  playerWrapperRef,
  toggleSpeedAdjustment,
  trackSpeedEvent,
}: Props) {
  const { speed, setSpeed } = usePlayerSettings();

  function setSpeedAndCallEvent(newSpeed: number) {
    setSpeed(newSpeed);
    trackSpeedEvent(newSpeed);
  }

  return (
    <TooltipWithTimer
      getTooltipContainer={() => playerWrapperRef.current}
      visible={isShowingSpeedAdjustment}
      overlayClassName="player-controls tooltip-menu"
      onMouseEnter={() => toggleSpeedAdjustment(true)}
      onMouseLeave={() => {
        toggleSpeedAdjustment(false);
      }}
      overlay={
        <>
          <HoverAdjustmentTitle>Speed</HoverAdjustmentTitle>
          <Flex justifyContent="center" my="16px" px="20px">
            <SpeedSlider
              className="tooltip-menu"
              min={0.5}
              max={1.5}
              value={speed}
              step={0.01}
              onChange={setSpeed}
              onAfterChange={(newSpeed: number) => {
                setSpeed(newSpeed);
                setSpeedAndCallEvent(newSpeed);
              }}
              tipProps={{
                placement: "top",
                prefixCls: "rc-slider-tooltip",
              }}
              tipFormatter={(value: number) => `${value}x`}
            />
          </Flex>
          <SpeedOptions
            onSpeedSelect={(newSpeed: number) => setSpeedAndCallEvent(newSpeed)}
          />
        </>
      }
    >
      <Flex flexDirection="column" alignItems="center">
        <ControlButton disabled={false}>
          <ControlIcon width="30px" height="30px" as={Speed} color="white" />
        </ControlButton>
        <H7 mt={2} textAlign="center" color="white">
          Speed (S)
          <br />
          {speed}x
        </H7>
      </Flex>
    </TooltipWithTimer>
  );
}
