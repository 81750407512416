import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Div from "app/components/Div";
import Button from "app/components/Button";
import { pauseClassAction, playClassAction } from "modules/classPlayer";
import styled from "styled-components";
import { usePlaySession } from "./hooks";

const NoticeWrapper = styled(Div)`
  z-index: 99999;
  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`;

export const ConcurrentStreamModal = ({
  isConcurrentStreamCheckModalOpen,
  setIsConcurrentStreamCheckModalOpen,
}) => {
  const dispatch = useDispatch();

  const { invalidPlaySession, forceResumePlayback } = usePlaySession();

  useEffect(() => {
    if (invalidPlaySession) {
      setIsConcurrentStreamCheckModalOpen(true);
      dispatch(pauseClassAction());
    }
  }, [dispatch, invalidPlaySession, setIsConcurrentStreamCheckModalOpen]);

  function handleForceResumePlayback() {
    forceResumePlayback();

    setIsConcurrentStreamCheckModalOpen(false);
    dispatch(playClassAction());
  }

  if (!isConcurrentStreamCheckModalOpen) {
    return null;
  }

  return (
    <NoticeWrapper>
      <h2>Streaming Limit</h2>
      <Div mt={4} pl={2} pr={2} textAlign="center">
        <Div>
          You can stream to only one device at the same time on your account.
        </Div>
        <Div>
          Continuing to watch this class will stop streaming on any other
          devices.
        </Div>
      </Div>
      <Button mt={4} onClick={handleForceResumePlayback}>
        RESUME CLASS
      </Button>
    </NoticeWrapper>
  );
};
