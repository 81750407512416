import { useEffect, useRef } from "react";
import ErrorReporter from "services/error-reporter";
import { useTrackLogPlayMutation } from "services/graphql";

// @TODO colocate
export const useTunedGlobalPlayTracker = ({ classId, countryCode, tracks }) => {
  const [mutate] = useTrackLogPlayMutation();
  const hasTrackedDictRef = useRef({});

  useEffect(() => {
    return () => {
      hasTrackedDictRef.current = {};
    };
  }, [classId]);

  const onPlayerSecondsUpdateForTunedGlobal = seconds => {
    if (!tracks || !countryCode) {
      return null;
    }

    const tracksBeforeCurrentTime = tracks.filter(t => {
      return (
        !hasTrackedDictRef.current[t.track.id] &&
        t.track.source === "tuned_global" &&
        t.startsAt < seconds &&
        t.endsAt > seconds
      );
    });
    const lastTrackBeforeCurrentTime =
      tracksBeforeCurrentTime[tracksBeforeCurrentTime.length - 1];
    if (lastTrackBeforeCurrentTime) {
      hasTrackedDictRef.current[lastTrackBeforeCurrentTime.track.id] = true;
    }
    return lastTrackBeforeCurrentTime || null;
  };

  const trackLogPlay = async lastTrackBeforeCurrentTime => {
    try {
      await mutate({
        variables: {
          input: {
            countryCode,
            trackId: lastTrackBeforeCurrentTime.track.trackId,
          },
        },
      });
    } catch (e) {
      ErrorReporter.report(e);
    }
  };

  return {
    onPlayerSecondsUpdateForTunedGlobal,
    trackLogPlay,
  };
};
