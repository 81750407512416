import React from "react";
import styled from "styled-components";
import { PoweredByStripe } from "../Icon";

const StyledPoweredByStripe = styled(PoweredByStripe)`
  fill: ${({ theme }) => theme.colors.grey};
  width: 90px;
`;

const PoweredByStripeIcon = () => <StyledPoweredByStripe />;

export default PoweredByStripeIcon;
