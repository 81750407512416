import React, { useState } from "react";
import styled, { withTheme } from "styled-components";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Alert from "app/components/Alert";
import SpanLink from "app/components/SpanLink";
import zendesk from "helpers/zendesk";
import { useUserHasTrialed } from "hooks/Subscriptions/useUserHasTrialed";
import LoaderCentered from "../Loader/LoaderCentered";
import Button from "../Button";
import Loader from "../Loader";
import { ScreenLtLg, ScreenGtLg } from "../MediaQueries";
import Disclaimer from "../Checkout/Disclaimer";
import Flex from "../Flex";

const style = theme => ({
  base: {
    iconColor: theme.colors.primary[0],
    color: "#333",
    lineHeight: "40px",
    fontWeight: 500,
    fontFamily: "Helvetica Neue",
    fontSize: "15px",
    "::placeholder": { color: theme.colors.grey },
  },
  complete: { color: theme.colors.green },
  invalid: { color: theme.colors.red },
});

const Wrapper = styled.div``;

const StripeWrapper = styled.div`
  display: none;
  position: relative;

  ${({ isReady }) =>
    isReady &&
    `
    display: block;
  `}
`;

const ButtonOverlay = styled(Flex)`
  position: fixed;
  padding: 16px 8px;
  justify-content: center;
  width: 100vw;
  background-color: white;
  bottom: 0;
  box-shadow: 0 -16px 10px -10px rgba(0, 0, 0, 0.15);
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const RedSmall = styled.small`
  color: ${({ theme }) => theme.colors.red};
`;

const ButtonWrapper = styled.div`
  padding: 16px 0;
`;

const StripeInputWrapper = styled.div`
  padding: 16px 0;
  .StripeElement {
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }

  ${({ success }) =>
    success &&
    `
    border-color: ${({ theme }) => theme.colors.green};
  `}

  ${({ error }) =>
    error &&
    `
      border-color: ${({ theme }) => theme.colors.red};
    `}

  ${Icon} {
    path {
      ${({ success }) =>
        success &&
        `
        fill: ${({ theme }) => theme.colors.green};
      `}

      ${({ error }) =>
        error &&
        `
        fill: ${({ theme }) => theme.colors.red};
      `}
    }
  }
`;

const LoaderWrapper = styled.div`
  height: 200px;
  margin: 20px;
`;

const StripeCard = ({
  onError,
  onSubmit,
  onEmptyCardInput,
  buttonMessage,
  children,
  parentLoading,
  referrer,
  theme,
  paymentError,
}) => {
  const [error, setError] = useState({});
  const [success, setSuccess] = useState({});
  const [loading, setLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const selectedPlan = useSelector(store => store.checkout.selectedPlan);
  const { hasTrialed } = useUserHasTrialed();
  const planCanTrial = selectedPlan?.canTrial;
  const canTrial = planCanTrial && !hasTrialed;

  const handleSubmit = async () => {
    setLoading(true);

    const cardElement = elements.getElement(CardElement);

    const { token, error: tokenError } = await stripe.createToken(cardElement);

    if (token) {
      onError(null);
      onSubmit(token);
    } else if (tokenError) {
      onError(tokenError);
    }

    setLoading(false);
  };

  const handleNumberChange = result => {
    const { complete, error: newError, empty } = result;

    if (onEmptyCardInput) {
      onEmptyCardInput(empty);
    }

    setError({ cardElement: newError });
    setSuccess({ cardElement: complete });
  };

  return (
    <Wrapper>
      {!isReady && (
        <LoaderWrapper>
          <LoaderCentered width={80} />
        </LoaderWrapper>
      )}
      <StripeWrapper isReady={isReady}>
        <StripeInputWrapper
          success={success.cardElement}
          error={error.cardElement}
        >
          <CardElement
            onReady={() => {
              setIsReady(true);
            }}
            onChange={handleNumberChange}
            options={{
              hidePostalCode: true,
              style: style(theme),
            }}
          />
          <RedSmall>{error.cardElement && error.cardElement.message}</RedSmall>
          {referrer === "two-step-checkout" && (
            <ScreenLtLg>
              {!!paymentError && (
                <Alert variant="danger" closeAlert={() => onError(null)}>
                  {paymentError.message} Please try again or
                  <SpanLink onClick={() => zendesk("webWidget", "open")}>
                    {" "}
                    contact us for help!
                  </SpanLink>
                </Alert>
              )}
            </ScreenLtLg>
          )}
        </StripeInputWrapper>
        {children}
        {referrer === "two-step-checkout" && (
          <>
            <ScreenLtLg>
              <ButtonOverlay>
                <Flex flexDirection="column" alignItems="center" px={3}>
                  <Button maxWidth="245px" onClick={handleSubmit}>
                    {canTrial ? "TRY FREE & SUBSCRIBE" : "SUBSCRIBE NOW"}
                  </Button>
                  <Disclaimer plan={selectedPlan} />
                </Flex>
              </ButtonOverlay>
            </ScreenLtLg>
            <ScreenGtLg>
              <Button width="100%" onClick={handleSubmit} mb={4}>
                {canTrial ? "TRY FREE & SUBSCRIBE" : "SUBSCRIBE NOW"}
              </Button>
              <Disclaimer plan={selectedPlan} />
            </ScreenGtLg>
          </>
        )}
        <ButtonWrapper>
          <Button
            width="100%"
            display={referrer === "two-step-checkout" && "none"}
            disabled={parentLoading || loading || !success.cardElement}
            onClick={handleSubmit}
          >
            {!parentLoading ? buttonMessage : <Loader light width={24} />}
          </Button>
        </ButtonWrapper>
      </StripeWrapper>
    </Wrapper>
  );
};

StripeCard.defaultProps = {
  buttonMessage: "Submit Card",
  onEmptyCardInput: null,
  parentLoading: false,
  referrer: null,
  paymentError: null,
};

StripeCard.propTypes = {
  buttonMessage: PropTypes.string,
  children: PropTypes.node.isRequired,
  parentLoading: PropTypes.bool,
  onEmptyCardInput: PropTypes.func,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  referrer: PropTypes.bool,
  theme: PropTypes.shape({}).isRequired,
  paymentError: PropTypes.shape({}),
};

export default withTheme(StripeCard);
