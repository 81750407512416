import { useMutation } from "@apollo/client";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import Flex from "app/components/Flex";
import { H2, H3, H5, P1 } from "app/components/Typography";
import WebcamPlayer from "app/components/WebcamPlayer";
import {
  DATE_FORMAT_FOR_SCHEDULES,
  DEFAULT_ASPECT_RATIO,
} from "constants/index";
import {
  START_CLASS_MUTATION,
  UPDATE_CLASS_PROGRESS_MUTATION,
  UPDATE_CLASS_SESSION_MUTATION,
} from "graphql/mutations";
import { GET_PLAYLIST_QUERY, GET_PROGRAM_V2_WITH_REF } from "graphql/queries";
import convertTimeObjectToSeconds from "helpers/convertTimeObjectToSeconds";
import convertToTimeString from "helpers/convertTimeToString";
import zendesk from "helpers/zendesk";
import { useTunedGlobalPlayTracker } from "hooks/Classes/useTunedGlobalPlayTracker";
import useCompleteClass from "hooks/useCompleteClass";
import useHlsQualityTracker from "hooks/useHlsQualityTracker";
import useInterval from "hooks/useInterval";
import usePlayerSettings from "hooks/usePlayerSettings";
import usePrevious from "hooks/usePrevious";
import { useVideoStream } from "hooks/Classes/useVideoStream";
import { get, throttle } from "lodash";
import {
  flashActivityAction,
  pauseClassAction,
  playClassAction,
  resetClassPlayerAction,
  setLoopingRangeClassAction,
  startLoopingClassAction,
  stopClassAction,
  stopLoopingClassAction,
} from "modules/classPlayer";
import {
  seekClassForPartyAction,
  setCurrentTimeForPartyAction,
  timeoutPartyMemberAction,
} from "modules/party";
import moment from "moment";
import queryString from "query-string";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import ReactPlayer from "react-player/lazy";
import { useDispatch, useSelector } from "react-redux";
import ReactResizeDetector from "react-resize-detector";
import { useHistory, useLocation } from "react-router-dom";
import screenfull from "screenfull";
import Icon, {
  Forward5,
  Loop,
  Pause,
  Play,
  Rewind5,
  Speed,
  VolumeOff,
} from "app/components/Icon";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { useGetPartyData, useUserSubscription } from "modules/selectors";
import { usePlaybackControl as playbackControl } from "services/typewriter/segment";
import {
  useClassPlayerFeatures,
  useRouterQuery,
  useCustomPlayers,
  useVideoRotation,
  useBeginClassEvents,
  useEndClassEvent,
  useTakingClassEvent,
  useClassEventData,
} from "../hooks";
import ActionDisplay from "../ActionDisplay";
import AdvancedControls from "../AdvancedControls";
import { initializeMux } from "../classPlayerHelper";
import CompletionOverlay from "../CompletionOverlay";
import { Button } from "../components";
import PartyButton from "../components/PartyButton";
import ControlBar from "../ControlBar";
import DebugPanel from "../DebugPanel";
import LastClassPrompt from "../LastClassPrompt";
import NextClassPrompt from "../NextClassPrompt";
import {
  getFragmentLoader,
  getPlaylistLoader,
  PlayerLoadEvent,
  usePerformanceTracker,
} from "../performanceUtils";
import ProgressBar from "../ProgressBar";
import ResizeableParty from "../ResizeableParty";
import { TrackInfoTopBar } from "../TrackInfoTopBar";
import VideoSections from "../VideoSections";
import { ConcurrentStreamModal } from "../ConcurrentStreamModal";
import { ChromecastModal, DEFAULT_CHROMECAST_STATE } from "../ChromecastModal";
import {
  ReactPlayerWrapper,
  PlayerWrapper,
  PlayerOverlay,
  PlayAndPauseOverlay,
  BottomBar,
  TopBar,
} from "./styles";
import { getTimeInSeconds, getTracksWithEndTime } from "./helpers";
import { useMutedCuepoints, useStartNextClass } from "./hooks";

const QUICK_SEEK_SECONDS = 5;

interface Props {
  classData: any;
  classVideo: any;
  hideContinuityCTA?: boolean;
  nextClassData?: any;
  onResizeGetWidth?: (width: number) => void;
  programData?: any;
  isUserAllowedToAccessClass?: boolean;
  isSubscribeModalShowing?: boolean;
  setIsSubscribeModalShowing: (isShowing: boolean) => void;
  showOverlay: boolean;
  setShowOverlay: (overlay: boolean) => void;
  isClassOverlayTimerStopped: boolean;
  setIsClassOverlayTimerStopped: (isStopped: boolean) => void;
  scrollToClassDetails: () => void;
}

const ClassPlayer = (
  {
    classData = null,
    classVideo = null,
    hideContinuityCTA = false,
    nextClassData = null,
    onResizeGetWidth = null,
    programData = null,
    isUserAllowedToAccessClass = false,
    isSubscribeModalShowing = false,
    setIsSubscribeModalShowing,
    showOverlay,
    setShowOverlay,
    isClassOverlayTimerStopped,
    setIsClassOverlayTimerStopped,
    scrollToClassDetails,
  }: Props,
  wrapperRef: any
) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<any>();
  const routerQuery = useRouterQuery();
  const {
    startClassEvent,
    continueClassEvent,
    reviewClassEvent,
  } = useBeginClassEvents({ classData, programData });
  const { endClassEvent } = useEndClassEvent({ classData, programData });
  const { takingClassEvent } = useTakingClassEvent({
    classData,
    programData,
  });
  const { classEventData } = useClassEventData({ classData, programData });
  const { playlist: schedulePlaylistId, playlistId } = queryString.parse(
    location.search
  );
  const { startNextClass } = useStartNextClass({
    currentClassData: classData,
    nextClassData,
  });

  const queryClassRefId = routerQuery.get("classRefId");
  const classRefId = queryClassRefId || classData.refId;
  const reactPlayerRef = useRef<any>();
  const playerWrapperRef = useRef<any>();
  const reactPlayerWrapperRef = useRef<any>();
  const overlayBarsRef = useRef<any>(null);
  const seekingRef = useRef<any>(false);
  const userPartyData = useGetPartyData();
  const isPartyApprovedClass =
    userPartyData.status === "APPROVED" &&
    userPartyData.classId === parseInt(classData.id);

  const auth = useSelector(({ auth: authState }: any) => authState);

  const { isSubscriptionActive } = useUserSubscription();

  const { playing, looping, loopingRange } = useSelector(
    ({ classPlayer }: any) => classPlayer
  );
  const classProgress = useSelector(
    ({ user }: any) =>
      (classData && user.progress?.["class-progress"]?.[classData.id]) || {}
  );

  const partyState = useSelector(({ party }: any) =>
    isPartyApprovedClass ? party : {}
  );
  useCustomPlayers();

  const [isShowingSpeedAdjustment, toggleSpeedAdjustment] = useState(false);
  const [isShowingVolumeAdjustment, toggleVolumeAdjustment] = useState(false);
  const [initialComponentLoaded, setInitialComponentLoaded] = useState(false);
  const [initializedMux, setInitializedMux] = useState(false);
  const [loading, setLoading] = useState(true);
  const [quality, setQuality] = useState("auto");

  const [sessionTimeInSeconds, setSessionTimeInSeconds] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState([]);
  const [hasProgressed, setHasProgressed] = useState(false);
  const [showSections, setShowSections] = useState(false);
  const [sectionName, setSectionName] = useState(null);
  const [cuepointName, setCuepointName] = useState(null);
  const [volume, setVolume] = useState(1);
  const [wrapperMaxHeight, setWrapperMaxHeight] = useState("100%");
  const [wrapperMaxWidth, setWrapperMaxWidth] = useState("100%");
  const [showOverlayBars, setOverlayBars] = useState(false);
  const [settingQuality, setSettingQuality] = useState(false);
  const [isPartyPopoverVisible, togglePartyPopover] = useState(false);
  const [sessionCount, setSessionCount] = useState(0);
  const [currentTrackInfoData, setCurrentTrackInfoData] = useState(null);
  const [
    isConcurrentStreamCheckModalOpen,
    setIsConcurrentStreamCheckModalOpen,
  ] = useState(false);
  const [chromecastState, setChromecastState] = useState(
    DEFAULT_CHROMECAST_STATE
  );

  const [startClassMutation] = useMutation(START_CLASS_MUTATION);
  const [updateClassProgressMutation] = useMutation(
    UPDATE_CLASS_PROGRESS_MUTATION,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [
    updateClassSessionMutation,
  ] = useMutation(UPDATE_CLASS_SESSION_MUTATION, { fetchPolicy: "no-cache" });

  const clearCurrentTrackInfoData = () => setCurrentTrackInfoData(null);

  const {
    videoStreamQualityLevels,
    videoStreamSetQualityLevel,
    videoStreamUpdateQualityLevels,
    videoStream,
  } = useVideoStream({
    video: classData?.video,
    reactPlayerRef,
  });

  const classPlayerFeatures = useClassPlayerFeatures({
    classType: classData?.type,
    isSingleView: videoStream.isSingleView,
  });
  const {
    isMirrorAvailable,
    isLoopingAvailable,
    isCameraAvailable,
    isBackViewAvailable,
  } = classPlayerFeatures;

  const { rotate } = useVideoRotation(classPlayerFeatures);

  const {
    isFrontView,
    mirrored,
    showFullscreen,
    showWebcam,
    setPlayerSetting,
    togglePlayerSetting,
    hlsQuality,
    speed,
    setSpeed,
  } = usePlayerSettings();

  const { mutedCuepoint } = useMutedCuepoints({
    mutedCuepoints: classData?.mutedCuepoints,
    currentTime: reactPlayerRef.current
      ? Math.floor(reactPlayerRef.current?.getCurrentTime())
      : 0,
  });

  const {
    trackLoadEvent,
    trackBufferStart,
    trackBufferEnd,
    trackSeek,
  }: any = usePerformanceTracker(reactPlayerRef, {
    ...classData,
    class_id: classData.id,
    party_session_id: userPartyData.sessionId,
    in_a_party: isPartyApprovedClass,
    playback_time: classProgress.time,
    initial_hls_quality: hlsQuality,
  });

  const trackHlsQuality = useHlsQualityTracker();

  const tracksWithEndTime = getTracksWithEndTime(
    classData.tracks,
    classData.duration_in_seconds
  );
  const {
    onPlayerSecondsUpdateForTunedGlobal,
    trackLogPlay,
  } = useTunedGlobalPlayTracker({
    classId: classData.id,
    countryCode: useSelector(({ iplocation }: any) => iplocation.country_code),
    tracks: tracksWithEndTime,
  });
  const { isFamilyModeOn } = useFamilyModeSetting();

  // We want to refetch GET_PLAYLIST_QUERY when class is being taken from a For You schedule
  // POTENTIAL TODO: refetch using the schedulePlaylistId instead of the date
  let refetchQueries: any = [];
  if (schedulePlaylistId) {
    refetchQueries = [
      {
        query: GET_PLAYLIST_QUERY,
        variables: {
          date: moment()
            .startOf("day")
            .format(DATE_FORMAT_FOR_SCHEDULES),
        },
      },
    ];
  } else if (classRefId) {
    refetchQueries = [
      {
        query: GET_PROGRAM_V2_WITH_REF,
        variables: {
          classRefId,
        },
      },
    ];
  }

  const completeClassMutation = useCompleteClass({
    refetchQueries,
    awaitRefetchQueries: true,
  });
  const subscriptionStatusOnInitialLoad = usePrevious(isSubscriptionActive);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resizeVideo = () => {
    const currentRef = reactPlayerWrapperRef.current;

    if (!currentRef) {
      return;
    }

    const playerWrapperWidth = currentRef.offsetWidth;
    const playerWrapperHeight = currentRef.offsetHeight;
    const actualAspectRatio = playerWrapperHeight / playerWrapperWidth;

    if (actualAspectRatio > DEFAULT_ASPECT_RATIO) {
      setWrapperMaxHeight(
        `${Math.ceil(playerWrapperWidth * DEFAULT_ASPECT_RATIO - 1)}px`
      );
      setWrapperMaxWidth(`${playerWrapperWidth}px`);
    } else {
      setWrapperMaxHeight(`${playerWrapperHeight}px`);
      setWrapperMaxWidth(`${playerWrapperHeight / DEFAULT_ASPECT_RATIO}px`);
    }

    if (onResizeGetWidth && !showWebcam) {
      // return player width to parent wrapper to allow responsiveness
      onResizeGetWidth(playerWrapperWidth);
    }
  };

  const seekReactPlayer = useCallback(
    (seconds: number) => {
      const currentRef = reactPlayerRef?.current;
      if (!currentRef) {
        return;
      }

      if (seconds || seconds === 0) {
        // eslint-disable-next-line no-unused-expressions
        currentRef.seekTo(seconds);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reactPlayerRef?.current]
  );

  const toggleOverlayBars = (toggle: boolean) => {
    setOverlayBars(toggle);
    if (!toggle) {
      togglePartyPopover(false);
      toggleSpeedAdjustment(false);
      toggleVolumeAdjustment(false);
    }
  };

  const skip = (seconds: number) => {
    const currentTimeInSeconds = Math.floor(
      reactPlayerRef.current?.getCurrentTime()
    );
    const newTimeInSeconds = currentTimeInSeconds + seconds;

    seek(
      classData.duration_in_seconds < newTimeInSeconds
        ? classData.duration_in_seconds
        : newTimeInSeconds,
      null,
      true
    );
    playbackControl({
      class_id: classData.id,
      skip_to: convertToTimeString(newTimeInSeconds),
      control_used: seconds > 0 ? "SkipForward" : "SkipBack",
      playback_time: convertToTimeString(currentTimeInSeconds),
      party_session_id: userPartyData.sessionId,
      in_a_party: isPartyApprovedClass,
      section_name: sectionName,
      cuepoint_name: cuepointName,
    });

    if (seconds === -QUICK_SEEK_SECONDS) {
      dispatch(flashActivityAction("Rewind", Rewind5, "25%"));
    } else if (seconds === QUICK_SEEK_SECONDS) {
      dispatch(flashActivityAction("Forward", Forward5, "75%"));
    }
  };

  const togglePlay = (isPlaying: boolean) => {
    if (isPlaying) {
      dispatch(playClassAction());
      clearTimeout(overlayBarsRef.current);
      overlayBarsRef.current = setTimeout(() => {
        toggleOverlayBars(false);
      }, 3000);
    } else {
      toggleOverlayBars(true);
      dispatch(pauseClassAction());
    }

    playbackControl({
      classId: classData.id,
      playback_time: convertToTimeString(currentTimeInSeconds),
      control_used: isPlaying ? "Play" : "Pause",
      party_session_id: userPartyData.sessionId,
      in_a_party: isPartyApprovedClass,
      section_name: sectionName,
      cuepoint_name: cuepointName,
    });

    dispatch(
      flashActivityAction(
        isPlaying ? "Play" : "Pause",
        isPlaying ? Play : Pause
      )
    );
  };

  const openSubscribeModal = () => {
    setIsSubscribeModalShowing(true);
    togglePlay(false);
  };

  const toggleMirror = () => {
    if (!isMirrorAvailable) {
      return;
    }

    const toggledMirrored = togglePlayerSetting("mirrored");

    playbackControl({
      classId: classData.id,
      playback_time: convertToTimeString(currentTimeInSeconds),
      control_used: "MirrorToggle",
      mirrored: toggledMirrored,
      party_session_id: userPartyData.sessionId,
      in_a_party: isPartyApprovedClass,
      section_name: sectionName,
      cuepoint_name: cuepointName,
    });
  };

  const toggleView = () => {
    if (!isBackViewAvailable) {
      return;
    }

    const isFrontViewSetting = togglePlayerSetting("isFrontView");

    const currentTimeInSeconds = Math.floor(
      reactPlayerRef.current?.getCurrentTime()
    );
    playbackControl({
      classId: classData.id,
      playback_time: convertToTimeString(currentTimeInSeconds),
      control_used: "ViewToggle",
      toggled_to: isFrontViewSetting ? "front" : "back",
      party_session_id: userPartyData.sessionId,
      in_a_party: isPartyApprovedClass,
      section_name: sectionName,
      cuepoint_name: cuepointName,
    });
  };

  const toggleLoop = (updatedLooping: boolean) => {
    if (!isLoopingAvailable) {
      return;
    }

    const currentTimeInSeconds = Math.floor(
      reactPlayerRef.current?.getCurrentTime()
    );
    const currentLoopingRange = [
      currentTimeInSeconds,
      currentTimeInSeconds + 60,
    ];

    dispatch(setLoopingRangeClassAction(currentLoopingRange));

    playbackControl({
      classId: classData.id,
      playback_time: convertToTimeString(currentTimeInSeconds),
      control_used: "Loop",
      create_loop: [currentLoopingRange?.[0], currentLoopingRange?.[1]],
      end_loop: !updatedLooping,
      party_session_id: userPartyData.sessionId,
      in_a_party: isPartyApprovedClass,
      section_name: sectionName,
      cuepoint_name: cuepointName,
    });

    if (updatedLooping) {
      dispatch(startLoopingClassAction());
    } else {
      dispatch(stopLoopingClassAction());
    }
    dispatch(
      flashActivityAction(updatedLooping ? "Loop On" : "Loop Off", Loop)
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleWebcam = () => {
    if (!isCameraAvailable) {
      return;
    }

    const toggledWebcam = togglePlayerSetting("showWebcam");

    setLoading(false);

    playbackControl({
      classId: classData.id,
      playback_time: convertToTimeString(currentTimeInSeconds),
      control_used: "Camera",
      toggle_camera: toggledWebcam,
      section_name: sectionName,
      cuepoint_name: cuepointName,
    });
  };

  useEffect(() => {
    if (partyState.started && showWebcam) {
      toggleWebcam();
    }
  }, [partyState.started, showWebcam, toggleWebcam]);

  const onKeyDown = useCallback(
    (e: any) => {
      if (["INPUT", "TEXTAREA"].includes(e.target.tagName) || showOverlay) {
        return;
      }

      switch (e.which) {
        // H Key
        case 72:
          setShowSections(prevShowSections => !prevShowSections);
          break;
        // S Key
        case 83:
          if (showOverlayBars) {
            toggleSpeedAdjustment(
              prevIsShowingSpeedAdjustment => !prevIsShowingSpeedAdjustment
            );
          }
          break;
        // W Key
        case 87:
          toggleWebcam();
          break;
        // Esc Key
        case 27:
          if (showSections) {
            setShowSections(false);
          }
          break;
        // Space Bar
        case 32:
          e.preventDefault();
          togglePlay(!playing);
          break;
        // Left Arrow
        case 37:
          skip(-QUICK_SEEK_SECONDS);
          break;
        // Right Arrow
        case 39:
          skip(QUICK_SEEK_SECONDS);
          break;
        // Shift Key
        case 16:
          toggleView();
          break;
        // M Key
        case 77:
          toggleMirror();
          break;
        // L Key
        case 76:
          toggleLoop(!looping);
          break;
        // F Key
        case 70:
          toggleFullscreen();
          break;
        // + Key
        case 187:
          if (speed < 1.5) {
            hotkeyUpdateSpeed(speed + 0.1);
          }
          break;
        // - Key
        case 189:
          if (speed > 0.5) {
            hotkeyUpdateSpeed(speed - 0.1);
          }
          break;
        default:
          break;
      }
    },
    [
      playing,
      isFrontView,
      mirrored,
      looping,
      showSections,
      showOverlay,
      showOverlayBars,
      speed,
    ]
  );

  useEffect(() => {
    const isUserNewSubscriberViaCtaModal =
      !subscriptionStatusOnInitialLoad && isSubscriptionActive;
    if (isUserNewSubscriberViaCtaModal && !loading) {
      togglePlay(true);
    }
    // eslint-disable-next-line
  }, [loading, isSubscriptionActive, subscriptionStatusOnInitialLoad]);

  useEffect(() => {
    // Disable keyboard shortcuts if subscribeModal is open or user is anonymous or concurrent stream modal is open
    if (
      !isSubscribeModalShowing &&
      !auth.isAnonymous &&
      !isConcurrentStreamCheckModalOpen &&
      chromecastState.isConnected != null
    ) {
      document.addEventListener("keydown", onKeyDown);
    }

    return () => document.removeEventListener("keydown", onKeyDown);
  }, [
    auth.isAnonymous,
    isSubscribeModalShowing,
    isConcurrentStreamCheckModalOpen,
    chromecastState.isConnected,
    onKeyDown,
  ]);

  useEffect(() => {
    if (partyState.timeToSeek) {
      seekReactPlayer(partyState.timeToSeek);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyState.timeToSeek]);

  useEffect(
    function syncPlayerWithPartyIfBehind() {
      const currentTimeInSeconds =
        Math.floor(reactPlayerRef.current?.getCurrentTime()) || 0;
      if (
        playing &&
        partyState.started &&
        !partyState.isLastActor &&
        (partyState.currentTimeInSeconds - 30 > currentTimeInSeconds ||
          partyState.currentTimeInSeconds + 30 < currentTimeInSeconds)
      ) {
        seekReactPlayer(partyState.currentTimeInSeconds + 10);
      }
    },
    [
      partyState,
      partyState.currentTimeInSeconds,
      partyState.isLastActor,
      partyState.started,
      playing,
      seekReactPlayer,
    ]
  );

  useEffect(
    function onProgressEffect() {
      const currentTimeInSeconds =
        Math.floor(reactPlayerRef.current?.getCurrentTime()) || 0;

      if (
        !hasProgressed ||
        currentTimeInSeconds > classData.duration_in_seconds + 1
      ) {
        // onProgress seems to fire beyond the class duration for some users.
        // Return if currentTime is greater than class duration (+1 to account for any player inconsistencies)
        return;
      }

      if (Boolean(classProgress.started) === false) {
        startClassMutation({
          variables: {
            classId: String(classData.id),
            startedDateTime: moment().toISOString(),
            context: {
              schedulePlaylistId,
              programClassRefId: classRefId,
            },
          },
        });
      }

      if (!seekingRef.current && playedSeconds.length >= 10) {
        updateClassProgressMutation({
          variables: {
            classId: String(classData.id),
            timestampSeconds: currentTimeInSeconds,
            sessionSeconds: 10,
            playedSeconds,
            playedDate: moment().toISOString(),
            context: {
              schedulePlaylistId,
              partyId: partyState.started ? userPartyData.id : null,
              programClassRefId: classRefId,
            },
          },
        });
        setPlayedSeconds([]);
      }

      if (
        looping &&
        (currentTimeInSeconds < loopingRange[0] ||
          currentTimeInSeconds >= loopingRange[1])
      ) {
        seek(loopingRange[0], loopingRange[1]);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [playedSeconds]
  );

  useInterval(() => {
    setSessionCount(sessionCount + 1);
    if (sessionCount >= 10) {
      updateClassSessionMutation({
        variables: {
          classId: String(classData.id),
          sessionSeconds: sessionCount,
          context: {
            partyId: partyState.started ? userPartyData.id : null,
          },
        },
      }).catch(() => null);
      setSessionCount(1);
    }
  }, 1000);

  const playedSecondsRef = useRef([]);
  useEffect(
    function avoidStaleClosureForPlayedSeconds() {
      playedSecondsRef.current = playedSeconds;
    },
    [playedSeconds]
  );

  useEffect(function onMount() {
    zendesk("webWidget", "hide");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(function onLastUnmount() {
    const reactPlayerNode = reactPlayerRef.current;
    return () => {
      endClassEvent({
        method: "exited_page",
        percent_completed: classProgress.percent,
      });
      dispatch(resetClassPlayerAction());

      const currentTimeInSeconds =
        Math.floor(reactPlayerNode?.getCurrentTime()) || 0;
      const currentPlayedSeconds = playedSecondsRef.current;
      if (currentPlayedSeconds.length) {
        updateClassProgressMutation({
          variables: {
            classId: String(classData.id),
            timestampSeconds: currentTimeInSeconds,
            sessionSeconds: currentPlayedSeconds.length,
            playedSeconds: currentPlayedSeconds,
            playedDate: moment().toISOString(),
            context: {
              schedulePlaylistId,
              partyId: partyState.started ? userPartyData.id : null,
              programClassRefId: classRefId,
            },
          },
        });
      }

      if (sessionCount) {
        updateClassSessionMutation({
          variables: {
            classId: String(classData.id),
            sessionSeconds: sessionCount,
            context: {
              partyId: partyState.started ? userPartyData.id : null,
            },
          },
        }).catch(() => null);
      }

      if (userPartyData.status === "APPROVED" && partyState.started) {
        dispatch(timeoutPartyMemberAction());
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (userPartyData.status === "APPROVED" && partyState.started) {
        dispatch(timeoutPartyMemberAction());
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [dispatch, partyState.started, userPartyData.status]);

  useEffect(() => {
    const intervalInSeconds = 15;
    if (sessionTimeInSeconds % intervalInSeconds === 0) {
      const currentTimeInSeconds =
        Math.floor(reactPlayerRef.current?.getCurrentTime()) || 0;

      takingClassEvent({
        percent_progress: Math.round(
          (currentTimeInSeconds / classData.duration_in_seconds) * 100
        ),
        interval: intervalInSeconds,
        session_minute_reached: Math.floor(sessionTimeInSeconds / 60),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionTimeInSeconds]);

  useEffect(() => {
    resizeVideo();
  }, [resizeVideo, showWebcam, showFullscreen, partyState.started]);

  useEffect(() => {
    if (classData && videoStream.url && auth && !initializedMux) {
      initializeMux({ classData, videoUrl: videoStream.url, auth });
      setInitializedMux(true);
    }
  }, [classData, videoStream.url, auth, initializedMux]);

  const onBuffer = () => {
    trackBufferStart();
    setLoading(true);
  };

  const onBufferEnd = () => {
    trackBufferEnd();
    setLoading(false);
  };

  const onProgress = (playerProgress: any) => {
    const currentTimeInSeconds = Math.floor(playerProgress.playedSeconds);
    if (
      // prevent triggers after class completion (+1 to account for any player inconsistencies)
      currentTimeInSeconds > classData.duration_in_seconds + 1 ||
      // prevent trigger after completion when video restarts to 0:00
      playerProgress.playedSeconds === 0
    ) {
      return;
    }

    setHasProgressed(true);
    setPlayedSeconds(prevPlayedSeconds => {
      return prevPlayedSeconds.concat(currentTimeInSeconds);
    });
    setSessionTimeInSeconds(prevSessionTimeInSeconds => {
      return prevSessionTimeInSeconds + 1;
    });
    setLoading(false);
    const lastTrackBeforeCurrentTime = onPlayerSecondsUpdateForTunedGlobal(
      currentTimeInSeconds
    );
    if (lastTrackBeforeCurrentTime) {
      trackLogPlay(lastTrackBeforeCurrentTime);
      setCurrentTrackInfoData(lastTrackBeforeCurrentTime.track);
    }

    if (partyState.started && partyState.isLastActor) {
      dispatch(setCurrentTimeForPartyAction(currentTimeInSeconds));
    }
  };

  const onError = (error: any, data: any) => {
    // Browsers like Safari will block autoplaying from time-to-time
    // Force the player to pause if player is not allowed to play
    if (
      error &&
      (error.name === "NotAllowedError" || error.name === "AbortError")
    ) {
      togglePlay(false);
    }

    // hls.js fires buffer events as errors and that is causing us to reach capacity on Amplitude
    if (data?.fatal) {
      window.analytics.track("Error", {
        ...classEventData,
        ...data,
        event: error,
        video_url: videoStream.url,
        flow: "Class",
        error: "class_player_error",
      });
    }
  };

  const seek = (
    seconds: number,
    nextSeconds: number | null = null,
    isSkipping = false
  ) => {
    setLoading(true);
    setShowSections(false);
    if (looping && nextSeconds) {
      dispatch(setLoopingRangeClassAction([seconds, nextSeconds]));

      // TODO: extract the duplication
      playbackControl({
        classId: classData.id,
        playback_time: convertToTimeString(currentTimeInSeconds),
        control_used: "Loop",
        create_loop: [loopingRange?.[0], loopingRange?.[1]],
        party_session_id: userPartyData.sessionId,
        in_a_party: isPartyApprovedClass,
        section_name: sectionName,
        cuepoint_name: cuepointName,
      });
    }

    if (partyState.started) {
      dispatch(seekClassForPartyAction(seconds));
    }

    if (!isSkipping) {
      trackSeek({
        skip_to: convertToTimeString(seconds),
        section_name: sectionName,
        cuepoint_name: cuepointName,
      });
    }

    seekReactPlayer(seconds);
  };

  const exitFullscreen = () => (showFullscreen ? toggleFullscreen() : null);

  const toggleFullscreen = throttle(() => {
    const screenfullFunction = screenfull as any;
    if (showFullscreen === screenfullFunction.isFullscreen) {
      screenfullFunction.toggle(wrapperRef.current).then(resizeVideo);
    }

    playbackControl({
      classId: classData.id,
      playback_time: convertToTimeString(currentTimeInSeconds),
      control_used: "Fullscreen",
      toggle_fullscreen: !showFullscreen,
      party_session_id: userPartyData.sessionId,
      in_a_party: isPartyApprovedClass,
      section_name: sectionName,
      cuepoint_name: cuepointName,
    });
  }, 750);

  useEffect(() => {
    const screenfullFunction = screenfull as any;
    screenfullFunction.onchange(() => {
      setPlayerSetting("showFullscreen", screenfullFunction.isFullscreen);
      if (!screenfullFunction.isFullscreen) {
        exitFullscreen();
      }
    });
    screenfullFunction.on("error", exitFullscreen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const endClass = () => {
    endClassEvent({
      method: "clicked_button",
      percent_completed: classProgress.percent,
    });

    if (location?.state?.redirectToRouteOnEnd) {
      return history.push(location.state.redirectToRouteOnEnd);
    }
    return get(location.state, "fromApp")
      ? history.goBack()
      : history.push("/dashboard");
  };

  const onEnded = () => {
    if (looping) {
      seek(loopingRange[0]);
      return;
    }

    const currentPlayedSeconds = playedSecondsRef.current;
    if (currentPlayedSeconds.length) {
      updateClassProgressMutation({
        variables: {
          classId: String(classData.id),
          timestampSeconds: currentTimeInSeconds,
          sessionSeconds: currentPlayedSeconds.length,
          playedSeconds: currentPlayedSeconds,
          playedDate: moment().toISOString(),
          context: {
            schedulePlaylistId,
            partyId: partyState.started ? userPartyData.id : null,
            programClassRefId: classRefId,
          },
        },
      });
      setPlayedSeconds([]);
    }

    completeClassMutation({
      variables: {
        classId: String(classData.id),
        completedDateTime: moment().toISOString(),
        context: {
          playlistId,
          programClassRefId: classRefId,
        },
      },
    });

    dispatch(stopClassAction());

    seekReactPlayer(0);
    setShowOverlay(true);
  };

  const onReady = (player: any) => {
    trackLoadEvent(PlayerLoadEvent.READY);
    trackHlsQuality(player);
    videoStreamUpdateQualityLevels();

    if (!initialComponentLoaded) {
      if (classProgress.completed) {
        reviewClassEvent();
      } else if (classProgress.started) {
        continueClassEvent();
      } else {
        startClassEvent();
      }

      setInitialComponentLoaded(true);
      if (partyState.started) {
        seekReactPlayer(partyState.currentTimeInSeconds);
      } else if (classProgress && classProgress.time) {
        seek(convertTimeObjectToSeconds(classProgress.time));
      }

      if (!isSubscribeModalShowing && !isClassOverlayTimerStopped) {
        togglePlay(true);
      }
      if (isClassOverlayTimerStopped) {
        togglePlay(false);
      }
    } else if (settingQuality) {
      if (classProgress && classProgress.time && !partyState.started) {
        seek(convertTimeObjectToSeconds(classProgress.time));
      } else if (partyState.started) {
        seekReactPlayer(partyState.currentTimeInSeconds);
      }
      setSettingQuality(false);
    }

    setLoading(false);
    resizeVideo();
  };

  const onSeek = () => {
    seekingRef.current = false;
  };

  const onAfterProgressBarChange = (value: any) => {
    const startLoopingRange = loopingRange[0];
    if (looping && value < startLoopingRange) {
      seek(startLoopingRange);
    } else {
      seek(value);
    }
  };

  const onAfterLoopingChange = (value: any) => {
    const newStartLoopingRange = value[0];
    const startLoopingRange = loopingRange[0];

    dispatch(setLoopingRangeClassAction(value));

    // TODO: extract the duplication
    playbackControl({
      classId: classData.id,
      playback_time: convertToTimeString(currentTimeInSeconds),
      control_used: "Loop",
      create_loop: [value?.[0], value?.[1]],
      party_session_id: userPartyData.sessionId,
      in_a_party: isPartyApprovedClass,
      section_name: sectionName,
      cuepoint_name: cuepointName,
    });

    // Only seek if the start of the loop has changed
    if (newStartLoopingRange !== startLoopingRange) {
      seek(value[0]);
    }
  };

  const goToNextClass = () => {
    startNextClass();
    setShowOverlay(false);

    togglePlay(true);
  };

  const replayClass = () => {
    seek(0);
    togglePlay(true);
  };

  const setQualityCallback = (qualityObj: any) => {
    // Need to set settingQuality first to avoid race conditions with changing videoUrl
    setSettingQuality(true);
    setQuality(qualityObj.label);
    setLoading(true);
    videoStreamSetQualityLevel(qualityObj);

    playbackControl({
      class_id: classData.id,
      playback_time: convertToTimeString(currentTimeInSeconds),
      control_used: "PlaybackQuality",
      adjusted_to: qualityObj.label,
      party_session_id: userPartyData.sessionId,
      in_a_party: isPartyApprovedClass,
      section_name: sectionName,
      cuepoint_name: cuepointName,
      adjustment_type: "manual",
    });
  };

  const trackSpeedEvent = (currentSpeed: number) => {
    playbackControl({
      class_id: classData.id,
      playback_time: convertToTimeString(currentTimeInSeconds),
      control_used: "PlaybackSpeed",
      adjusted_to: currentSpeed,
      party_session_id: userPartyData.sessionId,
      in_a_party: isPartyApprovedClass,
      section_name: sectionName,
      cuepoint_name: cuepointName,
    });
  };

  function hotkeyUpdateSpeed(newSpeed: number) {
    // Ensure speed is rounded to nearest tenth
    const roundedSpeed = Math.round(newSpeed * 10) / 10;
    setSpeed(roundedSpeed);
    dispatch(flashActivityAction(`${roundedSpeed}x Speed`, Speed, "50%"));
    trackSpeedEvent(roundedSpeed);
  }

  const trackVolumeEvent = (newVolume: number) => {
    playbackControl({
      classId: classData.id,
      playback_time: convertToTimeString(currentTimeInSeconds),
      control_used: "Volume",
      volume_set_to: newVolume,
      party_session_id: userPartyData.sessionId,
      in_a_party: isPartyApprovedClass,
      section_name: sectionName,
      cuepoint_name: cuepointName,
    });
  };

  const trackShortcutsModalEvent = () => {
    playbackControl({
      classId: classData.id,
      playback_time: convertToTimeString(currentTimeInSeconds),
      control_used: "ShortcutsModal",
      party_session_id: userPartyData.sessionId,
      in_a_party: isPartyApprovedClass,
      section_name: sectionName,
      cuepoint_name: cuepointName,
    });
  };

  const showZendesk = () => {
    if (isFamilyModeOn) {
      return;
    }

    exitFullscreen();

    zendesk("webWidget", "show");
    zendesk("webWidget", "open");
    zendesk("webWidget:on", "open", () => {
      playbackControl({
        classId: classData.id,
        playback_time: convertToTimeString(currentTimeInSeconds),
        control_used: "Helpdesk",
        party_session_id: userPartyData.sessionId,
        in_a_party: isPartyApprovedClass,
        section_name: sectionName,
        cuepoint_name: cuepointName,
      });
    });
    zendesk("webWidget:on", "close", () => {
      zendesk("webWidget", "hide");
    });
  };

  const currentTimeInSeconds = reactPlayerRef.current
    ? Math.floor(reactPlayerRef.current?.getCurrentTime())
    : 0;

  const isReactPlayerHeightFixed = showWebcam || partyState.started;
  const playerVolume = mutedCuepoint ? 0 : volume;

  return (
    <ReactResizeDetector
      handleHeight
      handleWidth
      refreshMode="throttle"
      refreshRate={750}
      onResize={resizeVideo}
    >
      <DebugPanel player={reactPlayerRef?.current} />
      <Flex>
        <PlayerWrapper
          ref={playerWrapperRef}
          onMouseMove={e => {
            e.preventDefault();

            if (!isMobile) {
              toggleOverlayBars(true);
              clearTimeout(overlayBarsRef.current);
              overlayBarsRef.current = setTimeout(() => {
                toggleOverlayBars(false);
              }, 3000);
            }
          }}
        >
          {showWebcam && (
            <WebcamPlayer
              playerHeight={
                playerWrapperRef.current &&
                playerWrapperRef.current?.offsetHeight
              }
              playerWidth={
                playerWrapperRef.current &&
                playerWrapperRef.current?.offsetWidth
              }
              onResize={resizeVideo}
              toggleWebcam={toggleWebcam}
            />
          )}
          <ReactPlayerWrapper
            ref={reactPlayerWrapperRef}
            maxheight={isReactPlayerHeightFixed ? null : wrapperMaxHeight}
            maxwidth={isReactPlayerHeightFixed ? null : wrapperMaxWidth}
            isFrontView={isFrontView}
            showFullscreen={showFullscreen}
            transform={rotate}
          >
            <ReactPlayer
              id="class-player"
              url={videoStream.url}
              config={{
                file: {
                  hlsOptions: {
                    pLoader: getPlaylistLoader(trackLoadEvent),
                    fLoader: getFragmentLoader(trackLoadEvent),
                    startPosition: getTimeInSeconds(classProgress, classData),
                    autoStartLoad: false,
                    /**
                     * startLevel forces hls.js to start with the playlist at
                     * the specfic index in the manifest (defaulting to the
                     * first if there are too few). We can enable this if
                     * we want to force starting at a lower rendition
                     *
                     *  startLevel: 3,
                     */
                  },
                },
                customdrmdash: {
                  ...videoStream.drmSettings,
                },
                customdrmhls: {
                  ...videoStream.drmSettings,
                },
              }}
              ref={reactPlayerRef}
              onBuffer={onBuffer}
              onBufferEnd={onBufferEnd}
              onError={onError}
              onEnded={onEnded}
              onReady={onReady}
              onProgress={onProgress}
              onPlay={() => {
                setLoading(false);
                setShowOverlay(false);

                // eslint-disable-next-line no-unused-expressions
                reactPlayerWrapperRef.current?.scrollIntoView(false);
              }}
              onSeek={onSeek}
              playing={
                !chromecastState.isConnected &&
                isUserAllowedToAccessClass &&
                playing
              }
              playsinline
              playbackRate={speed}
              volume={playerVolume}
              width={
                isReactPlayerHeightFixed
                  ? reactPlayerWrapperRef.current &&
                    reactPlayerWrapperRef.current?.offsetHeight /
                      DEFAULT_ASPECT_RATIO
                  : "100%"
              }
              height={
                isReactPlayerHeightFixed
                  ? reactPlayerWrapperRef.current &&
                    reactPlayerWrapperRef.current?.offsetHeight
                  : "100%"
              }
            />
            <PlayerOverlay>
              <ActionDisplay />
              <PlayAndPauseOverlay onClick={() => togglePlay(!playing)} />
            </PlayerOverlay>
            {loading && <LoaderCentered />}
          </ReactPlayerWrapper>
          <BottomBar hide={!showOverlayBars || showOverlay}>
            {/* @TODO create class player context to pass down state */}
            <AdvancedControls
              classPlayerFeatures={classPlayerFeatures}
              isFrontView={isFrontView}
              isShowingSpeedAdjustment={isShowingSpeedAdjustment}
              isMirrored={mirrored}
              partyStarted={partyState.started}
              playerWrapperRef={playerWrapperRef}
              showOverlay={showOverlay}
              showWebcam={showWebcam}
              toggleLoop={toggleLoop}
              toggleMirror={toggleMirror}
              toggleSpeedAdjustment={toggleSpeedAdjustment}
              toggleView={toggleView}
              toggleWebcam={toggleWebcam}
              trackSpeedEvent={trackSpeedEvent}
            />
            <ProgressBar
              currentTimeInSeconds={currentTimeInSeconds}
              onAfterChange={onAfterProgressBarChange}
              onAfterLoopingChange={onAfterLoopingChange}
              max={classData.duration_in_seconds}
              totalDuration={classData.duration}
            />
            <ControlBar
              quickSeekSeconds={QUICK_SEEK_SECONDS}
              isChromecastAvailable={chromecastState.isAvailable}
              endClass={endClass}
              fullscreen={showFullscreen}
              goToNextClass={goToNextClass}
              hideContinuityCTA={hideContinuityCTA}
              isShowingVolumeAdjustment={isShowingVolumeAdjustment}
              nextClassData={nextClassData}
              playerWrapperRef={playerWrapperRef}
              programData={programData}
              quality={quality}
              qualityLevels={videoStreamQualityLevels}
              replayClass={replayClass}
              setQuality={setQualityCallback}
              setVolume={(updatedVolume: number) => setVolume(updatedVolume)}
              trackVolumeEvent={trackVolumeEvent}
              showOverlay={showOverlay}
              showZendesk={showZendesk}
              skip={skip}
              toggleFullscreen={toggleFullscreen}
              togglePlay={togglePlay}
              toggleVolumeAdjustment={toggleVolumeAdjustment}
              volume={playerVolume}
              trackShortcutsModalEvent={trackShortcutsModalEvent}
              scrollToClassDetails={scrollToClassDetails}
            />
          </BottomBar>
          {classData && classVideo?.sections?.length > 0 && (
            <VideoSections
              classTitle={classData.title}
              classId={classData.id}
              partySessionId={userPartyData.sessionId}
              isPartyApprovedClass={isPartyApprovedClass}
              classDurationInSeconds={classData.duration_in_seconds}
              currentTimeInSeconds={currentTimeInSeconds}
              isLooping={looping}
              sections={classVideo.sections}
              showSections={showSections}
              toggleSections={setShowSections}
              setSectionName={setSectionName}
              setCuepointName={setCuepointName}
              seek={seek}
            />
          )}
          <TopBar hide={!showOverlayBars || showOverlay}>
            <div>
              <P1 color="white">Playing now:</P1>
              {programData && (
                <H2 color="white" mt={2}>
                  {programData.title}
                </H2>
              )}
              <H3 color="white" mt={2}>
                {classData.title}
              </H3>
            </div>
            <Flex justifyContent="center">
              {/* TODO: Add end party button else where */}
              {!isFamilyModeOn && (
                <PartyButton
                  classId={classData.id}
                  togglePartyPopover={togglePartyPopover}
                  isPartyPopoverVisible={isPartyPopoverVisible}
                  isFreeClass={classData.isFree}
                  openSubscribeModal={openSubscribeModal}
                  hasActiveSubscription={isSubscriptionActive}
                />
              )}
            </Flex>
            {!showSections && (
              <Flex justifyContent="flex-end">
                <div>
                  <Button onClick={() => setShowSections(!showSections)}>
                    <H5>VIEW SECTIONS</H5>
                  </Button>
                </div>
              </Flex>
            )}
          </TopBar>

          {currentTrackInfoData && (
            <TrackInfoTopBar
              showOverlayBars={showOverlayBars}
              onHide={clearCurrentTrackInfoData}
              trackInfo={currentTrackInfoData}
            />
          )}

          {mutedCuepoint && (
            <Flex
              position="absolute"
              top={showOverlayBars ? "140px" : "32px"}
              left="32px"
              transition="top 0.25s ease-in-out"
              zIndex="2"
            >
              <Flex
                p="12px"
                color="white"
                bg="rgb(0, 0, 0, 0.5)"
                borderRadius="4px"
              >
                <Icon
                  as={VolumeOff}
                  width="26px"
                  height="26px"
                  mr="8px"
                  color="white"
                />
                <p>
                  This music can't be played due to the recent licensing changes with the rights holders.{" "}
                  {mutedCuepoint.externalUrl?.length > 0 && (
                    <>
                      To practice with music,{" "}
                      <a
                        href={mutedCuepoint.externalUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        please click here.
                      </a>
                    </>
                  )}
                </p>
              </Flex>
            </Flex>
          )}

          {showOverlay && (
            <CompletionOverlay
              classId={classData.id}
              closeOverlay={() => {
                setIsClassOverlayTimerStopped(false);
                setShowOverlay(false);
              }}
              isClassOverlayTimerStopped={isClassOverlayTimerStopped}
            >
              {(() => {
                if (nextClassData && !classData.programMetadata?.isLastClass) {
                  return (
                    <NextClassPrompt
                      goToNextClass={goToNextClass}
                      classData={nextClassData}
                      isClassOverlayTimerStopped={isClassOverlayTimerStopped}
                    />
                  );
                }
                if (classData.programMetadata?.isLastClass) {
                  return <LastClassPrompt programData={programData} />;
                }

                return null;
              })()}
            </CompletionOverlay>
          )}
          <ChromecastModal
            chromecastState={chromecastState}
            setChromecastState={setChromecastState}
            classData={classData}
            mediaUrl={
              isFrontView
                ? videoStream.nonDrmFrontUrl
                : videoStream.nonDrmBackUrl
            }
            browserPlayerRef={reactPlayerRef}
            browserPlayerCurrentTime={reactPlayerRef.current?.getCurrentTime()}
            seekBrowserPlayer={seekReactPlayer}
            shouldForceEndPlayback={isConcurrentStreamCheckModalOpen}
          />
        </PlayerWrapper>
        {partyState.started &&
          userPartyData.accessToken &&
          userPartyData.id && (
            <ResizeableParty
              wrapperWidth={wrapperRef?.current?.offsetWidth}
              wrapperHeight={wrapperRef?.current?.offsetHeight}
              resizeVideo={resizeVideo}
            />
          )}
      </Flex>
      <ConcurrentStreamModal
        isConcurrentStreamCheckModalOpen={isConcurrentStreamCheckModalOpen}
        setIsConcurrentStreamCheckModalOpen={
          setIsConcurrentStreamCheckModalOpen
        }
      />
    </ReactResizeDetector>
  );
};

export default ClassPlayer;
