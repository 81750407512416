import React from "react";
import styled from "styled-components";
import StarRatings from "app/components/StarRatings";
import Flex from "app/components/Flex";
import { H1 } from "app/components/Typography";
import Icon, { CloseCircle } from "app/components/Icon";
import Img from "app/components/Img";
import { ModalWithContext } from "app/components/Modal";
import { BenefitsText } from "../BenefitsText";
import styles from "./styles.module.scss";

const StyledModal = styled(ModalWithContext)`
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
`;

const ModalCard = styled.div`
  background-color: ${({ theme }) => theme.colors.monochrome[0]};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-basis: auto;
  width: 950px;
  height: 650px;
`;

const LeftPane = styled(Flex)`
  flex-direction: column;
  color: ${({ theme }) => theme.colors.monochrome[1]};
  background: ${({ theme }) => theme.colors.monochrome[8]};
  border-radius: 8px 0 0 8px;
  width: 400px;
`;

const ModalImg = styled(Img)`
  overflow: hidden;
  object-fit: cover;
`;

const Gradient = styled(Flex)`
  width: 400px;
  height: 40%;
  bottom: 0;
  position: absolute;
  background: linear-gradient(
    ${({ theme }) => theme.colors.monochrome[8]} 30%,
    transparent 45%
  );
`;

const RightPane = styled(Flex)`
  border-radius: 0 8px 8px 0;
  position: relative;
  width: 100%;
  flex-direction: column;
`;

interface Props {
  closeModal(): void;
  previewHeader: string;
  previewSrc: string;
  children: React.ReactNode;
  classAccessHeader?: string;
}

export const DesktopSubscribeModalWrapper = ({
  closeModal,
  previewHeader,
  previewSrc,
  children,
  classAccessHeader,
}: Props) => {
  return (
    <StyledModal
      onEscapeKeydown={closeModal}
      onBackgroundClick={closeModal}
      isOpen
    >
      <ModalCard>
        <LeftPane>
          <Flex alignItems="start" flexDirection="column" p={4}>
            <H1 textAlign="left" color="white" mb={2}>
              {previewHeader}
            </H1>
            <StarRatings mb={3} />
            <BenefitsText iconColor="monochrome.1" />
          </Flex>
          <Gradient />
          <ModalImg src={previewSrc} width="auto" height="100%" />
        </LeftPane>
        <RightPane>
          <div className={styles.closeButtonWrapper}>
            {classAccessHeader && (
              <h4 className={styles.classAccessHeader}>{classAccessHeader}</h4>
            )}
            <Icon onClick={closeModal} as={CloseCircle} />
          </div>
          <div className={styles.rightPaneContent}>{children}</div>
        </RightPane>
      </ModalCard>
    </StyledModal>
  );
};
