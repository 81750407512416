import React, { useState } from "react";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { useCheckout } from "modules/selectors";
import Button from "app/components/Button";
import { AccessType } from "services/graphql";
import { CheckoutFlow } from "../CheckoutFlow";
import { PlanSelect } from "../PlanSelect";
import { useStartSubscriptionPlans } from "../hooks";
import styles from "./styles.module.scss";

interface Props {
  accessTypeRequired: AccessType;
}

export const StartSubscriptionFlow = ({ accessTypeRequired }: Props) => {
  const { selectedPlan } = useCheckout();
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const {
    startSubscriptionPlans,
    selectedBillingPeriod,
    setSelectedBillingPeriod,
  } = useStartSubscriptionPlans({ accessTypeRequired });

  if (!startSubscriptionPlans) {
    return <LoaderCentered />;
  }

  return (
    <div className={styles.container}>
      {isCheckingOut ? (
        <CheckoutFlow setIsCheckingOut={setIsCheckingOut} />
      ) : (
        <>
          <PlanSelect
            headerText="Manage Your Plan"
            plans={startSubscriptionPlans}
            selectedBillingPeriod={selectedBillingPeriod}
            setSelectedBillingPeriod={setSelectedBillingPeriod}
          />
          {selectedPlan && (
            <Button onClick={() => setIsCheckingOut(true)}>
              Continue to Checkout
            </Button>
          )}
        </>
      )}
    </div>
  );
};
