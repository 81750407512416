import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  CaretLeft,
  ChevronDown,
  ExitDoor,
  Forward5,
  Fullscreen,
  FullscreenExit,
  Keyboard,
  Play,
  Pause,
  QuestionCircle,
  Replay,
  Rewind5,
  Settings,
  VolumeOff,
  VolumeOn,
} from "app/components/Icon";
import { H5, P2 } from "app/components/Typography";
import Flex from "app/components/Flex";
import { IS_MOBILE } from "constants/index";
import { endPartyAction, leavePartyAction } from "modules/party";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import Tooltip from "app/components/Tooltip";
import TooltipWithTimer from "app/components/Tooltip/TooltipWithTimer";
import { ScreenGtMd } from "app/components/MediaQueries";
import queryString from "query-string";
import { useGetPlaylist } from "app/routes/Playlists/hooks";
import ShortcutModal from "../ShortcutModal";
import { ControlButton, ControlIcon, Button } from "../components";
import NextButton from "../NextButton";
import VerticalSlider from "../VerticalSlider";
import {
  ControlWrapper,
  HoverAdjustmentTitle,
  ViewPageButton,
  StyledViewProgramText,
  SettingOption,
} from "./styles";

const ControlBar = ({
  isChromecastAvailable,
  endClass,
  fullscreen,
  goToNextClass,
  hideContinuityCTA,
  hideQualitySelector,
  isShowingVolumeAdjustment,
  nextClassData,
  playerWrapperRef,
  programData,
  quality,
  qualityLevels,
  quickSeekSeconds,
  replayClass,
  setQuality,
  setVolume,
  showOverlay,
  showZendesk,
  skip,
  toggleFullscreen,
  togglePlay,
  toggleVolumeAdjustment,
  volume,
  trackVolumeEvent,
  trackShortcutsModalEvent,
  scrollToClassDetails,
}) => {
  const location = useLocation();
  const { playlistId } = queryString.parse(location.search);
  const { playlist } = useGetPlaylist({ playlistId });
  const [showQualityOptions, toggleQualityOptions] = useState(false);
  const [showShortcuts, toggleShortcuts] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { isStopped, playing } = useSelector(({ classPlayer }) => classPlayer);
  const { started: isPartyStarted, lastEventLog, lastEventTime } = useSelector(
    ({ party }) => party
  );
  const { isHost: isPartyHost } = useSelector(
    ({ user }) => user.private?.party || {}
  );
  const { isFamilyModeOn } = useFamilyModeSetting();

  return (
    <ControlWrapper>
      <Flex alignItems="center">
        {isStopped ? (
          <ControlButton onClick={replayClass}>
            <ControlIcon as={Replay} />
          </ControlButton>
        ) : (
          <ControlButton onClick={() => togglePlay(!playing)}>
            <ControlIcon as={playing ? Pause : Play} />
          </ControlButton>
        )}
        {nextClassData && (
          <NextButton
            nextClassData={nextClassData}
            goToNextClass={goToNextClass}
          />
        )}
        {!IS_MOBILE && (
          <TooltipWithTimer
            getTooltipContainer={() => playerWrapperRef.current}
            visible={isShowingVolumeAdjustment}
            overlayClassName="player-controls tooltip-menu"
            onMouseEnter={() => toggleVolumeAdjustment(true)}
            onMouseLeave={() => toggleVolumeAdjustment(false)}
            overlay={
              <>
                <Flex height="150px" justifyContent="center" m="15px 0">
                  <VerticalSlider
                    min={0}
                    max={1}
                    value={volume}
                    defaultValue={volume}
                    step={0.01}
                    onChange={newSpeed => setVolume(newSpeed)}
                    onAfterChange={trackVolumeEvent}
                    tipFormatter={value => `${(value * 100).toFixed(0)}%`}
                  />
                </Flex>
              </>
            }
          >
            <Flex flexDirection="column" alignItems="center">
              <ControlButton
                onClick={() => {
                  const newVolume = volume ? 0 : 1;
                  setVolume(newVolume);
                  trackVolumeEvent(newVolume);
                }}
              >
                <ControlIcon as={volume ? VolumeOn : VolumeOff} />
              </ControlButton>
            </Flex>
          </TooltipWithTimer>
        )}
        <Tooltip
          getTooltipContainer={() => playerWrapperRef.current}
          overlay={`Rewind ${quickSeekSeconds}s`}
        >
          <ControlButton
            data-tip
            data-for="rewind"
            onClick={() => skip(-quickSeekSeconds)}
          >
            <ControlIcon as={Rewind5} />
          </ControlButton>
        </Tooltip>
        <Tooltip
          getTooltipContainer={() => playerWrapperRef.current}
          overlay={`Forward ${quickSeekSeconds}s`}
        >
          <ControlButton
            data-tip
            data-for="forward"
            onClick={() => !showOverlay && skip(quickSeekSeconds)}
          >
            <ControlIcon as={Forward5} />
          </ControlButton>
        </Tooltip>
      </Flex>

      <Flex flex="1" alignItems="center" justifyContent="center">
        {isPartyStarted && lastEventLog ? (
          <P2 color="white" textTransform="uppercase">
            <strong>{lastEventTime}</strong> - {lastEventLog}
          </P2>
        ) : (
          <ScreenGtMd>
            <Tooltip
              getTooltipContainer={() => playerWrapperRef.current}
              overlay="Class Details"
            >
              <ControlButton
                position={{ lg: "absolute" }}
                left={{ lg: "50% " }}
                ml={{ _: 3, lg: 0 }}
                data-tip
                data-for="scroll-details"
                onClick={scrollToClassDetails}
              >
                <ControlIcon color="white" as={ChevronDown} />
              </ControlButton>
            </Tooltip>
          </ScreenGtMd>
        )}
      </Flex>
      <Flex alignItems="center">
        {(() => {
          if (hideContinuityCTA) {
            return null;
          }

          if (isPartyStarted) {
            return (
              <Button
                mr={2}
                onClick={() =>
                  isPartyHost
                    ? dispatch(endPartyAction())
                    : dispatch(leavePartyAction())
                }
                px={[2, 3]}
                py={[1, 2]}
              >
                <H5 textTransform="uppercase">
                  {isPartyHost ? "END PARTY" : "LEAVE PARTY"}
                </H5>
              </Button>
            );
          }

          if (programData) {
            return (
              <ViewPageButton
                onClick={() => {
                  history.push(`/programs/${programData.slug}`);
                }}
              >
                <ControlIcon m={{ _: 0, lg: 2 }} as={ExitDoor} />
                {!IS_MOBILE && (
                  <StyledViewProgramText>View Program</StyledViewProgramText>
                )}
              </ViewPageButton>
            );
          }

          if (playlist) {
            return (
              <ViewPageButton
                onClick={() => {
                  history.push(`/playlists/${playlist.id}`);
                }}
              >
                <ControlIcon m={{ _: 0, lg: 2 }} as={ExitDoor} />
                {!IS_MOBILE && (
                  <StyledViewProgramText>View Playlist</StyledViewProgramText>
                )}
              </ViewPageButton>
            );
          }

          return (
            <Button mr={2} onClick={endClass} px={[2, 3]} py={[1, 2]}>
              <H5>END CLASS</H5>
            </Button>
          );
        })()}
        {!IS_MOBILE && (
          <Tooltip
            getTooltipContainer={() => playerWrapperRef.current}
            overlay="Keyboard Shortcuts"
          >
            <ControlButton
              onClick={() => {
                if (!showOverlay) {
                  toggleShortcuts(true);
                  trackShortcutsModalEvent();
                }
              }}
            >
              <ControlIcon as={Keyboard} />
            </ControlButton>
          </Tooltip>
        )}
        {!isFamilyModeOn && (
          <Tooltip
            getTooltipContainer={() => playerWrapperRef.current}
            overlay="Need help with this class?"
          >
            <ControlButton onClick={showZendesk}>
              <ControlIcon as={QuestionCircle} />
            </ControlButton>
          </Tooltip>
        )}
        {isChromecastAvailable && (
          <Tooltip overlay="Chromecast">
            <ControlButton>
              <ControlIcon>
                <google-cast-launcher
                  style={{
                    "--connected-color": "white",
                    "--disconnected-color": "white",
                  }}
                />
              </ControlIcon>
            </ControlButton>
          </Tooltip>
        )}
        {!hideQualitySelector && (
          <TooltipWithTimer
            getTooltipContainer={() => playerWrapperRef.current}
            visible={!showOverlay && showQualityOptions}
            overlayClassName="player-controls no-padding"
            onMouseEnter={() => toggleQualityOptions(true)}
            onMouseLeave={() => toggleQualityOptions(false)}
            onTouchStart={() => toggleQualityOptions(true)}
            onTouchCancel={() => toggleQualityOptions(false)}
            overlay={
              <>
                <HoverAdjustmentTitle>Quality</HoverAdjustmentTitle>
                {qualityLevels &&
                  qualityLevels.map(level => (
                    <SettingOption
                      key={level.label}
                      selected={quality === level.label}
                      onClick={() => {
                        toggleQualityOptions(false);
                        setQuality(level);
                      }}
                    >
                      {level.label}&nbsp;
                      {quality === level.label && <CaretLeft size="14px" />}
                    </SettingOption>
                  ))}
              </>
            }
          >
            <ControlButton>
              <ControlIcon as={Settings} />
            </ControlButton>
          </TooltipWithTimer>
        )}
        {!IS_MOBILE && (
          <Tooltip
            getTooltipContainer={() => playerWrapperRef.current}
            overlay={fullscreen ? "Exit Fullscreen" : "Fullscreen"}
            placement="topRight"
          >
            <ControlButton
              data-tip
              data-for="fullscreen"
              onClick={() => !showOverlay && toggleFullscreen()}
            >
              <ControlIcon as={fullscreen ? FullscreenExit : Fullscreen} />
            </ControlButton>
          </Tooltip>
        )}
      </Flex>
      <ShortcutModal
        isOpen={showShortcuts}
        toggleModal={() => toggleShortcuts(!showShortcuts)}
      />
    </ControlWrapper>
  );
};

ControlBar.defaultProps = {
  hideContinuityCTA: false,
  isShowingVolumeAdjustment: false,
  programData: null,
  nextClassData: null,
  qualityLevels: [],
  showOverlay: false,
};

ControlBar.propTypes = {
  isChromecastAvailable: PropTypes.bool.isRequired,
  endClass: PropTypes.func.isRequired,
  fullscreen: PropTypes.bool.isRequired,
  goToNextClass: PropTypes.func.isRequired,
  hideContinuityCTA: PropTypes.bool,
  hideQualitySelector: PropTypes.bool.isRequired,
  isShowingVolumeAdjustment: PropTypes.bool,
  nextClassData: PropTypes.shape({
    id: PropTypes.number,
  }),
  playerWrapperRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  programData: PropTypes.shape({}),
  quality: PropTypes.string.isRequired,
  qualityLevels: PropTypes.instanceOf(Array),
  replayClass: PropTypes.func.isRequired,
  setQuality: PropTypes.func.isRequired,
  setVolume: PropTypes.func.isRequired,
  showOverlay: PropTypes.bool,
  showZendesk: PropTypes.func.isRequired,
  skip: PropTypes.func.isRequired,
  toggleFullscreen: PropTypes.func.isRequired,
  togglePlay: PropTypes.func.isRequired,
  toggleVolumeAdjustment: PropTypes.func.isRequired,
  volume: PropTypes.number.isRequired,
  trackVolumeEvent: PropTypes.func.isRequired,
  trackShortcutsModalEvent: PropTypes.func.isRequired,
};

export default ControlBar;
