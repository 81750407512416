import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ResizableBox } from "react-resizable";
import Room from "app/components/VideoChat";
import { ResizeableWrapper, ResizeableHandle } from "./components";

const ResizeableParty = ({ wrapperWidth, wrapperHeight, resizeVideo }) => {
  const [width, setWidth] = useState(wrapperWidth / 2 || 300);
  const [containerHeight, setContainerHeight] = useState(wrapperHeight);

  const onResize = (event, { size }) => {
    setWidth(size.width);
    setContainerHeight(size.height);
    resizeVideo();
  };

  useEffect(() => {
    // maxConstraints does not update on window resize
    // We need to update the container width manually
    if (width > wrapperWidth / 2) {
      setWidth(wrapperWidth / 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperWidth]);

  useEffect(() => {
    setContainerHeight(wrapperHeight);
  }, [containerHeight, wrapperHeight]);

  return (
    <ResizeableWrapper>
      <ResizableBox
        height={wrapperHeight}
        width={width}
        handle={ResizeableHandle}
        resizeHandles={["w"]}
        onResize={onResize}
        // https://github.com/STRML/react-resizable/issues/38#issuecomment-554625611
        draggableOpts={{
          offsetParent: document.body,
        }}
        axis="x"
        minConstraints={[300, wrapperHeight]}
        maxConstraints={[wrapperWidth / 2, wrapperHeight]}
      >
        <Room containerWidth={width} containerHeight={containerHeight} />
      </ResizableBox>
    </ResizeableWrapper>
  );
};

ResizeableParty.propTypes = {
  wrapperWidth: PropTypes.number.isRequired,
  wrapperHeight: PropTypes.number.isRequired,
  resizeVideo: PropTypes.func.isRequired,
};

export default ResizeableParty;
