import React from "react";
import { ScreenGtLg, ScreenLtLg } from "app/components/MediaQueries";
import env from "helpers/env";
import { Class, StudioClass, SubscriptionProvider } from "services/graphql";
import { usePrimarySubscription } from "hooks/Subscriptions/usePrimarySubscription";
import styled from "styled-components";
import zendesk from "helpers/zendesk";
import { useIsLargeScreen } from "hooks/index";
import { DesktopSubscribeModalWrapper } from "./DesktopSubscribeModalWrapper";
import { MobileSubscribeModalWrapper } from "./MobileSubscribeModalWrapper";
import { UpgradePlanFLow } from "./UpgradePlanFlow";
import { StartSubscriptionFlow } from "./StartSubscriptionFlow";
import { SubscribeModalContext } from "./context";
import { ReactivateSubscriptionFlow } from "./ReactivateSubscriptionFlow";
import { ModalWithContext } from "../Modal";
import Alert from "../Alert";
import SpanLink from "../SpanLink";
import Div from "../Div";
import Icon, { CloseCircle } from "../Icon";
import styles from "./styles.module.scss";

interface Props {
  classData?: Class | StudioClass;
  closeModal(): void;
  isOpen: boolean;
}

export const SubscribeModal = ({ classData, isOpen, closeModal }: Props) => {
  const { primarySubscription } = usePrimarySubscription();
  const { isActive: isSubscriptionActive } = primarySubscription || {};

  if (!isOpen) {
    return null;
  }

  let previewSrc =
    `${env("PUBLIC_ASSET_PATH")}/onboarding/checkout-screen.jpg` ||
    `${env(
      "PUBLIC_ASSET_PATH"
    )}/onboarding/checkout-screen.jpg?blur=200&px=16&auto=format`;
  let classAccessHeader = null;

  if (classData) {
    previewSrc = `${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
      classData.slug
    }-basic.jpg`;
    previewSrc =
      `${env("PUBLIC_ASSET_PATH")}/onboarding/checkout-screen.jpg` ||
      `${env(
        "PUBLIC_ASSET_PATH"
      )}/onboarding/checkout-screen.jpg?blur=200&px=16&auto=format`;
    const capitalizedAccessType =
      classData?.accessType.charAt(0).toUpperCase() +
      classData?.accessType.slice(1);
    classAccessHeader = `This class is available on the ${capitalizedAccessType} subscription plan.`;
  }

  const canReactivate = primarySubscription?.isReactivatableV2;
  const checkoutHeader =
    !isSubscriptionActive && canReactivate
      ? "Reactivate your subscription"
      : "Start your subscription";
  const canUpgrade = isSubscriptionActive && !canReactivate;
  const previewHeader = canUpgrade
    ? "Upgrade your subscription"
    : checkoutHeader;
  const CheckoutFlow = canReactivate
    ? ReactivateSubscriptionFlow
    : StartSubscriptionFlow;
  const SubscribeFlow = canUpgrade ? UpgradePlanFLow : CheckoutFlow;

  if (primarySubscription?.provider?.name === SubscriptionProvider.Revenuecat) {
    return <MobileSubscriberModal closeModal={closeModal} />;
  }

  return (
    <SubscribeModalContext.Provider value={{ closeModal }}>
      <ScreenGtLg>
        <DesktopSubscribeModalWrapper
          closeModal={closeModal}
          previewSrc={previewSrc}
          previewHeader={previewHeader}
          classAccessHeader={classAccessHeader}
        >
          <SubscribeFlow accessTypeRequired={classData.accessType} />
        </DesktopSubscribeModalWrapper>
      </ScreenGtLg>
      <ScreenLtLg>
        <MobileSubscribeModalWrapper
          closeModal={closeModal}
          classAccessHeader={classAccessHeader}
        >
          <SubscribeFlow accessTypeRequired={classData.accessType} />
        </MobileSubscribeModalWrapper>
      </ScreenLtLg>
    </SubscribeModalContext.Provider>
  );
};

const DesktopModal = styled(ModalWithContext)`
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  max-width: 768px;
`;

const MobileModal = styled(ModalWithContext)`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  border-radius: 0;
  overflow: auto;
  padding: 0;
`;

function MobileSubscriberModal({ closeModal }: { closeModal: () => void }) {
  const isLargeScreen = useIsLargeScreen();

  const Modal = isLargeScreen ? DesktopModal : MobileModal;
  return (
    <SubscribeModalContext.Provider value={{ closeModal }}>
      <ScreenGtLg>
        <Modal
          onEscapeKeydown={closeModal}
          onBackgroundClick={closeModal}
          isOpen
        >
          <Div p="12px">
            <div className={styles.closeButtonWrapper}>
              <h4>Upgrade Subscription</h4>
              <Icon onClick={closeModal} as={CloseCircle} />
            </div>

            <Alert variant="danger" margin="0">
              Your subscription was created through the STEEZY Studio{" "}
              <a
                href="https://apps.apple.com/app/steezy-learn-how-to-dance/id1296001664"
                target="blank"
              >
                iOS
              </a>{" "}
              or{" "}
              <a
                href="https://play.google.com/store/apps/details?id=co.steezy.app"
                target="blank"
              >
                Android
              </a>{" "}
              mobile app. To upgrade your subscription, please open the app on
              your mobile device or{" "}
              <SpanLink onClick={() => zendesk("webWidget", "open")}>
                contact us for help!
              </SpanLink>
            </Alert>
          </Div>
        </Modal>
      </ScreenGtLg>
    </SubscribeModalContext.Provider>
  );
}
