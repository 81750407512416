import React from "react";
import PropTypes from "prop-types";
import { H2, H3, P } from "app/components/Typography";
import Flex from "app/components/Flex";
import Button from "app/components/Button";
import { ScreenGtMd, ScreenLtMd } from "app/components/MediaQueries";
import { userSelectsProgramAction } from "modules/content";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

const LastClassPrompt = ({ programData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isProgramCompleted = Boolean(programData.progress?.completedDate);
  const header = isProgramCompleted
    ? `You have completed ${programData.title}`
    : "You have reached the last class!";
  return (
    <Flex
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      p={3}
    >
      <ScreenGtMd>
        {isProgramCompleted && (
          <H2 color="white" mb={1}>
            Congratulations
          </H2>
        )}
        <H2 textAlign="center" color="white">
          {header}
        </H2>
      </ScreenGtMd>
      <ScreenLtMd>
        {isProgramCompleted && (
          <H3 color="white" mb={1}>
            Congratulations
          </H3>
        )}
        <H3 textAlign="center" color="white">
          {header}
        </H3>
      </ScreenLtMd>
      <P textAlign="center" color="white" mt={3}>
        {isProgramCompleted
          ? "Head back to programs page and to review any classes, or start a new program!"
          : "You have some classes remaining, return to program page to complete the program."}
      </P>
      <Flex flexDirection="column">
        <Button
          mt={4}
          onClick={() =>
            dispatch(
              userSelectsProgramAction({
                programSlug: programData.slug,
                component: "ContinuityOverlay",
                element: "ReturnToProgramsButton",
              })
            )
          }
        >
          Return to Program Page
        </Button>
        {isProgramCompleted && (
          <Button
            variant="inversePrimary"
            mt={3}
            onClick={() => history.push("/programs")}
          >
            Take a Different Program
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

LastClassPrompt.propTypes = {
  programData: PropTypes.shape({}).isRequired,
};

export default LastClassPrompt;
