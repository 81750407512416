import moment from "moment";

const dayparts = [
  { start: 1, end: 6, id: "late_fringe" },
  { start: 6, end: 9, id: "morning" },
  { start: 9, end: 15, id: "daytime" },
  { start: 15, end: 19, id: "early_fringe" },
  { start: 19, end: 23, id: "prime" },
  { start: 23, end: 1, id: "late_night" },
];

export default () => {
  const hour = moment().format("H");
  const index = dayparts.findIndex(
    daypart => hour >= daypart.start && hour < daypart.end
  );
  if (index !== -1) {
    return dayparts[index].id;
  }
  return dayparts[dayparts.length - 1].id;
};
