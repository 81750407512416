import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Div from "app/components/Div";
import useDebugMode from "hooks/useDebugMode";
import CloseButton from "app/components/Button/CloseButton";
import Text from "app/components/Text";
import ReactPlayer from "react-player/lazy";

const D_KEY_CODE = 68;

const printQuality = (levels, index) => {
  if (!levels || index < 0) {
    return "";
  }
  const { height, width, bitrate } = levels[index];

  return `Size: ${width}x${height}, Bitrate: ${bitrate}`;
};

const CloseDebugPanelButton = styled(CloseButton)`
  right: 10px;
`;

const UrlInfo = ({ url }) => {
  const { origin, pathname } = new URL(url);
  return (
    <Div paddingBottom="10px">
      <Text fontSize="12px">Main Manifest Origin:</Text>
      <Text fontSize="13px">{origin}</Text>
      <Text fontSize="12px">Main Manifest File Name:</Text>
      <Text fontSize="13px">{pathname}</Text>
    </Div>
  );
};

const markTimingInSeconds = (marks, key) => {
  const mark = marks.find(({ name }) => name === `steezy_player_${key}`);
  return mark?.startTime / 1000 || null;
};

const getDiff = (start, end) => {
  if (!start || !end) {
    return null;
  }
  return (end - start).toFixed(2);
};

// eslint-disable-next-line react/prop-types
const Timings = ({ performanceApi = window.performance }) => {
  const marks = performanceApi.getEntriesByType("mark");
  const mounted = markTimingInSeconds(marks, "mounted");
  const requestManifest = markTimingInSeconds(marks, "request_manifest");
  const fragStart = markTimingInSeconds(marks, "request_fragment_start");
  const fragEnd = markTimingInSeconds(marks, "request_fragment_end");

  return (
    <Div paddingTop="10px">
      <Text>Timings (seconds since player mounted):</Text>
      <Diff text="Player Load" diff={getDiff(mounted, requestManifest)} />
      <Diff text="Playlist Parsed" diff={getDiff(requestManifest, fragStart)} />
      <Diff text="Video Downloaded" diff={getDiff(fragStart, fragEnd)} />
    </Div>
  );
};

const Diff = ({ text, diff }) => {
  if (!diff) {
    return null;
  }

  return (
    <Text fontSize="13px">
      {text}: <b>{diff}</b> seconds
    </Text>
  );
};

const PlayerInfo = ({ player }) => {
  const hlsPlayer = player?.getInternalPlayer("hls");
  if (!hlsPlayer) {
    return <Text>Waiting for HLS player to load...</Text>;
  }
  const { levels, currentLevel, startLevel, url } = hlsPlayer;

  return (
    <Div>
      <UrlInfo url={url} />
      <Text fontSize="12px">Initial Video Quality:</Text>
      <Text fontSize="14px">{printQuality(levels, startLevel)}</Text>
      <Text fontSize="12px">Current Video Quality:</Text>
      <Text fontSize="14px">{printQuality(levels, currentLevel)}</Text>
      <Timings />
    </Div>
  );
};

const DebugPanel = ({ player }) => {
  const isDebugMode = useDebugMode();
  const [isShowing, setIsShowing] = useState(true);

  const hidePanel = () => setIsShowing(false);

  useEffect(() => {
    const onKeyDown = e => {
      if (e.keyCode === D_KEY_CODE) {
        setIsShowing(showing => !showing);
      }
    };

    document.addEventListener("keydown", onKeyDown);

    return () => document.removeEventListener("keydown", onKeyDown);
  }, []);

  if (!isDebugMode || !isShowing) {
    return null;
  }

  return (
    <Div
      width="45%"
      height="40%"
      position="fixed"
      zIndex="10"
      margin="20px"
      padding="10px"
      backgroundColor="#fff"
      opacity={0.9}
      border="1px solid black"
      overflow="scroll"
    >
      <CloseDebugPanelButton onClick={hidePanel} />
      <Text paddingBottom="20px">
        <b>VIDEO DEBUG PANEL</b> (press &quot;D&quot; to toggle)
      </Text>
      <PlayerInfo player={player} />
    </Div>
  );
};

UrlInfo.propTypes = {
  url: PropTypes.string.isRequired,
};

Diff.propTypes = {
  text: PropTypes.string.isRequired,
  diff: PropTypes.string.isRequired,
};

PlayerInfo.propTypes = {
  player: PropTypes.instanceOf(ReactPlayer),
};

PlayerInfo.defaultProps = {
  player: null,
};

DebugPanel.propTypes = {
  player: PropTypes.instanceOf(ReactPlayer),
};

DebugPanel.defaultProps = {
  player: null,
};

export default DebugPanel;
