import { useEffect, useRef } from "react";

/**
 * This is useful when dealing with async setups (e.g. connection setup).
 * Use this once completed to see if the component was unmounted
 * while the async was resolving.
 */
export function useMountedRef() {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  // Have to return object to use pointer, can't return `mounted.current` val.
  return mounted;
}
