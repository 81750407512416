import React, { useState } from "react";
import { BillingPeriodUnit, Plan, SubscriptionStatus } from "services/graphql";
import { usePrimarySubscription } from "hooks/Subscriptions/usePrimarySubscription";
import moment from "moment";
import { centsToDollars } from "helpers/centsToDollars";
import { AbbreviatedPeriodUnits, PeriodUnitAdverbs } from "constants/cart";
import { ArrowDown } from "app/components/Icon";
import Button from "app/components/Button";
import Loader from "app/components/Loader";
import { useSubscriptionSwitchPlan } from "app/routes/Account/Subscription/hooks";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import Alert from "app/components/Alert";
import SpanLink from "app/components/SpanLink";
import zendesk from "helpers/zendesk";
import styles from "./styles.module.scss";
import { useSubscribeModalContext } from "../hooks";

interface Props {
  plan: Plan;
  cancelUpgradeFlow(): void;
}

export function ConfirmUpgradePlanFlow({ plan, cancelUpgradeFlow }: Props) {
  const [error, setError] = useState(null);
  const { closeModal } = useSubscribeModalContext();
  const {
    primarySubscription,
    loadingPrimarySubscription,
  } = usePrimarySubscription();
  const {
    subscriptionSwitchPlan,
    subscriptionSwitchPlanLoading,
  } = useSubscriptionSwitchPlan();

  if (!plan) {
    return <LoaderCentered />;
  }

  if (!primarySubscription || loadingPrimarySubscription) {
    return <LoaderCentered />;
  }

  const isTrialing = primarySubscription.status === SubscriptionStatus.InTrial;
  const { canTrial, changePlanEstimate } = plan;
  const { isEndOfTerm, invoiceEstimate } = changePlanEstimate || {};
  let amountDueText = "";

  if (!invoiceEstimate) {
    return (
      <Alert variant="danger">
        There was an error loading your estimate. Please refresh page or{" "}
        <SpanLink onClick={() => zendesk("webWidget", "open")}>
          contact us for help!
        </SpanLink>
      </Alert>
    );
  }

  const amountDueAfterPeriodInDollars = invoiceEstimate.total / 100;
  const amountDueTodayInDollars = invoiceEstimate.amountDue / 100;
  const amountDueToday =
    isTrialing || isEndOfTerm ? 0 : amountDueTodayInDollars;
  const currentPlan = primarySubscription.plan;

  if (canTrial && isTrialing) {
    amountDueText = `after trial (${moment(
      primarySubscription.trialEndTime
    ).format("MM/DD/YY")})`;
  } else if (isEndOfTerm) {
    amountDueText = `after billing period ends (${moment(
      primarySubscription.currentTermEnd
    ).format("MM/DD/YY")})`;
  } else {
    amountDueText = "next billing period";
  }

  const handleSwitchPlan = async () => {
    try {
      await subscriptionSwitchPlan({
        subscriptionId: primarySubscription.id,
        planId: plan.id,
      });
      closeModal();
    } catch {
      setError(
        "There was an error switching your plan, please refresh page and try again."
      );
    }
  };

  return (
    <>
      <div>
        <div className={styles.headerContainer}>
          <h1>Upgrade Plan</h1>
        </div>
        {error && (
          <Alert variant="danger" closeAlert={() => setError(null)}>
            {error}{" "}
            <SpanLink onClick={() => zendesk("webWidget", "open")}>
              If you are having issues, please contact us for help!
            </SpanLink>
          </Alert>
        )}
        <div className={styles.plansContainer}>
          <div className={styles.toggledPlanCardContainer}>
            <PlanCardDetails
              plan={currentPlan}
              header={`Current Plan: ${currentPlan.accessType}`}
            />
          </div>
          <ArrowDown width="20px" />
          <div className={styles.planCardContainer}>
            <PlanCardDetails
              plan={plan}
              header={`New Plan: ${plan.accessType}`}
            />
          </div>
        </div>
        <div className={styles.amountDueContainer}>
          <p>Amount due {amountDueText}</p>
          <strong>${amountDueAfterPeriodInDollars}</strong>
        </div>
        <div className={styles.totalsContainer}>
          <p>Today&apos;s total</p>
          <strong>${amountDueToday}</strong>
        </div>
        <span className={styles.spanContainer}>
          You are upgrading to the{" "}
          <strong>
            {plan.accessType}{" "}
            {
              PeriodUnitAdverbs[
                plan?.periodUnit as keyof typeof PeriodUnitAdverbs
              ]
            }{" "}
          </strong>
          plan.
          {isEndOfTerm
            ? ` Your plan will switch after the end your current billing term: ${moment(
                primarySubscription.currentTermEnd
              ).format("MM/DD/YY")}`
            : " This change will be effective immediately."}
        </span>
        <br />
        {!isEndOfTerm && (
          <span className={styles.spanContainer}>
            Your total payment of <strong>${amountDueToday}</strong> will be
            charged immediately.
          </span>
        )}
      </div>
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => {
            cancelUpgradeFlow();
          }}
          className={styles.cancelButton}
          disabled={subscriptionSwitchPlanLoading}
        >
          Nevermind
        </Button>
        <Button
          onClick={() => {
            handleSwitchPlan();
          }}
          className={styles.confirmButton}
          disabled={subscriptionSwitchPlanLoading}
        >
          {subscriptionSwitchPlanLoading ? (
            <Loader width="20" />
          ) : (
            <>Upgrade Plan</>
          )}
        </Button>
      </div>
    </>
  );
}

function PlanCardDetails({
  plan,
  header,
}: {
  plan: Partial<Plan>;
  header: string;
}) {
  return (
    <>
      <div className={styles.planCardHeaderContainer}>
        <header>{header}</header>
      </div>
      <div className={styles.planCardSubContainer}>
        <div>
          <div className={styles.planCardCostContainer}>
            <h2>${centsToDollars(plan.monthlyCostInCents)}/mo</h2>
            {plan?.periodUnit !== BillingPeriodUnit.Month && (
              <p>
                (Billed ${centsToDollars(plan.priceInCents)}/
                {
                  AbbreviatedPeriodUnits[
                    plan?.periodUnit as keyof typeof AbbreviatedPeriodUnits
                  ]
                }
                )
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
