import React, { useCallback, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import styled from "styled-components";
import env from "helpers/env";
import {
  Forward15,
  Play,
  Pause,
  Rewind15,
  VolumeOff,
  VolumeOn,
} from "app/components/Icon";
import { ControlButton, ControlIcon } from "./components";
import Tooltip from "../Tooltip";

const NoticeWrapper = styled(Div)`
  z-index: 99999;
  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`;

export const DEFAULT_CHROMECAST_STATE = {
  canControlVolume: false,
  canPause: false,
  canSeek: false,
  deviceName: "TV",
  isAvailable: false,
  isConnected: false,
  isPlaying: false,
  volumeLevel: null,
};

/**
 * Custom Chromecast impl was causing issues with built-in browser casting,
 * presumably because of all the window script modifications.
 *
 * Leaving the logic here for now; seems like we want to investigate short term.
 * Long term, if not fixed, Chromecast logic in /ClassPlayer/Player &
 * /ClassPlayer/ControlBar from commit f136159ab0340e28b3d000b6781df74f2841bf62
 * can safely be removed/cleaned up.
 */
export function ChromecastModal() {
  return null;
}

// Receiver app is an html file available in the Prod Video Outputs S3 Bucket
const ChromecastModalCustom = ({
  browserPlayerRef,
  chromecastState,
  classData,
  mediaUrl,
  setChromecastState,
  shouldForceEndPlayback,
}) => {
  const remotePlayerRef = useRef(null); // reference to the Chromecast player, used for reading properties
  const remotePlayerControllerRef = useRef(null); // reference to the Chromecast controller, which makes changes on Chromecast
  const didSeekToStartTimeRef = useRef(null); // tracks whether we have seeked the Chromecast player to where the browser player left off

  // start and stop functions
  const startChromecastPlayback = async () => {
    try {
      const castContextInstance = window.cast.framework.CastContext.getInstance();
      await castContextInstance
        .getCurrentSession()
        .loadMedia(
          new window.chrome.cast.media.LoadRequest(
            new window.chrome.cast.media.MediaInfo(
              mediaUrl,
              "application/x-mpegURL"
            )
          )
        );
      remotePlayerRef.current = new window.cast.framework.RemotePlayer();
      remotePlayerControllerRef.current = new window.cast.framework.RemotePlayerController(
        remotePlayerRef.current
      );
      didSeekToStartTimeRef.current = false;
      setChromecastState({ ...chromecastState, isConnected: true });
    } catch (e) {
      // error playing content
      toast.error("Could not play content on Chromecast.");
    }
  };

  const endChromecastPlayback = (isAvailable = true) => {
    if (chromecastState.currentTime) {
      browserPlayerRef.current.seekTo(chromecastState.currentTime);
    }
    setChromecastState({
      ...chromecastState,
      isConnected: false,
      isAvailable,
    });
    remotePlayerControllerRef.current = null;
    remotePlayerRef.current = null;
  };

  // chromecast event handlers
  const handlePlayerChange = useCallback(() => {
    if (!remotePlayerRef.current) {
      return;
    }

    // update current session player state
    const {
      canControlVolume,
      canPause,
      canSeek,
      currentTime,
      isPaused,
      playerState,
      volumeLevel,
    } = remotePlayerRef.current;
    setChromecastState(oldChromecastState => ({
      ...oldChromecastState,
      canControlVolume,
      canPause,
      canSeek,
      // use Chromecast player's remote currentTime, otherwise playback has ended so use the latest available time
      currentTime: currentTime || oldChromecastState.currentTime,
      deviceName:
        window.cast.framework.CastContext.getInstance()
          ?.getCurrentSession()
          ?.getCastDevice()?.friendlyName || "TV",
      isPlaying:
        playerState !== window.chrome.cast.media.PlayerState.IDLE && !isPaused,
      volumeLevel,
    }));

    // seek to initial starting time when playback on Chromecast starts
    if (!didSeekToStartTimeRef.current && canSeek) {
      didSeekToStartTimeRef.current = true;
      const currentBrowserPlayerTime = browserPlayerRef.current?.getCurrentTime();
      if (currentBrowserPlayerTime) {
        seek(currentBrowserPlayerTime);
      }
    }
  }, [browserPlayerRef, setChromecastState]);

  const handleCastStateChange = async csce => {
    switch (csce.castState) {
      case window.cast.framework.CastState.CONNECTED:
        startChromecastPlayback();
        break;
      case window.cast.framework.CastState.NOT_CONNECTED:
        endChromecastPlayback();
        break;
      case window.cast.framework.CastState.NO_DEVICES_AVAILABLE:
        endChromecastPlayback(false);
        break;
      default:
        break;
    }
  };

  // initialization
  useEffect(() => {
    if (!mediaUrl) {
      return;
    }

    window.__onGCastApiAvailable = isAvailable => {
      if (isAvailable) {
        // initialize cast options
        window.cast.framework.CastContext.getInstance().setOptions({
          receiverApplicationId:
            env("PUBLIC_CHROMECAST_RECEIVER_APP_ID") ||
            window.chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
          autoJoinPolicy: window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
        });

        const initialCastState = window.cast.framework.CastContext.getInstance().getCastState();

        // firing handleCastStateChange manually since it's not fired intially
        handleCastStateChange({ castState: initialCastState });
      }
    };
    const script = document.createElement("script");
    script.src =
      "//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1";
    document.head.appendChild(script);
  }, [!!mediaUrl]);

  useEffect(() => {
    if (shouldForceEndPlayback) {
      /**
       * @TODO need to `endChromecastPlayback` AND manipulate chromecast state on `window`.
       *
       * If can do properly, likely don't need explicit `endChromecastPlayback`;
       * `handleCastStateChange` should do so implicitly.
       */
    }
  }, [shouldForceEndPlayback]);

  useEffect(() => {
    if (window.cast && window.cast.framework) {
      window.cast.framework.CastContext.getInstance().addEventListener(
        window.cast.framework.CastContextEventType.CAST_STATE_CHANGED,
        handleCastStateChange
      );
    }
    if (remotePlayerControllerRef.current) {
      remotePlayerControllerRef.current.addEventListener(
        window.cast.framework.RemotePlayerEventType.ANY_CHANGE,
        handlePlayerChange
      );
    }

    return () => {
      if (window.cast && window.cast.framework) {
        window.cast.framework.CastContext.getInstance().removeEventListener(
          window.cast.framework.CastContextEventType.CAST_STATE_CHANGED,
          handleCastStateChange
        );
      }
      if (remotePlayerControllerRef.current) {
        remotePlayerControllerRef.current.removeEventListener(
          window.cast.framework.RemotePlayerEventType.ANY_CHANGE,
          handlePlayerChange
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chromecastState]);

  // remote player controls
  const seek = newCurrentTime => {
    if (remotePlayerControllerRef.current && remotePlayerRef.current) {
      remotePlayerRef.current.currentTime = newCurrentTime;
      remotePlayerControllerRef.current.seek();
    }
  };
  const handlePlayToggle = () => {
    if (remotePlayerControllerRef.current) {
      remotePlayerControllerRef.current.playOrPause();
    }
  };
  const handleSeek = secondDiff => {
    if (remotePlayerRef.current) {
      seek(remotePlayerRef.current.currentTime + secondDiff);
    }
  };
  const handleVolumeToggle = () => {
    if (remotePlayerControllerRef.current && remotePlayerRef.current) {
      if (remotePlayerRef.current.volumeLevel > 0) {
        remotePlayerRef.current.prevVolumeLevel =
          remotePlayerRef.current.volumeLevel;
        remotePlayerRef.current.volumeLevel = 0;
        remotePlayerControllerRef.current.setVolumeLevel();
      } else {
        remotePlayerRef.current.volumeLevel =
          remotePlayerRef.current.prevVolumeLevel || 0.1;
        remotePlayerControllerRef.current.setVolumeLevel();
      }
    }
  };

  const {
    canControlVolume,
    canPause,
    canSeek,
    deviceName,
    isConnected,
    isPlaying,
    volumeLevel,
  } = chromecastState;

  if (!isConnected) {
    return null;
  }

  return (
    <NoticeWrapper>
      <h2>Playing on {deviceName}</h2>
      <h3 style={{ marginTop: "16px" }}>{classData.title}</h3>
      <Flex width="100vw" mt={3} alignItems="center" justifyContent="center">
        {canPause && (
          <Tooltip overlay={isPlaying ? "Pause" : "Play"}>
            <ControlButton onClick={handlePlayToggle}>
              <ControlIcon as={isPlaying ? Pause : Play} />
            </ControlButton>
          </Tooltip>
        )}
        {canSeek && (
          <>
            <Tooltip overlay="Rewind 15s">
              <ControlButton onClick={() => handleSeek(-15)}>
                <ControlIcon as={Rewind15} />
              </ControlButton>
            </Tooltip>
            <Tooltip overlay="Fast Forward 15s">
              <ControlButton onClick={() => handleSeek(15)}>
                <ControlIcon as={Forward15} />
              </ControlButton>
            </Tooltip>
          </>
        )}
        {canControlVolume && (
          <Tooltip overlay="Volume">
            <ControlButton onClick={handleVolumeToggle}>
              <ControlIcon as={volumeLevel > 0 ? VolumeOn : VolumeOff} />
            </ControlButton>
          </Tooltip>
        )}
        <Tooltip overlay="Chromecast">
          <ControlButton>
            <ControlIcon>
              <google-cast-launcher style={{ "--connected-color": "white" }} />
            </ControlIcon>
          </ControlButton>
        </Tooltip>
      </Flex>
    </NoticeWrapper>
  );
};

ChromecastModal.propTypes = {
  chromecastState: PropTypes.shape({
    isAvailable: PropTypes.bool,
    isConnected: PropTypes.bool,
    isPlaying: PropTypes.bool,
  }).isRequired,
  classData: PropTypes.shape({
    duration_in_seconds: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  mediaUrl: PropTypes.string.isRequired,
  setChromecastState: PropTypes.func.isRequired,
  shouldForceEndPlayback: PropTypes.bool,
  browserPlayerRef: PropTypes.shape({}),
  browserPlayerCurrentTime: PropTypes.shape({}),
  seekBrowserPlayer: PropTypes.func,
};
