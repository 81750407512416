import React, { useState, useEffect, useRef, memo } from "react";
import PropTypes from "prop-types";
import {
  PayPalButtons,
  usePayPalScriptReducer,
  FUNDING,
} from "@paypal/react-paypal-js";
import { P2 } from "app/components/Typography";
import Div from "app/components/Div";
import Loader from "app/components/Loader";
import ErrorReporter from "services/error-reporter";
import env from "helpers/env";

const PaypalButton = memo(
  ({
    buttonColor = "silver",
    buttonWidth = null,
    buttonHeight = null,
    onSubmit,
    onError,
  }) => {
    const [{ isPending }] = usePayPalScriptReducer();
    const paypalRef = useRef(null);
    const [error, setError] = useState(null);

    useEffect(() => {
      const loadImports = async () => {
        const imports = [
          import(
            /* webpackChunkName: "braintree-client" */ "braintree-web/client"
          ),
          import(
            /* webpackChunkName: "braintree-paypal-checkout" */ "braintree-web/paypal-checkout"
          ),
        ];
        const [braintreeClient, paypalCheckout] = await Promise.all(imports);
        const clientInstance = await braintreeClient.create({
          authorization: env("PUBLIC_BRAINTREE_AUTH_TOKEN"),
        });
        const paypalInstance = await paypalCheckout.create({
          client: clientInstance,
        });
        paypalRef.current = paypalInstance;
      };

      try {
        loadImports();
      } catch (err) {
        ErrorReporter.report(err);
        setError(err);
      }
    }, []);

    if (env("PUBLIC_PAYPAL_ENABLED") === "false") {
      return null;
    }

    return (
      <Div m={0} maxWidth={buttonWidth || "150px"}>
        {error && (
          <P2 color="red">
            Could not connect to PayPal. Please refresh the page and try again.
          </P2>
        )}
        {isPending ? (
          <Loader width={24} margin="0 auto" />
        ) : (
          <PayPalButtons
            forceReRender={[onSubmit]}
            style={{ color: buttonColor, height: buttonHeight || 35 }}
            fundingSource={FUNDING.PAYPAL}
            createBillingAgreement={async () =>
              paypalRef.current.createPayment({
                flow: "vault",
              })
            }
            onApprove={async data => {
              const payload = await paypalRef.current.tokenizePayment(data);
              onError(null);
              onSubmit(payload.nonce);
            }}
            onError={err => {
              ErrorReporter.report(err);
              setError(err);
            }}
          />
        )}
      </Div>
    );
  },
  () => true
);

PaypalButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  buttonColor: PropTypes.string,
  buttonHeight: PropTypes.number,
  buttonWidth: PropTypes.string,
};

export default PaypalButton;
