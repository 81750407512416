import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Modal from "../Modal";
import CloseButton from "../Button/CloseButton";

const StyledModal = styled(Modal)`
  width: 900px;
`;

const H4 = styled.h4`
  font-weight: bold;
`;

const ShortcutList = styled.ul`
  margin: 0;
  padding: 0;
`;

const Shortcut = styled.li`
  margin: 10px 0;
`;

const Key = styled.span`
  background: ${({ theme }) => theme.colors.lightGrey};
  border-radius: 5px;
  display: inline-block;
  font-weight: 400;
  margin-right: 4px;
  padding: 4px 8px;
`;

const ShortcutModal = ({ isOpen, toggleModal }) => (
  <StyledModal
    isOpen={isOpen}
    onBackgroundClick={toggleModal}
    onEscapeKeydown={toggleModal}
  >
    <CloseButton onClick={toggleModal} />
    <div>
      <H4>Keyboard Shortcuts</H4>
      <ShortcutList>
        <Shortcut>
          <Key>space</Key>
          <span>Play or pause</span>
        </Shortcut>
        <Shortcut>
          <Key>shift</Key>
          <span>Switch to front/back view</span>
        </Shortcut>
        <Shortcut>
          <Key>←</Key>
          <span>Seek backwards 5 seconds</span>
        </Shortcut>
        <Shortcut>
          <Key>→</Key>
          <span>Seek forwards 5 seconds</span>
        </Shortcut>
        <Shortcut>
          <Key>F</Key>
          <span>Toggle fullscreen mode</span>
        </Shortcut>
        <Shortcut>
          <Key>M</Key>
          <span>Toggle mirror on/off</span>
        </Shortcut>
        <Shortcut>
          <Key>L</Key>
          <span>Toggle loop mode on and off</span>
        </Shortcut>
        <Shortcut>
          <Key>H</Key>
          <span>Show/Hide Sections</span>
        </Shortcut>
        <Shortcut>
          <Key>-/+</Key>
          <span>Decrease/Increase Speed</span>
        </Shortcut>
      </ShortcutList>
    </div>
  </StyledModal>
);

ShortcutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default ShortcutModal;
