import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ResizableBox } from "react-resizable";
import Webcam from "react-webcam";
import {
  ResizeableWrapper,
  ResizeableHandle,
} from "app/components/ClassPlayer/components";

const WebcamWrapper = styled.div`
  height: 100%;
  width: ${props => props.width}px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const StyledWebcam = styled(Webcam)`
  object-fit: fill;
  height: 100%;
  width: auto;
  position: absolute;
  transform: rotateY(180deg);
`;

class WebcamPlayer extends React.Component {
  constructor(props) {
    super(props);

    const { playerWidth } = props;

    this.state = { maxPlayerWidthPercentage: 0.75, width: playerWidth / 2 };

    this.webcamRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { playerWidth } = this.props;
    const { maxPlayerWidthPercentage, width } = this.state;
    if (
      playerWidth !== prevProps.playerWidth &&
      width > playerWidth * maxPlayerWidthPercentage
    ) {
      this.setState({ width: playerWidth * maxPlayerWidthPercentage });
    }

    if (!width) {
      this.setState({ width: playerWidth / 2 });
    }
  }

  onResize = (event, { size }) => {
    const { onResize } = this.props;
    this.setState({ width: size.width });
    onResize();
  };

  render() {
    const { playerWidth, playerHeight, toggleWebcam } = this.props;
    const { maxPlayerWidthPercentage, width } = this.state;
    const webcamHeight =
      this.webcamRef &&
      this.webcamRef.current &&
      this.webcamRef.current.video &&
      this.webcamRef.current.video.offsetHeight;
    const webcamWidth =
      this.webcamRef &&
      this.webcamRef.current &&
      this.webcamRef.current.video &&
      this.webcamRef.current.video.offsetWidth;
    const maxWidth = playerWidth * maxPlayerWidthPercentage;

    return (
      <ResizeableWrapper>
        <ResizableBox
          height={playerHeight}
          width={width}
          handle={ResizeableHandle}
          onResize={this.onResize}
          resizeHandles={["e"]}
          axis="x"
          minConstraints={[200, 200]}
          maxConstraints={[
            (webcamWidth > maxWidth ? maxWidth : webcamWidth) || 600,
            webcamHeight || 600,
          ]}
        >
          <WebcamWrapper height={playerHeight} width={width}>
            <StyledWebcam
              onUserMediaError={() => {
                toggleWebcam();
                window.alert("Webcam not available.");
              }}
              audio={false}
              ref={this.webcamRef}
              height="100%"
              width="100%"
            />
          </WebcamWrapper>
        </ResizableBox>
      </ResizeableWrapper>
    );
  }
}

WebcamPlayer.propTypes = {
  onResize: PropTypes.func.isRequired,
  playerWidth: PropTypes.number.isRequired,
  playerHeight: PropTypes.number.isRequired,
  toggleWebcam: PropTypes.func.isRequired,
};

export default WebcamPlayer;
