import styled from "styled-components";

export const ControlWrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
  display: flex;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
`;

export const HoverAdjustmentTitle = styled.p`
  padding: 5px 20px;
  margin: 0;
  font-weight: bold;
  text-align: center;

  ${({ theme }) => theme.mediaQueries.ltmd} {
    padding: 5px 10px;
  }
`;

export const StyledA = styled.a`
  display: flex;
  justify-content: center;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.blue};
  margin: 8px 0;
`;
