import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { VIDEO_RATIO } from "constants/index.js";

const StyledDiv = styled.div`
  ${({ theme }) => theme.mediaQueries.gtmd} {
    height: calc(100vh - ${props => props.navbarHeight}px);
  }

  ${({ theme }) => `
    ${theme.mediaQueries.ltmd} {
      /* Added 120px to give class player padding for button on top and controlbar on bottom */
      height: calc(100vw / ${VIDEO_RATIO});
    }
  `}
`;

const Wrapper = ({ children }) => {
  const navbarHeight = useSelector(
    ({ components }) => components.Navbar.height
  );
  const ref = useRef();

  return (
    <StyledDiv navbarHeight={navbarHeight} ref={ref}>
      {React.cloneElement(children, { ref })}
    </StyledDiv>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Wrapper;
