import { chunk } from "lodash";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Flex from "app/components/Flex";
import { P2 } from "app/components/Typography";
import Grid from "app/components/Grid";
import Icon, {
  ChevronDown,
  MicOn,
  MicOff,
  VideoOn,
  VideoOff,
} from "app/components/Icon";
import Button from "app/components/Button";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { VIDEO_RATIO } from "constants/index";
import { muteParticipantsAction } from "modules/party";
import { useMountedRef } from "./hooks";
import Participant from "./Participant";

const HEIGHT_OF_ARROW_BUTTONS = 30;
const HEIGHT_OF_MEDIA_CONTROLS = 120;

const ArrowButton = styled.button`
  display: flex;
  height: ${HEIGHT_OF_ARROW_BUTTONS}px;
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  justify-content: center;
  align-items: center;
  border: 0;

  @media (hover: hover) {
    :hover {
      opacity: 0.7;
    }
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
`;

const useVideoCallApi = () => {
  const [videoCallApi, setVideoCallApi] = useState(null);
  const [errorVideoCallApi, setErrorVideoCallApi] = useState(false);

  useEffect(() => {
    import("twilio-video" /* webpackChunkName: "twilio" */)
      .then(setVideoCallApi)
      .catch(() => {
        // TODO: Report this via new error service (when merged).
        setErrorVideoCallApi(true);
      });
  }, []);

  const loadingVideoCallApi = !(videoCallApi || errorVideoCallApi);

  return { videoCallApi, loadingVideoCallApi, errorVideoCallApi };
};

const usePartyInfo = () => {
  const partyInfo = useSelector(({ user }) => user.private?.party);

  if (!partyInfo) {
    return null;
  }

  const { id, accessToken, sessionId, isHost } = partyInfo;
  return { id, accessToken, sessionId, isHost };
};

const usePartyRoom = (videoCallApi, { id, accessToken }) => {
  const [room, setRoom] = useState(null);
  const [remoteParticipants, setRemoteParticipants] = useState([]);

  const mounted = useMountedRef();

  useEffect(() => {
    if (!videoCallApi) {
      return;
    }

    const participantConnected = newParticipant => {
      setRemoteParticipants(prevParticipants => {
        // Removing duplicates
        return prevParticipants.reduce(
          (participants, participant) => {
            const index = participants.findIndex(
              p => p.identity === participant.identity
            );
            if (index === -1) {
              return [...participants, participant];
            }

            return participants;
          },
          [newParticipant]
        );
      });
    };

    const participantDisconnected = participant => {
      setRemoteParticipants(prevParticipants =>
        prevParticipants.filter(p => p.identity !== participant.identity)
      );
    };

    const connectToRoom = connectedRoom => {
      setRoom(connectedRoom);
      connectedRoom.on("participantConnected", participantConnected);
      connectedRoom.on("participantDisconnected", participantDisconnected);
      connectedRoom.participants.forEach(participantConnected);
    };

    const setUpParty = async () => {
      let connectedRoom = null;

      try {
        connectedRoom = await videoCallApi.connect(accessToken, { name: id });
        connectToRoom(connectedRoom);
      } catch (e) {
        try {
          connectedRoom = await videoCallApi.connect(accessToken, {
            name: id,
            video: false,
            audio: true,
          });
          connectToRoom(connectedRoom);
        } catch (err) {
          // Currently just swallowing error after failing to connect twice.
          // May want to show a user facing error eventually.
        }
      } finally {
        if (!mounted.current && connectedRoom) {
          connectedRoom.disconnect();
        }
      }
    };

    setUpParty();

    // eslint-disable-next-line consistent-return
    return () => {
      setRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(trackPublication => {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        }
        return currentRoom;
      });
    };
  }, [videoCallApi, id, accessToken, mounted]);

  return { room, remoteParticipants };
};

const Room = ({ containerHeight, containerWidth }) => {
  const dispatch = useDispatch();

  const [isVideoOn, toggleVideo] = useState(true);
  const [isAudioOn, toggleAudio] = useState(true);
  const [page, setPage] = useState(0);
  const [maxWidthOfVideoContainer, setMaxWidthOfVideoContainer] = useState(0);
  const [maxHeightOfVideoContainer, setMaxHeightOfVideoContainer] = useState(0);
  const [chunkedRemoteParticipants, setChunkedRemoteParticipants] = useState(
    []
  );
  const [numberOfColumns, setNumberOfColumns] = useState(1);

  const mutedParticipantsAt = useSelector(
    ({ party }) => party.mutedParticipantsAt
  );

  const {
    videoCallApi,
    loadingVideoCallApi,
    errorVideoCallApi,
  } = useVideoCallApi();

  const partyInfo = usePartyInfo();

  const { room, remoteParticipants } = usePartyRoom(videoCallApi, partyInfo);

  useEffect(() => {
    let updatedNumberOfColumns = 1;
    const totalParticipantCount = remoteParticipants.length + 1;

    if (containerWidth > 600 && remoteParticipants.length >= 1) {
      updatedNumberOfColumns = 2;
    }

    const adjustedContainerHeight = containerHeight - HEIGHT_OF_MEDIA_CONTROLS;

    const maxHeightUsingParticipantCount =
      adjustedContainerHeight /
      (Math.ceil(totalParticipantCount / updatedNumberOfColumns) || 1);
    const maxWidthUsingParticipantCount =
      maxHeightUsingParticipantCount * VIDEO_RATIO;

    const maxWidthUsingContainerSize = containerWidth / 2;
    const maxHeightUsingContainerSize =
      maxWidthUsingContainerSize / VIDEO_RATIO;

    const potentialMaxWidthOfContainer =
      (maxWidthUsingParticipantCount < maxWidthUsingContainerSize
        ? maxWidthUsingParticipantCount
        : maxWidthUsingContainerSize) * 2;
    setMaxWidthOfVideoContainer(potentialMaxWidthOfContainer);
    setMaxHeightOfVideoContainer(
      (maxHeightUsingParticipantCount < maxHeightUsingContainerSize
        ? maxHeightUsingParticipantCount
        : maxHeightUsingContainerSize) * totalParticipantCount
    );

    const columnWidths = potentialMaxWidthOfContainer;
    const heightOfEachVideo = columnWidths / VIDEO_RATIO;

    // Setting columns when we have more than 1 participant or reach certain width
    if (updatedNumberOfColumns === 1) {
      const newAdjustedContainerHeight =
        adjustedContainerHeight - HEIGHT_OF_ARROW_BUTTONS * 2;
      let remoteVideosToDisplay = Math.floor(
        (newAdjustedContainerHeight - heightOfEachVideo) / heightOfEachVideo
      );

      // Want to display at least 1 video
      if (remoteVideosToDisplay < 1) {
        remoteVideosToDisplay = 1;
        setMaxWidthOfVideoContainer(
          (newAdjustedContainerHeight / 2) * VIDEO_RATIO
        );
      }

      const newChunkedRemoteParicipants = chunk(
        remoteParticipants,
        remoteVideosToDisplay
      );
      if (!newChunkedRemoteParicipants[page]) {
        let newPage = page;
        while (!newChunkedRemoteParicipants[newPage] && newPage > 0) {
          newPage--;
        }

        setPage(newPage);
      }
      setChunkedRemoteParticipants(newChunkedRemoteParicipants);
    } else {
      setPage(0);
      setChunkedRemoteParticipants([remoteParticipants]);
    }

    setNumberOfColumns(updatedNumberOfColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerHeight, containerWidth, remoteParticipants]);

  const toggleLocalAudio = async toggle => {
    if (!toggle) {
      room.localParticipant.audioTracks.forEach(publication => {
        publication.track.stop();
        publication.unpublish();
      });
    } else {
      const localAudioTrack = await videoCallApi.createLocalAudioTrack();
      await room.localParticipant.publishTrack(localAudioTrack);
    }

    window.analytics.track("UseVideoPartyControl", {
      party_session_id: partyInfo.sessionId,
      mute: !toggle,
    });

    toggleAudio(toggle);
  };

  const toggleLocalVideo = async toggle => {
    if (!toggle) {
      room.localParticipant.videoTracks.forEach(publication => {
        publication.track.stop();
        publication.unpublish();
      });
    } else {
      const localVideoTrack = await videoCallApi.createLocalVideoTrack();
      await room.localParticipant.publishTrack(localVideoTrack);
    }

    window.analytics.track("UseVideoPartyControl", {
      party_session_id: partyInfo.sessionId,
      video: toggle,
    });

    toggleVideo(toggle);
  };

  useEffect(() => {
    if (room?.localParticipant && mutedParticipantsAt && isAudioOn) {
      toggleLocalAudio(false);
    }
    // mutedParticipantsAt will only update whenever the host clicks Mute All
    // We only want to toggle off user's local audio only when mutedParticipantsAt updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutedParticipantsAt]);

  if (loadingVideoCallApi) {
    return <LoaderCentered />;
  }
  if (errorVideoCallApi) {
    // TODO: Error handling below is temporary! Default to new error component when merged.
    return (
      <Container>
        <p>Something went wrong :(</p>
        <p>Please refresh the page.</p>
      </Container>
    );
  }

  return (
    <>
      {!!room && (
        <Flex
          justifyContent="center"
          alignItems={
            chunkedRemoteParticipants.length > 1 ||
            chunkedRemoteParticipants.length === 0
              ? "flex-start"
              : "center"
          }
          pb={HEIGHT_OF_MEDIA_CONTROLS}
          width="100%"
          height="100%"
          bg="#000000"
        >
          <Grid
            width="100%"
            maxHeight={
              chunkedRemoteParticipants.length > 1
                ? maxHeightOfVideoContainer
                : "unset"
            }
            maxWidth={maxWidthOfVideoContainer}
            gridTemplateColumns={`repeat(${numberOfColumns}, 1fr)`}
          >
            <Participant
              isLocal
              isLocalAudioOn={isAudioOn}
              isLocalVideoOn={isVideoOn}
              key={room.localParticipant.sid}
              participant={room.localParticipant}
            />
            {chunkedRemoteParticipants && chunkedRemoteParticipants[page] && (
              <>
                {chunkedRemoteParticipants.length > 1 && (
                  <ArrowButton
                    onClick={() => setPage(page - 1)}
                    disabled={page === 0}
                  >
                    <Icon
                      height="100%"
                      as={ChevronDown}
                      transform="rotate(180deg)"
                    />
                  </ArrowButton>
                )}
                {chunkedRemoteParticipants.map(
                  (participantArray, chunkedIndex) =>
                    participantArray.map(participant => (
                      <Participant
                        key={participant.sid}
                        participant={participant}
                        hiddenFromPagination={page !== chunkedIndex}
                      />
                    ))
                )}
                {chunkedRemoteParticipants.length > 1 && (
                  <ArrowButton
                    onClick={() => setPage(page + 1)}
                    disabled={page === chunkedRemoteParticipants.length - 1}
                  >
                    <Icon height="100%" as={ChevronDown} />
                  </ArrowButton>
                )}
              </>
            )}
          </Grid>
        </Flex>
      )}
      <Flex
        width="100%"
        justifyContent="center"
        position="absolute"
        bottom="0"
        padding="16px"
        height={HEIGHT_OF_MEDIA_CONTROLS}
        alignItems="center"
        flexDirection="column"
      >
        {partyInfo.isHost && (
          <Button
            height="24px"
            width="100%"
            maxWidth="300px"
            variant="hollowHoverGold"
            onClick={() => dispatch(muteParticipantsAction())}
            mb={3}
          >
            Mute All
          </Button>
        )}
        <Flex>
          <Flex
            height="40px"
            width="100px"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            onClick={() => toggleLocalAudio(!isAudioOn)}
            cursor="pointer"
          >
            <Icon
              height="20px"
              color={isAudioOn ? "white" : "red"}
              as={isAudioOn ? MicOn : MicOff}
            />
            <P2 mt={1} color="white">
              {isAudioOn ? "Mute" : "Unmute"}
            </P2>
          </Flex>
          <Flex
            height="40px"
            width="100px"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            onClick={() => toggleLocalVideo(!isVideoOn)}
            cursor="pointer"
          >
            <Icon
              height="20px"
              color={isVideoOn ? "white" : "red"}
              as={isVideoOn ? VideoOn : VideoOff}
            />
            <P2 mt={1} color="white">
              {isVideoOn ? "Stop Video" : "Start Video"}
            </P2>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

Room.propTypes = {
  containerHeight: PropTypes.number.isRequired,
  containerWidth: PropTypes.number.isRequired,
};

export default Room;
