import React from "react";
import styled from "styled-components";
import Flex from "app/components/Flex";
import Icon, { CloseCircle } from "app/components/Icon";
import Modal from "app/components/Modal";
import styles from "./styles.module.scss";

const StyledModal = styled(Modal)`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  border-radius: 0;
  overflow: auto;
  padding: 0;
`;

const Container = styled(Flex)`
  position: relative;
  width: 100%;
  flex-direction: column;
`;

interface Props {
  closeModal(): void;
  children: React.ReactNode;
  classAccessHeader?: string;
}

export const MobileSubscribeModalWrapper = ({
  closeModal,
  children,
  classAccessHeader,
}: Props) => {
  return (
    <StyledModal
      onEscapeKeydown={closeModal}
      onBackgroundClick={closeModal}
      isOpen
    >
      <Container>
        <div className={styles.closeButtonWrapper}>
          {classAccessHeader && (
            <h4 className={styles.classAccessHeader}>{classAccessHeader}</h4>
          )}
          <Icon onClick={closeModal} as={CloseCircle} />
        </div>
        <div className={styles.content}>{children}</div>
      </Container>
    </StyledModal>
  );
};
