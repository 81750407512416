import React from "react";
import styled from "styled-components";
import transition from "styled-transition-group";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const Wrapper = transition.div`
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.white};
  left: ${({ position }) => position || "50%"};
  padding: 20px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);

  &:enter {
    opacity: 0;
  }

  &:enter-active {
    transition: opacity 100ms;
    opacity: 1;
  }

  &:exit {
    opacity: 1;
  }

  &:exit-active {
    transition: opacity 600ms ease-in;
    opacity: 0;
  }
  
  ${({ customStyles }) => customStyles};
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  text-transform: uppercase;
`;

const Icon = styled.div`
  height: 80px;
  width: 80px;
  fill: ${({ theme }) => theme.colors.white};
`;

const ActionDisplay = ({ customStyles }) => {
  const { flashedActivity, showFlashActivity } = useSelector(
    ({ classPlayer }) => classPlayer
  );

  return (
    <Wrapper
      unmountOnExit
      in={showFlashActivity}
      timeout={1000}
      position={flashedActivity.position}
      customStyles={customStyles}
    >
      <Title>{flashedActivity.title}</Title>
      <Icon as={flashedActivity.icon} />
    </Wrapper>
  );
};

ActionDisplay.defaultProps = {
  customStyles: null,
};

ActionDisplay.propTypes = {
  customStyles: PropTypes.shape({}),
};

export default ActionDisplay;
