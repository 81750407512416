import React, { useMemo } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { P2 } from "app/components/Typography";
import SpanLink from "app/components/SpanLink";
import useCheckoutEstimate from "hooks/Subscriptions/useCheckoutEstimate";
import { useUserHasTrialed } from "hooks/Subscriptions/useUserHasTrialed";

const propTypes = {
  plan: PropTypes.shape({}).isRequired,
};

const CouponText = ({ totalCostPerDuration, plan }) => {
  const { checkoutEstimate } = useCheckoutEstimate();
  const firstTermPrice = useMemo(() => {
    return checkoutEstimate?.discountPlanPriceInCents / 100;
  }, [checkoutEstimate]);

  if (!checkoutEstimate?.coupon) {
    return <></>;
  }
  const discountDuration = checkoutEstimate?.coupon?.discountDuration;

  return (
    <>
      The first {discountDuration > 1 && discountDuration} {plan.periodUnit}
      {discountDuration > 1 && "s"} of your subscription will cost $
      {firstTermPrice} that will renew at {totalCostPerDuration}.{" "}
    </>
  );
};

CouponText.propTypes = {
  ...propTypes,
  totalCostPerDuration: PropTypes.string.isRequired,
};

const Disclaimer = ({ plan }) => {
  const totalPlanPrice = plan.priceInCents / 100;
  const { canTrial } = plan;
  const { hasTrialed } = useUserHasTrialed();
  const trialEndDate = moment(plan.trialEndTime).format("MM/DD/YYYY");
  const currentDate = moment().format("MM/DD/YYYY");
  const totalCostPerDuration = `$${totalPlanPrice}/${plan.periodUnit}`;

  const canUserTrial = canTrial && !hasTrialed;

  return (
    <P2 mt={3} color="monochrome.6">
      By clicking on{" "}
      {canUserTrial ? "“Try free & subscribe”" : "“Subscribe now”"}, you will be
      enrolled in recurring payments for the amount selected{" "}
      {canUserTrial
        ? `after your trial period ends on ${trialEndDate}`
        : `on ${currentDate}`}
      . <CouponText plan={plan} totalCostPerDuration={totalCostPerDuration} />
      You can cancel anytime. For more information,{" "}
      <a
        href="https://steezy.zendesk.com/hc/en-us/sections/360011158532-Canceling-Your-Subscription"
        target="blank"
      >
        <SpanLink underline color="monochrome.6">
          click here
        </SpanLink>
        .
      </a>
    </P2>
  );
};

Disclaimer.propTypes = propTypes;

export default Disclaimer;
