import { useMutation } from "@apollo/client";
import { COMPLETE_CLASS_MUTATION } from "graphql/mutations";

export default options => {
  const [mutation] = useMutation(COMPLETE_CLASS_MUTATION, options);

  return ({ variables }) => {
    return mutation({
      variables,
    });
  };
};
