import { push } from "connected-react-router";
import { getAnalyticsLocationFromPath } from "helpers/analytics";
import { Location } from "history";
import { useContentSelectClassEvent } from "hooks/analytics";
import { isNil, omitBy } from "lodash";
import { useGetUserIsAnonymous } from "modules/selectors";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

interface ExtendedLocation extends Location {
  state: { redirectToRouteOnEnd?: string };
}

export function useStartNextClass({ currentClassData, nextClassData }: any) {
  const location: ExtendedLocation = useLocation();
  const dispatch = useDispatch();
  const isAnonymous = useGetUserIsAnonymous();
  const component = "ContinuityOverlay";
  const element = "NextClassButton";
  const redirectToRouteOnEnd = location?.state?.redirectToRouteOnEnd;
  const { contentSelectClassEvent } = useContentSelectClassEvent({
    classData: nextClassData,
  });

  const startNextClass = () => {
    if (!nextClassData) {
      return;
    }

    if (!currentClassData) {
      return;
    }

    const queryParams = queryString.parse(location.search);
    queryParams.classRefId = nextClassData.refId;
    if (!isAnonymous) {
      const stringifiedParams = resolveQueryParams({
        classData: nextClassData,
        queryParams,
      });
      const routeToPush = `/class/${nextClassData.id}${
        stringifiedParams ? `?${stringifiedParams}` : ""
      }`;

      contentSelectClassEvent({
        element,
        location: getAnalyticsLocationFromPath(location.pathname),
        path: location.pathname,
        playlist_id: nextClassData.playlistId,
        selected_from: component,
        otherEventAttributes: {
          previous_class_id: currentClassData.id,
        },
      });

      dispatch(
        push(routeToPush, {
          refComponent: component,
          refElement: element,
          redirectToRouteOnEnd,
          fromApp: true,
        })
      );
    } else {
      dispatch(push("/register"));
    }
  };

  return { startNextClass };
}

function resolveQueryParams({ classData, queryParams }: any) {
  const queryParamResult = {
    ...queryParams,
  };

  if (classData.refId) {
    queryParamResult.classRefId = classData.refId;
  } else if (classData.programs) {
    // TODO: VERIFY CLASS + PROGRAMS 1-to-many or 1-to-1 relationship?
    // eslint-disable-next-line prefer-destructuring
    queryParamResult.program = classData.programs[0];
  }

  if (classData.playlistId) {
    queryParamResult.playlist = classData.playlistId;
  }

  return queryString.stringify(omitBy(queryParamResult, isNil));
}
