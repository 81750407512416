/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable consistent-return */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { isSafari } from "react-device-detect";
import { useAuthHeaders } from "helpers/session/useAuthHeaders";
import { isClassPreview } from "helpers/isClassPreview";
import { getReactPlayer } from "./getReactPlayer";

const useDashJS = () => {
  const [dashJS, setDashJS] = useState(null);

  useEffect(() => {
    import("dashjs" /* webpackChunkName: "dashjs" */).then(setDashJS);
  }, []);

  return {
    dashJS,
    loadingDashJS: !dashJS,
  };
};

const CustomDrmDashReactPlayer = ({
  onMount,
  onReady,
  onPlay,
  onBuffer,
  onBufferEnd,
  onPause,
  onEnded,
  onError,
  onPlayBackRateChange,
  onPresentationModeChange,
  onEnablePIP,
  onDisablePIP,
  onSeek,
  onLoaded,
  playing,
  config,
  controls,
  muted,
  loop,
  width,
  height,
  playsinline,
  url,
}) => {
  const [isPlayerInitialized, setIsPlayerInitialized] = useState(false);
  const videoElRef = useRef(null);
  const dashPlayerRef = useRef(null);

  const style = {
    // copied from FilePlayer in react-player package
    width: width === "auto" ? width : "100%",
    height: height === "auto" ? height : "100%",
  };

  const { dashJS, loadingDashJS } = useDashJS();
  const { authHeaders, loadingAuthHeaders } = useAuthHeaders();

  useEffect(() => {
    if (loadingDashJS || loadingAuthHeaders) {
      return;
    }

    const videoEl = videoElRef.current;
    if (videoEl) {
      videoEl.addEventListener("play", onPlay);
      videoEl.addEventListener("waiting", onBuffer);
      videoEl.addEventListener("playing", onBufferEnd);
      videoEl.addEventListener("pause", onPause);
      videoEl.addEventListener("seeked", onSeek);
      videoEl.addEventListener("ended", onEnded);
      videoEl.addEventListener("error", onError);
      videoEl.addEventListener("ratechange", onPlayBackRateChange);
      videoEl.addEventListener("enterpictureinpicture", onEnablePIP);
      videoEl.addEventListener("leavepictureinpicture", onDisablePIP);
      videoEl.addEventListener(
        "webkitpresentationmodechanged",
        onPresentationModeChange
      );
      videoEl.addEventListener("canplay", onReady);

      if (playsinline) {
        videoEl.setAttribute("playsinline", "");
        videoEl.setAttribute("webkit-playsinline", "");
        videoEl.setAttribute("x5-playsinline", "");
      }
    }
    dashPlayerRef.current = dashJS.MediaPlayer().create();
    const reactPlayer = getReactPlayer({
      dashPlayer: dashPlayerRef.current,
      videoEl,
      onLoaded,
      onError,
    });
    onMount(reactPlayer);
    setIsPlayerInitialized(true);

    return () => {
      if (videoEl) {
        videoEl.removeEventListener("play", onPlay);
        videoEl.removeEventListener("waiting", onBuffer);
        videoEl.removeEventListener("playing", onBufferEnd);
        videoEl.removeEventListener("pause", onPause);
        videoEl.removeEventListener("seeked", onSeek);
        videoEl.removeEventListener("ended", onEnded);
        videoEl.removeEventListener("error", onError);
        videoEl.removeEventListener("ratechange", onPlayBackRateChange);
        videoEl.removeEventListener("enterpictureinpicture", onEnablePIP);
        videoEl.removeEventListener("leavepictureinpicture", onDisablePIP);
        videoEl.removeEventListener(
          "webkitpresentationmodechanged",
          onPresentationModeChange
        );
        videoEl.removeEventListener("canplay", onReady);
      }
      if (dashPlayerRef.current) {
        dashPlayerRef.current.destroy();
        dashPlayerRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingDashJS, loadingAuthHeaders]);

  useEffect(() => {
    if (!isPlayerInitialized) {
      return;
    }

    dashPlayerRef.current.initialize(videoElRef.current, url, true);
    const protectionData = {
      "com.widevine.alpha": {
        serverURL: config.licenseUrl,
        httpRequestHeaders: authHeaders,
        priority: 0,
      },
    };
    dashPlayerRef.current.setProtectionData(protectionData);
    onLoaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, isPlayerInitialized]);

  useEffect(() => {
    if (!isPlayerInitialized) {
      return;
    }

    const filterLicenseRequest = request => {
      // eslint-disable-next-line no-param-reassign
      request.headers = authHeaders; // must edit in place
      return request;
    };

    dashPlayerRef.current.registerLicenseRequestFilter(filterLicenseRequest);
    return () => {
      if (dashPlayerRef.current) {
        dashPlayerRef.current.unregisterLicenseRequestFilter(
          filterLicenseRequest
        );
      }
    };
  }, [authHeaders, isPlayerInitialized]);

  return (
    <video
      ref={videoElRef}
      style={style}
      preload="auto"
      autoPlay={playing || undefined}
      controls={controls}
      muted={muted}
      loop={loop}
    />
  );
};

CustomDrmDashReactPlayer.propTypes = {
  onMount: PropTypes.func,
  onReady: PropTypes.func,
  onPlay: PropTypes.func,
  onBuffer: PropTypes.func,
  onBufferEnd: PropTypes.func,
  onPause: PropTypes.func,
  onEnded: PropTypes.func,
  onError: PropTypes.func,
  onPlayBackRateChange: PropTypes.func,
  onPresentationModeChange: PropTypes.func,
  onEnablePIP: PropTypes.func,
  onDisablePIP: PropTypes.func,
  onSeek: PropTypes.func,
  onLoaded: PropTypes.func,
  playing: PropTypes.bool,
  config: PropTypes.shape({
    licenseUrl: PropTypes.string,
  }),
  controls: PropTypes.bool,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  playsinline: PropTypes.bool,
};
CustomDrmDashReactPlayer.key = "customdrmdash";
CustomDrmDashReactPlayer.displayName = "CustomDrmDashReactPlayer";
CustomDrmDashReactPlayer.canPlay = url => !isSafari && !isClassPreview(url);

export default CustomDrmDashReactPlayer;
