import { useState, useEffect } from "react";
import { useGetPlaylistStudioClasses } from "app/routes/Playlists/hooks";
import { useQuery } from "@apollo/client";
import { DATE_FORMAT_FOR_SCHEDULES } from "constants/index";
import { GET_PLAYLIST_QUERY } from "graphql/queries";
import useGetClass from "hooks/useGetClass";
import moment from "moment";
import queryString from "query-string";
import { useLocation, useParams } from "react-router";

export function useGetNextClass() {
  const { id: classId } = useParams<{ id: string }>();
  const location = useLocation();
  const {
    playlist: schedulePlaylistId,
    playlistId,
    classRefId,
  } = queryString.parse(location.search);

  const [getClassData] = useGetClass({
    variables: {
      classId,
      programClassRefId: classRefId,
    },
    notFoundRedirectPath: "/dashboard",
  });

  const { nextPlaylistClassId } = useGetPlaylistNextClassId({
    playlistId,
    classId: Number(classId),
  });
  const nextProgramClass = getClassData?.nextProgramClass || {};

  const startOfSelectedDate = moment()
    .startOf("day")
    .format(DATE_FORMAT_FOR_SCHEDULES);
  const { data: schedulePlaylistData } = useQuery(GET_PLAYLIST_QUERY, {
    variables: {
      date: startOfSelectedDate,
    },
    errorPolicy: "ignore",
    skip: !schedulePlaylistId,
  });

  let nextClassId = nextPlaylistClassId || nextProgramClass.id;
  let nextClassRefId = nextProgramClass.refId;
  if (schedulePlaylistId && schedulePlaylistData) {
    const scheduleClasses = schedulePlaylistData?.playlist?.classes;
    const index = scheduleClasses.findIndex(
      (playlistClass: any) => parseInt(playlistClass.id) === parseInt(classId)
    );
    nextClassId = scheduleClasses[index + 1]?.id;
    nextClassRefId = scheduleClasses[index + 1]?.refId;
  }

  const [getNextClass] = useGetClass({
    variables: {
      classId: nextClassId?.toString(),
      programClassRefId: nextClassRefId,
    },
    options: {
      skip: !nextClassId,
    },
  });
  const nextClassData = getNextClass?.current;

  return { nextClassData };
}

export function useGetPlaylistNextClassId({
  classId,
  playlistId,
}: {
  classId: number;
  playlistId: any; // should be string but we're pulling this from the url params which is (string | string[])
}) {
  const [nextPlaylistClassId, setNextPlaylistClassId] = useState<
    number | null
  >();
  const {
    studioClasses,
    fetchMorePlaylistStudioClasses,
    loadingPlaylistStudioClasses,
  } = useGetPlaylistStudioClasses({
    playlistId,
  });

  useEffect(() => {
    if (loadingPlaylistStudioClasses) {
      return;
    }

    if (!fetchMorePlaylistStudioClasses) {
      return;
    }

    const currentClassIndex = studioClasses?.findIndex(
      studioClass => studioClass.classId === Number(classId)
    );

    if (currentClassIndex >= 0) {
      const nextClass = studioClasses[currentClassIndex + 1];
      setNextPlaylistClassId(nextClass?.classId);
    } else {
      fetchMorePlaylistStudioClasses();
    }
  }, [studioClasses]);

  return {
    nextPlaylistClassId,
  };
}
