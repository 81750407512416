import React from "react";
import styled from "styled-components";
import Icon, {
  ClassesInactive,
  ProgramsInactive,
  Schedule,
} from "app/components/Icon";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import Span from "app/components/Span";
import { P1, H3 } from "app/components/Typography";
import { ThinLine } from "app/components/Lines";

const Wrapper = styled(Div)`
  ${({ theme }) => theme.mediaQueries.ltmd} {
    padding: 0 16px;
  }
`;

const Row = styled(Flex)`
  align-items: start;
  margin: 16px 0;
`;

const RowIcon = styled<any>(Icon)`
  color: ${({ theme, iconColor }: any) => theme.colors[iconColor]};
  margin-right: 16px;
  margin-top: 3px;
`;

export const BenefitsText = ({ iconColor, ...props }: any) => {
  return (
    <Wrapper width="100%" textAlign="left" mb={2} {...props}>
      <H3 mt={3}>What you get</H3>
      <Row>
        <RowIcon as={ClassesInactive} iconColor={iconColor} width="25px" />
        <P1>
          <Span fontWeight="600">1,000+ classes</Span> for beginners through
          advanced
        </P1>
      </Row>
      <ThinLine />
      <Row>
        <RowIcon as={ProgramsInactive} iconColor={iconColor} width="25px" />
        <P1>
          <Span fontWeight="600">Guided programs</Span> for Ballet, Jazz, Hip
          Hop, Heels, and more
        </P1>
      </Row>
      <ThinLine />
      <Row>
        <RowIcon as={Schedule} iconColor={iconColor} width="22px" />
        <P1>
          <Span fontWeight="600">Daily curated schedules</Span> and new classes
          added weekly
        </P1>
      </Row>
    </Wrapper>
  );
};
