import Flex from "app/components/Flex";
import { TrackInformation } from "app/components/TrackInformation";
import React, { useEffect, useRef, useState } from "react";

type TrackInfo = {
  albumName: string;
  image: string;
  artists: string[];
  title: string;
  label: string;
  isExplicit: boolean;
};

type Props = {
  showOverlayBars: boolean;
  onHide: () => void;
  trackInfo: TrackInfo;
};

export const TrackInfoTopBar = ({
  showOverlayBars,
  onHide,
  trackInfo: { albumName, artists, image, isExplicit, label, title },
}: Props) => {
  const overlayTrackInfoRef = useRef<any>(null);
  const resetTrackInfoRef = useRef<any>(null);
  const [showingTrackInfo, setShowingTrackInfo] = useState(false);
  const timersInMs = {
    toShow: 1000,
    toHide: 5000,
    toReset: 500,
  };

  useEffect(() => {
    overlayTrackInfoRef.current = setTimeout(() => {
      setShowingTrackInfo(true);
    }, timersInMs.toShow);

    return () => {
      clearTimeout(overlayTrackInfoRef.current);
      clearTimeout(resetTrackInfoRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showingTrackInfo) {
      clearTimeout(overlayTrackInfoRef.current);
      overlayTrackInfoRef.current = setTimeout(() => {
        setShowingTrackInfo(false);
        resetTrackInfoRef.current = setTimeout(() => {
          onHide();
        }, timersInMs.toReset);
      }, timersInMs.toHide);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showingTrackInfo]);

  const topWhenShowingOverlayBars = showOverlayBars ? "88px" : "32px";
  const topPosition = showingTrackInfo ? topWhenShowingOverlayBars : "-150px";

  const artistsNames = (artists || []).join(", ");

  return (
    <Flex
      position="absolute"
      top={topPosition}
      right="32px"
      transition="top 0.25s ease-in-out"
      zIndex="2"
    >
      <Flex
        p="12px"
        bg="white"
        maxWidth="440px"
        minWidth="122px"
        minHeight="65px"
        borderRadius="4px"
        opacity="0.85"
        mr="10px"
      >
        <TrackInformation
          albumName={albumName}
          artistName={artistsNames}
          cover={image}
          isExplicit={isExplicit}
          recordLabelName={label}
          songName={title}
        />
      </Flex>
    </Flex>
  );
};
