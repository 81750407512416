/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from "react";
import Img from "app/components/Img";
import PropTypes from "prop-types";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { P1, H7 } from "app/components/Typography";
import styled from "styled-components";
import Icon, { MicOff } from "app/components/Icon";
import { PLACEHOLDER_FIRST_NAME } from "constants/index";
import env from "helpers/env";

import { useFirebaseConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";

const Video = styled.video`
  width: 100%;
  height: auto;
  pointer-events: none;
  transform: rotateY(180deg);
  ${({ hide }) =>
    hide &&
    `
    display: none;
  `};
`;

const Participant = ({
  participant,
  hiddenFromPagination,
  isLocal,
  isLocalAudioOn,
  isLocalVideoOn,
}) => {
  useFirebaseConnect([
    { path: `users_public/${participant.identity}/first_name` },
  ]);
  const usersFirstName = useSelector(
    ({ firebase }) =>
      firebase.data.users_public?.[participant.identity]?.first_name ||
      PLACEHOLDER_FIRST_NAME
  );
  const [isVideoShowing, toggleVideo] = useState(true);
  const [isAudioOn, toggleAudio] = useState(true);
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = trackMap =>
    Array.from(trackMap.values())
      .map(publication => publication.track)
      .filter(track => track !== null);

  useEffect(() => {
    const trackSubscribed = track => {
      console.log("trackSubscribed", { track });
      if (track.kind === "video") {
        toggleVideo(true);
        setVideoTracks([track]);
      } else if (track.kind === "audio") {
        toggleAudio(true);
        setAudioTracks([track]);
      }
    };

    const trackUnsubscribed = track => {
      console.log("trackUnsubscribed", { track });
      if (track.kind === "video") {
        toggleVideo(false);
        setVideoTracks(prevVideoTracks =>
          prevVideoTracks.filter(v => v !== track)
        );
      } else if (track.kind === "audio") {
        toggleAudio(false);
        setAudioTracks(prevAudioTracks =>
          prevAudioTracks.filter(a => a !== track)
        );
      }
    };

    const trackPublished = publication => {
      // We're only publishing local tracks to avoid multiple tracks
      if (publication.kind === "video" && isLocal) {
        const { track } = publication;
        setVideoTracks([track]);
      }

      if (publication.kind === "audio" && isLocal) {
        const { track } = publication;
        setAudioTracks([track]);
      }
    };

    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackPublished", trackPublished);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participant]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      toggleVideo(true);

      return () => {
        videoTrack.detach();
        toggleVideo(false);
      };
    }
  }, [isLocal, participant.videoTracks, videoTracks]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      toggleAudio(true);

      return () => {
        toggleAudio(false);
        audioTrack.detach();
      };
    }

    toggleAudio(false);
  }, [audioTracks]);

  return (
    <Div
      pt="56.25%"
      position="relative"
      bg="monochrome.7"
      width="100%"
      height="0"
      display={hiddenFromPagination ? "none" : "block"}
    >
      <Flex
        alignItems="center"
        overflow="hidden"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
      >
        {isLocal && (
          <P1
            position="absolute"
            top={0}
            color="bulbaGreen"
            p={[2, 3]}
            zIndex={1}
          >
            You
          </P1>
        )}
        <Flex
          position="absolute"
          bottom={0}
          left={0}
          p={[2, 3]}
          zIndex={1}
          alignItems="center"
        >
          {(!isAudioOn || (isLocal && !isLocalAudioOn)) && (
            <Icon color="red" as={MicOff} width="15px" mr={2}>
              You
            </Icon>
          )}
          <H7 color="white">{usersFirstName}</H7>
        </Flex>
        {(isLocal && isLocalVideoOn) || (!isLocal && isVideoShowing) ? (
          <Video ref={videoRef} autoPlay />
        ) : (
          <Img
            width="100%"
            src={`${env("PUBLIC_ASSET_PATH")}/default-avatar.png?w=600`}
          />
        )}
        {isAudioOn && <audio ref={audioRef} autoPlay />}
      </Flex>
    </Div>
  );
};

Participant.defaultProps = {
  hiddenFromPagination: false,
  isLocal: false,
  isLocalAudioOn: false,
  isLocalVideoOn: false,
};

Participant.propTypes = {
  participant: PropTypes.shape({}).isRequired,
  hiddenFromPagination: PropTypes.bool,
  isLocal: PropTypes.bool,
  isLocalAudioOn: PropTypes.bool,
  isLocalVideoOn: PropTypes.bool,
};

export default Participant;
