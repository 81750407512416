import styled from "styled-components";
import {
  background,
  border,
  color,
  layout,
  position,
  space,
  shadow,
  system,
  FontSizeProps,
} from "styled-system";

export interface SpanProps extends FontSizeProps {
  fontWeight?: string;
  backdropFilter?: string;
  textDecoration?: string;
}

export default styled.span<SpanProps>`
  ${background}
  ${border}
  ${color}
  ${layout}
  ${position}
  ${space}
  ${shadow}
  ${system({
    backgroundClip: true,
    columns: true,
    cursor: true,
    textAlign: true,
    transition: true,
    transform: true,
  })}
  font-weight: ${({ fontWeight }) => fontWeight};
  backdrop-filter: ${({ backdropFilter }) => backdropFilter};
  text-decoration: ${({ textDecoration }) => textDecoration};
`;
