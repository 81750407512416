/* eslint-disable consistent-return */
import { useEffect, useRef } from "react";

export default (callback, delay) => {
  const savedCallback = useRef();
  const id = useRef();
  const stop = () => {
    clearInterval(id.current);
  };

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      id.current = setInterval(tick, delay);
      return () => stop();
    }
  }, [delay]);

  // Allow component to clear the interval
  return { stop };
};
