import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Icon, { Party, Lock } from "app/components/Icon";
import { P2, H3, H5, H7 } from "app/components/Typography";
import Tippy from "@tippyjs/react/headless";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import styled from "styled-components";
import Button from "app/components/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "app/components/Tooltip";
import {
  endPartyAction,
  leavePartyAction,
  startPartyAction,
} from "modules/party";
import env from "helpers/env";
import { Button as ClassPlayerButton } from "./index";

const Arrow = styled(Div)`
  width: 16px;
  height: 16px;

  ::before {
    position: absolute;
    width: 16px;
    height: 16px;
    z-index: -1;
    content: "";
    transform: rotate(45deg);
    background: ${({ theme }) => theme.colors.monochrome[7]};
    border-radius: 0 0 6px 0;
  }

  &[data-placement^="bottom"] {
    top: -8px;
  }
`;

const PartyButton = ({
  classId,
  isPartyPopoverVisible,
  togglePartyPopover,
  isFreeClass,
  openSubscribeModal,
  hasActiveSubscription,
}) => {
  const dispatch = useDispatch();
  const [copyLinkMessage, setCopyLinkMessage] = useState("Copy Link");
  const [copyCodeMessage, setCopyCodeMessage] = useState("Copy Code");
  const { started: partyStarted, sizeOfParty } = useSelector(
    ({ party }) => party
  );
  const { isHost: isPartyHost, id: pid } = useSelector(
    ({ user }) => user.private?.party || {}
  );
  const startParty = () =>
    hasActiveSubscription
      ? dispatch(startPartyAction({ classId, isFreeClass }))
      : openSubscribeModal();

  return (
    <Tippy
      onClickOutside={() => togglePartyPopover(false)}
      visible={isPartyPopoverVisible}
      placement="bottom"
      offset={[0, 20]}
      interactive
      interactiveBorder={20}
      render={attrs => (
        <Div
          maxWidth="300px"
          color="black"
          borderRadius="10px"
          overflow="hidden"
          boxShadow="4px 4px 4px rgba(0, 0, 0, 0.15)"
          cursor="pointer"
          {...attrs}
        >
          <Arrow data-popper-arrow data-placement="bottom" />
          <Div py={2} px={3} bg="monochrome.7">
            <H3 color="white">
              {partyStarted ? "Send an invite" : "Start a party?"}
            </H3>
          </Div>
          <Div pt={2} pb={3} px={3} bg="monochrome.8">
            {partyStarted ? (
              <>
                <P2 color="white">
                  Invite up to nine friends by sharing your code or link.
                </P2>
                <Div mt={3}>
                  <H7 color="white">Share Your URL</H7>
                  <CopyToClipboard
                    text={`${env("PUBLIC_DOMAIN")}/party?pid=${pid}`}
                    onCopy={() => setCopyLinkMessage("Link Copied!")}
                  >
                    <Tooltip overlay={copyLinkMessage}>
                      <Div
                        p={2}
                        bg="monochrome.7"
                        borderRadius="2px"
                        onMouseLeave={() => setCopyLinkMessage("Copy Link")}
                      >
                        <P2 color="white">
                          {env("PUBLIC_DOMAIN")}/party?pid={pid}
                        </P2>
                      </Div>
                    </Tooltip>
                  </CopyToClipboard>
                </Div>
                {!!pid && (
                  <>
                    <Div mt={3}>
                      <H7 color="white">Share Your Code</H7>
                    </Div>
                    <Div display="inline-block">
                      <CopyToClipboard
                        text={pid}
                        onCopy={() => setCopyCodeMessage("Code Copied!")}
                      >
                        <Tooltip overlay={copyCodeMessage}>
                          <Flex
                            mt={1}
                            onMouseLeave={() => setCopyCodeMessage("Copy Link")}
                          >
                            {pid.split("").map(p => (
                              <Flex
                                justifyContent="center"
                                alignItems="center"
                                px={2}
                                py={1}
                                border="1px solid"
                                borderColor="white"
                                borderRadius="4px"
                                mr={2}
                              >
                                <H5 color="white">{p}</H5>
                              </Flex>
                            ))}
                          </Flex>
                        </Tooltip>
                      </CopyToClipboard>
                    </Div>
                  </>
                )}
                <Div mt={3}>
                  <P2 color="white">
                    <strong>Enter code at:</strong>
                    <br />
                    {env("PUBLIC_DOMAIN")}/party
                  </P2>
                </Div>
              </>
            ) : (
              <P2 color="white">
                Start a party and share your code or link to invite your friends
              </P2>
            )}
            {isPartyHost ? (
              <Button
                mt={3}
                width="100%"
                onClick={() => {
                  if (partyStarted) {
                    dispatch(endPartyAction());
                    togglePartyPopover(false);
                  } else {
                    startParty();
                  }
                }}
              >
                {partyStarted ? "End Party" : "Start"}
              </Button>
            ) : (
              <Button
                mt={3}
                width="100%"
                onClick={() => {
                  if (partyStarted) {
                    dispatch(leavePartyAction());
                    togglePartyPopover(false);
                  } else {
                    startParty();
                  }
                }}
              >
                {partyStarted ? "Leave Party" : "Start"}
              </Button>
            )}
          </Div>
        </Div>
      )}
    >
      <div>
        <ClassPlayerButton
          borderColor={partyStarted ? "gold" : null}
          onClick={() => togglePartyPopover(!isPartyPopoverVisible)}
        >
          {hasActiveSubscription ? (
            <Icon
              width="24px"
              as={Party}
              color={partyStarted ? "gold" : null}
            />
          ) : (
            <Icon height="18px" width="14px" as={Lock} />
          )}
          {/* TODO: Add amount of people in party */}
          <H5 ml={3} color={partyStarted ? "gold" : null}>
            {partyStarted ? `In a Party (${sizeOfParty})` : "Start A Party"}
          </H5>
        </ClassPlayerButton>
      </div>
    </Tippy>
  );
};

PartyButton.defaultProps = {
  isPartyPopoverVisible: false,
  pid: null,
  isFreeClass: false,
};

PartyButton.propTypes = {
  classId: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
  isPartyPopoverVisible: PropTypes.bool,
  pid: PropTypes.string,
  togglePartyPopover: PropTypes.func.isRequired,
  isFreeClass: PropTypes.bool,
  openSubscribeModal: PropTypes.func.isRequired,
  hasActiveSubscription: PropTypes.bool.isRequired,
};

export default PartyButton;
