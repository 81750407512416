import { PlaySession } from "./hooks";

const PLAY_SESSION_STORAGE_KEY = "playSession";

export function checkCacheForNotExpiredPlaySession(): PlaySession | null {
  const cachedPlaySession = localStorage.getItem(PLAY_SESSION_STORAGE_KEY);
  if (!cachedPlaySession) {
    return null;
  }

  return JSON.parse(cachedPlaySession);
}

export function cachePlaySession(playSession: PlaySession) {
  const data = JSON.stringify(playSession);
  localStorage.setItem(PLAY_SESSION_STORAGE_KEY, data);
}

export function clearCachedPlaySession() {
  localStorage.removeItem(PLAY_SESSION_STORAGE_KEY);
}
