import firebase from "config/firebase-config";
import { useEffect, useState } from "react";


export function useAuthHeaders() {
  const [authHeaders, setAuthHeaders] = useState(null);

  useEffect(() => {
    // `onIdTokenChanged` when called will set up a listener, but returns a cleanup (unsubscribe function).
    const unsubscribe = firebase.auth().onIdTokenChanged(user => {
      if (!user) {
        return;
      }

      user.getIdToken()
        .then(idToken => {
          setAuthHeaders({"x-auth-token": `Bearer ${idToken}`})
        });
    });

    return unsubscribe;
  }, []);

  return {
    authHeaders,
    loadingAuthHeaders: !authHeaders,
  };
}