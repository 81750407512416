import convertTimeObjectToSeconds from "helpers/convertTimeObjectToSeconds";

export const getTimeInSeconds = (classProgress, classData) => {
  if (classProgress?.time) {
    const progressTimeInSeconds = convertTimeObjectToSeconds(
      classProgress.time
    );

    if (
      !classProgress.completed ||
      (classProgress.completed &&
        classData.duration_in_seconds - progressTimeInSeconds > 15)
    ) {
      return progressTimeInSeconds;
    }
  }

  return 0;
};

export const getTracksWithEndTime = (tracks, classDuration) => {
  if (tracks) {
    const tracksWithEndTime = tracks.map((track, index) => {
      const endsAt =
        index < tracks.length - 1 ? tracks[index + 1].startsAt : classDuration;
      return { ...track, endsAt };
    });
    return tracksWithEndTime;
  }
  return null;
};
