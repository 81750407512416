import React, { useEffect, useState } from "react";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { useCheckout } from "modules/selectors";
import Button from "app/components/Button";
import { AccessType } from "services/graphql";
import { usePrimarySubscription } from "hooks/Subscriptions/usePrimarySubscription";
import { PlanSelect } from "../PlanSelect";
import { useUpgradePlans } from "../hooks";
import { ConfirmUpgradePlanFlow } from "../ConfirmUpgradePlanFlow";
import styles from "./styles.module.scss";

interface Props {
  accessTypeRequired: AccessType;
}

export const UpgradePlanFLow = ({ accessTypeRequired }: Props) => {
  const { selectedPlan } = useCheckout();
  const [isConfirmingPlan, setIsConfirmingPlan] = useState(false);
  const {
    upgradePlans,
    selectedBillingPeriod,
    setSelectedBillingPeriod,
  } = useUpgradePlans({ accessTypeRequired });
  const {
    primarySubscription,
    refetchPrimarySubscription,
    loadingPrimarySubscription,
  } = usePrimarySubscription();
  const { plan: currentPlan } = primarySubscription || {};

  useEffect(() => {
    if (loadingPrimarySubscription) {
      return;
    }

    refetchPrimarySubscription();
  }, []);

  if (!upgradePlans) {
    return <LoaderCentered />;
  }

  if (!primarySubscription || loadingPrimarySubscription) {
    return <LoaderCentered />;
  }

  return (
    <div className={styles.container}>
      {isConfirmingPlan ? (
        <ConfirmUpgradePlanFlow
          plan={selectedPlan}
          cancelUpgradeFlow={() => setIsConfirmingPlan(false)}
        />
      ) : (
        <>
          <PlanSelect
            currentPlan={currentPlan}
            headerText="Manage Your Plan"
            plans={upgradePlans}
            selectedBillingPeriod={selectedBillingPeriod}
            setSelectedBillingPeriod={setSelectedBillingPeriod}
          />
          {selectedPlan && (
            <Button onClick={() => setIsConfirmingPlan(true)}>
              Continue to Upgrade
            </Button>
          )}
        </>
      )}
    </div>
  );
};
