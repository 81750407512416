export default timeString => {
  const timeArray = timeString.split(":");
  let timeInSeconds = 0;

  if (timeArray.length === 2) {
    timeInSeconds =
      timeInSeconds + Number(timeArray[0]) * 60 + Number(timeArray[1]);
  } else if (timeArray.length === 3) {
    timeInSeconds =
      timeInSeconds +
      Number(timeArray[0]) * 3600 +
      Number(timeArray[1]) * 60 +
      Number(timeArray[2]);
  }

  return timeInSeconds;
};
