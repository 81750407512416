import env from "helpers/env";
import { useEffect, useState } from "react";
import { isSafari } from "react-device-detect";

const DASH_PLAYER_KEY = "customreactdash";

// @TODO colocate
export const useVideoStream = ({ video, reactPlayerRef }) => {
  const [videoStream, setVideoStream] = useState({
    isSingleView: isSafari
      ? !video?.drmHlsPlaybackUrls?.backUrl
      : !video?.drmDashPlaybackUrls?.backUrl,
    url: null,
    drmSettings: {},
    nonDrmFrontUrl: null,
    nonDrmBackUrl: null,
  });
  const [qualityLevels, setQualityLevels] = useState([]);

  useEffect(() => {
    if (!video) {
      return;
    }

    if (isSafari) {
      setVideoStream({
        ...videoStream,
        url: video.drmHlsPlaybackUrls?.defaultUrl,
        nonDrmFrontUrl:
          video?.hlsPlaybackUrls?.frontUrl ||
          video?.hlsPlaybackUrls?.defaultUrl,
        nonDrmBackUrl: video?.hlsPlaybackUrls?.backUrl,
        drmSettings: {
          licenseUrl: `${env(
            "PUBLIC_WEBHOOK_DOMAIN"
          )}/videos/license?drmType=fp&contentId=${video.id}`,
          certificateUrl: video.drmHlsPlaybackUrls?.fairplayCertificateUrl,
        },
      });
    } else {
      setVideoStream({
        ...videoStream,
        url: video?.drmDashPlaybackUrls?.defaultUrl,
        nonDrmFrontUrl:
          video?.hlsPlaybackUrls?.frontUrl ||
          video?.hlsPlaybackUrls?.defaultUrl,
        nonDrmBackUrl: video?.hlsPlaybackUrls?.backUrl,
        drmSettings: {
          licenseUrl: `${env(
            "PUBLIC_WEBHOOK_DOMAIN"
          )}/videos/license?drmType=wv&contentId=${video.id}`,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);

  const setQualityLevel = qualityObj => {
    const dashPlayer = reactPlayerRef.current.getInternalPlayer(
      DASH_PLAYER_KEY
    );
    if (dashPlayer) {
      const isAuto = qualityObj.qualityIndex == null;
      dashPlayer.updateSettings({
        streaming: {
          abr: { autoSwitchBitrate: { video: isAuto, audio: true } },
        },
      });
      if (!isAuto) {
        dashPlayer.setQualityFor("video", qualityObj.qualityIndex, true);
      }
    } else {
      setVideoStream({
        ...videoStream,
        url: qualityObj.url,
      });
    }
  };

  const updateQualityLevels = () => {
    const dashPlayer = reactPlayerRef.current.getInternalPlayer(
      DASH_PLAYER_KEY
    );
    if (dashPlayer) {
      const bitrateInfos = dashPlayer.getBitrateInfoListFor("video");
      const newQualityLevels = bitrateInfos
        .map((bitrateInfo, index) => ({
          qualityIndex: index,
          height: bitrateInfo.height,
          label: `${
            videoStream.isSingleView
              ? bitrateInfo.height
              : bitrateInfo.height / 2
          }p`,
        }))
        .sort((a, b) => b.height - a.height)
        .reduce((acc, curr) => {
          const hasLabel = acc.filter(q => q.label === curr.label).length > 0;
          return hasLabel ? acc : [...acc, curr];
        }, []);
      newQualityLevels.push({ label: "auto" });
      setQualityLevels(newQualityLevels);
    } else {
      setQualityLevels([
        {
          label: "1080p",
          url: video?.drmHlsPlaybackUrls?.default1080Url,
        },
        {
          label: "720p",
          url: video?.drmHlsPlaybackUrls?.default720Url,
        },
        {
          label: "540p",
          url: video?.drmHlsPlaybackUrls?.default540Url,
        },
        {
          label: "360p",
          url: video?.drmHlsPlaybackUrls?.default360Url,
        },
        {
          label: "auto",
          url: video?.drmHlsPlaybackUrls?.defaultUrl,
        },
      ]);
    }
  };

  return {
    videoStreamQualityLevels: qualityLevels,
    videoStreamSetQualityLevel: setQualityLevel,
    videoStreamUpdateQualityLevels: updateQualityLevels,
    videoStream,
  };
};
