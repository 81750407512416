import React, { useState } from "react";
import PropTypes from "prop-types";
import { H2, H3, H5, P } from "app/components/Typography";
import Icon, { PlayNext } from "app/components/Icon";
import CircularProgressBar from "app/components/CircularProgressBar";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import useInterval from "hooks/useInterval";
import {
  ScreenGtLg,
  ScreenMd,
  ScreenSm,
  ScreenLtSm,
} from "app/components/MediaQueries";

const NextClassPrompt = ({
  classData,
  goToNextClass,
  isClassOverlayTimerStopped,
}) => {
  const [count, setCount] = useState(0);
  const { stop: stopInterval } = useInterval(() => setCount(count + 1), 100);
  if (isClassOverlayTimerStopped) {
    stopInterval();
  }

  if (count > 110) {
    goToNextClass();
  }

  return (
    <Flex alignItems="center" flexDirection="column">
      <Flex flexDirection="column" alignItems="center" textAlign="center">
        <ScreenGtLg>
          <H3 color="white" mt={4}>
            Next Class
          </H3>
          <H2 color="white" mt={2}>
            {classData.title}
          </H2>
        </ScreenGtLg>
        <ScreenMd>
          <H3 color="white" mt={4}>
            Next Class
          </H3>
          <H2 color="white" mt={2}>
            {classData.title}
          </H2>
        </ScreenMd>
        <ScreenSm>
          <H5 color="white" mt={3} mb={0}>
            Next Class
          </H5>
          <P color="white" mt={2}>
            {classData.title}
          </P>
        </ScreenSm>
        <ScreenLtSm>
          <H5 color="white" mt={1} mb={0}>
            Next Class
          </H5>
          <P color="white" mt={1}>
            {classData.title}
          </P>
        </ScreenLtSm>
      </Flex>

      <Flex
        my={{ _: 2, md: 3, lg: 4 }}
        justifyContent="center"
        alignItems="center"
        cursor="pointer"
        onClick={goToNextClass}
      >
        <Div
          width={{ _: "48px", lg: "96px" }}
          height={{ _: "48px", lg: "96px" }}
        >
          {!isClassOverlayTimerStopped && (
            <CircularProgressBar percentage={count} strokeWidth={6} />
          )}
        </Div>
        <Flex
          position="absolute"
          justifyContent="center"
          alignItems="center"
          width={{ _: "36px", lg: "75px" }}
          height={{ _: "36px", lg: "75px" }}
          bg="rgba(255, 255, 255, 0.4)"
          borderRadius="50%"
        >
          <Icon
            width={{ _: "18px", lg: "36px" }}
            height={{ _: "18px", lg: "36px" }}
            color="white"
            as={PlayNext}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

NextClassPrompt.propTypes = {
  classData: PropTypes.shape({}).isRequired,
  goToNextClass: PropTypes.func.isRequired,
  isClassOverlayTimerStopped: PropTypes.bool.isRequired,
};

export default NextClassPrompt;
