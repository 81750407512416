import { v4 as uuidv4 } from "uuid";
import convertToTimeString from "helpers/convertTimeToString";
import {
  playerLoad,
  classPlayerBuffering,
  usePlaybackControl,
} from "services/typewriter/segment";
import { PlayerLoadEvent } from "./constants";

export const PERF_API_PREFIX = "steezy_player";
const RENDITION_NAME_REGEX = /-(\d*p.*\.m3u8)$/;
const SEEK_BUFFER_THRESHOLD_IN_MS = 1000;

const defaultBeacons = {
  playerLoad,
  classPlayerBuffering,
  usePlaybackControl,
};

class PerformanceTracker {
  loadEvents = [
    PlayerLoadEvent.MOUNTED,
    PlayerLoadEvent.REQUEST_MANIFEST,
    PlayerLoadEvent.REQUEST_PLAYLIST,
    PlayerLoadEvent.REQUEST_FRAGMENT_START,
    PlayerLoadEvent.REQUEST_FRAGMENT_END,
    PlayerLoadEvent.READY,
  ];

  constructor({
    performanceApi,
    metadata,
    playerRef,
    beacons = defaultBeacons,
  }) {
    this.uuid = uuidv4();
    this.metadata = metadata;
    this.performanceApi = performanceApi;
    this.getMetadata = () => ({
      ...this.metadata,
      video_url: this.getVideoUrl(),
      instance_id: this.uuid,
    });
    this.playerRef = playerRef;
    this.beacons = beacons;

    this.loadEvents.forEach(event => {
      this.performanceApi.clearMarks(`${PERF_API_PREFIX}_${event}`);
    });

    this.trackLoadEventWithTiming(
      PlayerLoadEvent.MOUNTED,
      this.performanceApi.now(),
      0
    );
  }

  setMetadata(metadata) {
    this.metadata = metadata;
  }

  getPlayerTime() {
    const time = Math.floor(this.playerRef.current?.getCurrentTime());
    return convertToTimeString(time);
  }

  getVideoUrl() {
    return this.playerRef.current?.player?.props.url;
  }

  getHlsPlayer() {
    return this.playerRef.current?.getInternalPlayer("hls");
  }

  getRenditionDetails() {
    const hlsPlayer = this.getHlsPlayer();
    if (!hlsPlayer || hlsPlayer.currentLevel < 0) {
      // hlsPlayer hasn't loaded yet
      return {};
    }
    const level = hlsPlayer.levels[hlsPlayer.currentLevel];
    const nameMatch = level.details.url.match(RENDITION_NAME_REGEX);
    const name = nameMatch && nameMatch[1];
    return {
      hls_bitrate: level.bitrate,
      hls_name: name,
    };
  }

  getEventTime(eventName) {
    return this.performanceApi
      .getEntriesByType("mark")
      .filter(({ name }) => name === `${PERF_API_PREFIX}_${eventName}`)
      .map(({ startTime }) => startTime)[0];
  }

  trackLoadEvent(loadEvent, metadata) {
    const eventIndex = this.loadEvents.indexOf(loadEvent);
    if (eventIndex < 0) {
      return;
    }
    const prerequisiteEvent = this.loadEvents[eventIndex - 1];
    if (this.previousLoadEvent !== prerequisiteEvent) {
      return;
    }
    const curTime = this.performanceApi.now();
    const prevTime = this.getEventTime(this.previousLoadEvent);

    if (prevTime) {
      this.trackLoadEventWithTiming(loadEvent, curTime, prevTime, metadata);
    }
  }

  trackLoadEventWithTiming(loadEvent, curTime, prevTime, metadata = {}) {
    this.previousLoadEvent = loadEvent;
    this.performanceApi.mark(`${PERF_API_PREFIX}_${loadEvent}`);
    this.beacons.playerLoad({
      ...this.getMetadata(),
      ...metadata,
      load_event_name: loadEvent,
      time_since_page_load: curTime,
      time_since_prev_event: curTime - prevTime,
    });
  }

  trackBufferStart() {
    this.bufferStartTime = this.performanceApi.now();
    const timeSinceSeek = this.bufferStartTime - this.seekTime;
    this.bufferedFromSeek = timeSinceSeek < SEEK_BUFFER_THRESHOLD_IN_MS;
  }

  trackBufferEnd() {
    const bufferDuration =
      this.bufferStartTime && this.performanceApi.now() - this.bufferStartTime;
    this.beacons.classPlayerBuffering({
      ...this.getMetadata(),
      ...this.getRenditionDetails(),
      buffer_duration: bufferDuration,
      buffered_from_seek: this.bufferedFromSeek,
      current_time_in_seconds: this.getPlayerTime(),
    });
  }

  trackSeek(additionalMetadata) {
    this.seekTime = this.performanceApi.now();
    this.beacons.usePlaybackControl({
      ...this.getMetadata(),
      ...additionalMetadata,
      playback_time: this.getPlayerTime(),
      control_used: "Seek",
    });
  }
}

export default PerformanceTracker;
