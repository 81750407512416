import styled from "styled-components";
import Grid from "app/components/Grid";
import Flex from "app/components/Flex";

interface ReactPlayerWrapperProps {
  maxwidth: string;
  maxheight: string;
  showFullscreen: boolean;
  isFrontView: boolean;
  transform: string;
}

export const ReactPlayerWrapper = styled.div<ReactPlayerWrapperProps>`
  & {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;

    #class-player {
      max-width: ${({ maxwidth }) => maxwidth} !important;
      max-height: ${({ maxheight }) => maxheight} !important;
      position: absolute;
      overflow: hidden;
      display: flex;
      /* 
        Adding padding here to avoid a split screen issue that's happening on Chrome
        https://stzy.atlassian.net/browse/CORE-2557

        Theory: chrome fullscreen API is attempting to fit the video within the screen as soon as the video hits the edge of the screen
        By adding padding, it's potentially tricking the chrome API that  our video is NOT touching the edges, avoiding any forceful stretching
      */
      ${({ showFullscreen }) => showFullscreen && "padding: 10px"};

      video {
        height: unset !important;
        position: absolute !important;
        object-fit: cover !important;
        ${({ isFrontView }) => (isFrontView ? "top: 0" : "bottom: 0%")};
        ${({ transform }) => `transform: ${transform}`};
      }
    }
  }
`;

export const PlayerWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
`;

export const PlayerOverlay = styled.div`
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const PlayAndPauseOverlay = styled.div`
  height: 100%;
  width: 100%;
`;

const topBarAttrs = ({ hide }: { hide: boolean }) => ({
  p: 4,
  position: "absolute",
  top: hide ? "-150px" : 0,
  width: "100%",
  justifyContent: "space-between",
  transition: "top 0.25s ease-in-out",
  zIndex: 2,
  gridTemplateColumns: "repeat(3, 1fr)",
});
export const TopBar = styled(Grid).attrs(topBarAttrs)``;

const bottomBarAttrs = ({ hide }: { hide: boolean }) => ({
  p: 4,
  position: "absolute",
  bottom: hide ? "-300px" : 0,
  width: "100%",
  flexDirection: "column",
  transition: "top 0.25s ease-in-out",
  zIndex: 2,
});
export const BottomBar = styled(Flex).attrs(bottomBarAttrs)`
  background: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0, #000 100%);
`;
