import React from "react";
import PropTypes from "prop-types";
import Flex from "app/components/Flex";
import Icon, { StarRating4pt7 } from "app/components/Icon";
import { P2 } from "app/components/Typography";

const StarRatings = ({ textColor = null, ...props }) => (
  <Flex alignContent="center" {...props}>
    <Icon
      as={StarRating4pt7}
      width="79px"
      height="15px"
      color="champYellow.4"
      mr={2}
    />
    <P2 color={textColor || "monochrome.2"}>4.7 • 12K+ ratings</P2>
  </Flex>
);

StarRatings.propTypes = {
  color: PropTypes.string,
};

export default StarRatings;
