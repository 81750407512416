import { useEffect, useState } from "react";
import env from "helpers/env";

export default () => {
  if (env("PUBLIC_ENV") !== "testing") {
    return false;
  }
  const [isDebugMode, setIsDebugMode] = useState(false);
  const handleHashChange = () => {
    const hash = window.location.hash.substr(1);
    if (hash === "debug") {
      setIsDebugMode(true);
    } else {
      setIsDebugMode(false);
    }
  };
  useEffect(() => {
    window.addEventListener("hashchange", handleHashChange, false);
    handleHashChange();
    return () =>
      window.removeEventListener("hashchange", handleHashChange, false);
  }, []);

  return isDebugMode;
};
