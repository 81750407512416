import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import styled from "styled-components";
import { position, layout } from "styled-system";
import { IS_MOBILE } from "constants/index";
import { PlayNext } from "app/components/Icon";
import { ScreenGtMd } from "app/components/MediaQueries";
import Flex from "app/components/Flex";
import { H5, P } from "app/components/Typography";
import Div from "app/components/Div";
import env from "helpers/env";
import { HoverWrapper, ControlButton, ControlIcon } from "./components";

const PreviewWrapper = styled(Flex)`
  position: absolute;
  bottom: 70px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  cursor: pointer;

  ${position}
  ${layout}
`;

const PreviewTextWrapper = styled(Div)`
  display: flex;
  width: 200px;
  flex-direction: column;
`;

const StyledP = styled(P)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Thumbnail = styled.img`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const Triangle = styled(Div)`
  position: absolute;
  bottom: 58px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 16px solid rgba(0, 0, 0, 0.5);
`;

let timeout = null;

const NextButton = ({ goToNextClass, nextClassData }) => {
  const [showNextPreview, setShowNextPreview] = React.useState(false);

  return (
    <HoverWrapper
      onMouseEnter={() => {
        clearTimeout(timeout);
        setShowNextPreview(true);
      }}
      onMouseLeave={() => {
        timeout = setTimeout(() => {
          setShowNextPreview(false);
        }, 500);
      }}
    >
      <ControlButton width="37px" onClick={() => goToNextClass()}>
        <ControlIcon color="white" as={PlayNext} />
      </ControlButton>
      {!IS_MOBILE && (
        <ScreenGtMd>
          <Triangle display={showNextPreview ? "block" : "none"} />
          <PreviewWrapper
            display={showNextPreview ? "flex" : "none"}
            onClick={() => goToNextClass()}
          >
            <Thumbnail
              alt="Next Class Thumbnail"
              src={`${env("PUBLIC_ASSET_PATH")}/class-thumbnails/${
                nextClassData.thumbnail
              }-basic.jpg?w=175`}
            />
            <PreviewTextWrapper paddingTop={[3]} paddingLeft={[3]}>
              <H5 margin="0px" marginBottom={[2]}>
                Next Class
              </H5>
              <StyledP overflow="hidden">{nextClassData.title}</StyledP>
            </PreviewTextWrapper>
          </PreviewWrapper>
        </ScreenGtMd>
      )}
    </HoverWrapper>
  );
};

NextButton.defaultProps = {
  programData: null,
  nextClassData: null,
};

NextButton.propTypes = {
  goToNextClass: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  nextClassData: PropTypes.shape({
    id: PropTypes.number,
  }),
  programData: PropTypes.shape({}),
};

export default withRouter(NextButton);
