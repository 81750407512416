import { useEffect, useState } from "react";
import { MutedSectionCuepoint } from "services/graphql";

interface Props {
  currentTime: number;
  mutedCuepoints: MutedSectionCuepoint[];
}

export function useMutedCuepoints({ currentTime, mutedCuepoints }: Props) {
  const [mutedCuepoint, setMutedCuepoint] = useState(null);

  useEffect(() => {
    if (!mutedCuepoints) {
      return;
    }
    if (mutedCuepoints.length > 0) {
      const foundMutedCuepoint = mutedCuepoints.find(
        (cuepoint: any) =>
          currentTime >= cuepoint.startsAtTimeSec &&
          currentTime <= cuepoint.endsAtTimeSec
      );

      setMutedCuepoint(foundMutedCuepoint);
    }
  }, [currentTime]);

  return { mutedCuepoint };
}
