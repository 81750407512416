import React from "react";
import Slider, { createSliderWithTooltip } from "rc-slider";
import styled from "styled-components";
import "rc-slider/assets/index.css";

const StyledSlider = styled(createSliderWithTooltip(Slider))`
  .rc-slider-vertical {
    padding: 0;
    width: 4px;
  }
  .rc-slider-rail {
    height: 8px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0;
    opacity: 0.4;
  }
  .rc-slider-step {
  }
  .rc-slider-track {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0;
  }
  .rc-slider-handle {
    background-color: ${({ theme }) => theme.colors.white};
    border: none;
  }
  .rc-slider-handle:focus {
    border-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.white};
  }
  .rc-slider-handle:hover {
    border-color: ${({ theme }) => theme.colors.white};
  }
  .rc-slider-handle:active {
    border-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 3px ${({ theme }) => theme.colors.white};
  }
`;

const VerticalSlider = (props: any) => <StyledSlider vertical {...props} />;

export default VerticalSlider;
