/* eslint-disable no-param-reassign */

// player api expected from react-player package
export const getReactPlayer = ({ dashPlayer, videoEl, onError }) => ({
  load: async () => {
    // do nothing. this is handled in the React component so it can get updated props
  },
  stop: () => {
    videoEl.removeAttribute("src");
    dashPlayer.reset();
  },
  play: () => {
    const promise = videoEl.play();
    if (promise) {
      promise.catch(onError);
    }
  },
  pause: () => {
    videoEl.pause();
  },
  setVolume: volume => {
    videoEl.volume = volume;
  },
  mute: () => {
    videoEl.muted = true;
  },
  unmute: () => {
    videoEl.muted = false;
  },
  setPlaybackRate: rate => {
    try {
      videoEl.playbackRate = rate;
    } catch (e) {
      onError(e);
    }
  },
  getDuration: () => {
    return videoEl.duration;
  },
  getCurrentTime: () => {
    return videoEl.currentTime;
  },
  getSecondsLoaded: () => {
    const { buffered, duration } = videoEl;
    if (buffered.length === 0) {
      return 0;
    }
    const end = buffered.end(buffered.length - 1);
    if (end > duration) {
      return duration;
    }
    return end;
  },
  seekTo: seconds => {
    videoEl.currentTime = seconds;
  },
  customreactdash: dashPlayer,
});
