import mux from "mux-embed";
import env from "helpers/env";

export const initializeMux = ({ auth, classData, videoUrl }) => {
  let videoCdn;

  if (videoUrl.includes("fastly")) {
    videoCdn = "Fastly";
  } else if (videoUrl.includes("cloudfront")) {
    videoCdn = "CloudFront";
  }

  mux.monitor("#class-player > video", {
    debug: false, // toggle true only if needed to check mux console logging
    data: {
      env_key: env("PUBLIC_MUX_KEY"),
      page_type: "watchpage", // (see docs) 'watchpage', 'iframe', or leave empty
      viewer_user_id: auth.uid, // ex: '12345'

      // Player Metadata
      player_name: "web react-player", // ex: 'My Main Player'
      player_version: "1.0.0", // ex: '1.0.0'
      player_init_time: Date.now(), // ex: 1451606400000

      // Video Metadata (cleared with 'videochange' event)
      video_id: classData.id,
      video_title: `${
        classData.instructor ? `${classData.instructor.name} - ` : ""
      }${classData.title}`,
      video_content_type: `${classData.style} ${classData.type}`,
      video_language_code: "en",
      video_duration: classData.duration_in_seconds * 1000, // in milliseconds, ex: 120000
      video_series: classData.type,
      video_stream_type: "on-demand",
      video_cdn: videoCdn,
    },
  });
};

export default {};
