import { useEffect, useRef, useState } from "react";
import {
  useCreatePlaySessionMutation,
  useRefreshPlaySessionMutation
} from "services/graphql";
import {
  cachePlaySession,
  checkCacheForNotExpiredPlaySession,
  clearCachedPlaySession,
} from "./helpers";

export interface PlaySession {
  playSessionId: string; // "3f4dbab7-f68c-49b6-a86f-b956756294a5"
}

export function usePlaySession() {
  const { playSession, forceResumePlayback } = useGetPlaySession();
  const { invalidPlaySession } = useRefreshPlaySession(playSession);

  return { invalidPlaySession, forceResumePlayback };
}

function useGetPlaySession() {
  const [ createPlaySessionMutation ] = useCreatePlaySessionMutation();
  const [ refreshPlaySessionMutation ] = useRefreshPlaySessionMutation();

  const [ playSession, setPlaySession ] =
    useState<PlaySession | null>(null);

  useEffect(() => {
    getPlaySession();
  }, []);

  async function getPlaySession() {
    const cachedSession = checkCacheForNotExpiredPlaySession();

    if (!cachedSession) {
      await createPlaySession();

      return;
    }

    try {
      await refreshPlaySessionMutation(
        { variables: { playSessionId: cachedSession.playSessionId } }
      );

      setPlaySession(cachedSession);
    } catch (e) {
      // Cached session likely evicted. Just create a new one.
      await createPlaySession();
    }
  }

  async function createPlaySession() {
    try {
      const { data } = await createPlaySessionMutation();
      if (!data) {
        // Usually we would check `errors`, but we throw underneath on error.
        // Should never actually get here, but sanity for TS.
        throw "Unexpected error";
      }

      const playSessionId = data.playSessionCreate;

      cachePlaySession({ playSessionId });
      setPlaySession({ playSessionId });
    } catch (e) {
      // Not explicitly handling any create play session errors.
      // This happens really close to when the class data is loaded,
      // so presumably if this were to fail, that would've failed too.
      // On the very off chance, users just get a "free" session for one vid.
      setPlaySession(null);
    }
  }

  return {
    playSession,
    forceResumePlayback: () => { getPlaySession(); },
  }
}

function useRefreshPlaySession(playSession: PlaySession | null) {
  const [ refreshPlaySessionMutation ] = useRefreshPlaySessionMutation();

  const [ invalidPlaySession, setInvalidPlaySession ] = useState(false);
  const timeoutRef = useRef<any>(null);

  const { playSessionId } = (playSession || {});

  useEffect(() => {
    if (!playSession) {
      return;
    }

    // Clear error if needed everytime we get a new play session.
    invalidPlaySession && setInvalidPlaySession(false);

    queueUpRefreshPlaySessionRequest(playSession);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [playSessionId]);

  function queueUpRefreshPlaySessionRequest(
    { playSessionId }: PlaySession,
    secondsUntilRequest=30
  ) {
    timeoutRef.current = setTimeout(async () => {
      try {
        await refreshPlaySessionMutation(
          { variables: { playSessionId } }
        );

        queueUpRefreshPlaySessionRequest({ playSessionId });
      } catch (e: any) {
        if (e.message === "INVALID_PLAY_SESSION") { // Handled Apollo error(s).
          clearCachedPlaySession();
          setInvalidPlaySession(true);

          return;
        }

        // Handle unexpected servers restarts; etc. Try again in 10 seconds.
        // If server never recovers, users will just get to watch the video
        // without it counting towards their "sessions".
        // ^This should resolve upon next video.
        queueUpRefreshPlaySessionRequest({ playSessionId }, 10);
      }
    }, (secondsUntilRequest * 1000));
  }

  return { invalidPlaySession };
}
