import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { View, Loop, Mirror, Camera } from "app/components/Icon";
import Flex from "app/components/Flex";
import Grid from "app/components/Grid";
import { H7 } from "app/components/Typography";
import Tooltip from "app/components/Tooltip";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import { ControlButton, ControlIcon } from "../components";
import { ControlWrapper } from "./styles";
import { SpeedTooltip } from "./SpeedTooltip";

const AdvancedControls = ({
  classPlayerFeatures,
  isFrontView,
  isShowingSpeedAdjustment,
  isMirrored,
  partyStarted,
  playerWrapperRef,
  showOverlay,
  showWebcam,
  toggleLoop,
  toggleMirror,
  toggleSpeedAdjustment,
  toggleView,
  toggleWebcam,
  trackSpeedEvent,
}) => {
  const {
    isMirrorAvailable,
    isLoopingAvailable,
    isCameraAvailable,
    isBackViewAvailable,
  } = classPlayerFeatures;
  const looping = useSelector(({ classPlayer }) => classPlayer.looping);
  const { isFamilyModeOn } = useFamilyModeSetting();

  const featureUnavailableTooltip = "Unavailable for video";
  const isCameraControlShowing = !partyStarted && !isFamilyModeOn;
  const controlsCount = isCameraControlShowing ? 5 : 4;

  return (
    <ControlWrapper>
      <Flex flex={1} alignItems="center" justifyContent="center">
        <Grid gridTemplateColumns={`repeat(${controlsCount}, 1fr)`} gridGap={4}>
          {isMirrorAvailable ? (
            <Tooltip
              getTooltipContainer={() => playerWrapperRef.current}
              overlay={
                isMirrorAvailable
                  ? `Turn ${isMirrored ? "Off" : "On"} Mirrored View`
                  : featureUnavailableTooltip
              }
            >
              <Flex flexDirection="column" alignItems="center">
                <ControlButton
                  data-tip
                  data-for="isMirrored"
                  disabled={!isMirrorAvailable}
                  onClick={() => !showOverlay && toggleMirror()}
                >
                  <ControlIcon
                    width="30px"
                    height="30px"
                    as={Mirror}
                    color={isMirrored ? "champYellow.2" : "white"}
                  />
                </ControlButton>
                <H7
                  mt={2}
                  opacity={isMirrorAvailable ? 1 : 0.2}
                  textAlign="center"
                  color={isMirrored ? "champYellow.2" : "white"}
                >
                  Mirror (M)
                  <br />
                  {isMirrored ? "On" : "Off"}
                </H7>
              </Flex>
            </Tooltip>
          ) : (
            <Tooltip
              getTooltipContainer={() => playerWrapperRef.current}
              overlay={featureUnavailableTooltip}
            >
              <Flex flexDirection="column" alignItems="center">
                <ControlButton disabled>
                  <ControlIcon width="30px" height="30px" as={Mirror} />
                </ControlButton>
              </Flex>
            </Tooltip>
          )}

          <Tooltip
            getTooltipContainer={() => playerWrapperRef.current}
            overlay={() => {
              if (!isLoopingAvailable) {
                return featureUnavailableTooltip;
              }

              return looping ? "Exit Looping Mode" : "Start Looping Mode";
            }}
          >
            <Flex flexDirection="column" alignItems="center">
              <ControlButton
                data-tip
                data-for="loop"
                disabled={!isLoopingAvailable}
                onClick={() => {
                  if (!isLoopingAvailable) {
                    return;
                  }

                  if (showOverlay) {
                    return;
                  }

                  toggleLoop(!looping);
                }}
              >
                <ControlIcon
                  width="30px"
                  height="30px"
                  as={Loop}
                  color={looping ? "champYellow.2" : "white"}
                />
              </ControlButton>
              <H7
                mt={2}
                opacity={isLoopingAvailable ? 1 : 0.2}
                textAlign="center"
                color={looping ? "champYellow.2" : "white"}
              >
                Looping (L)
                <br />
                {looping ? "On" : "Off"}
              </H7>
            </Flex>
          </Tooltip>
          <SpeedTooltip
            playerWrapperRef={playerWrapperRef}
            isShowingSpeedAdjustment={isShowingSpeedAdjustment}
            toggleSpeedAdjustment={toggleSpeedAdjustment}
            trackSpeedEvent={trackSpeedEvent}
          />
          {isBackViewAvailable ? (
            <Tooltip
              getTooltipContainer={() => playerWrapperRef.current}
              overlay={
                isFrontView ? "Switch to Back View" : "Switch to Front View"
              }
            >
              <Flex flexDirection="column" alignItems="center">
                <ControlButton data-tip data-for="view" onClick={toggleView}>
                  <ControlIcon
                    width="30px"
                    height="30px"
                    as={View}
                    color={isFrontView ? "white" : "champYellow.2"}
                  />
                </ControlButton>
                <H7
                  mt={2}
                  opacity={isBackViewAvailable ? 1 : 0.2}
                  textAlign="center"
                  color={isFrontView ? "white" : "champYellow.2"}
                >
                  View (Shift)
                  <br />
                  {isFrontView ? "Front" : "Back"}
                </H7>
              </Flex>
            </Tooltip>
          ) : (
            <Tooltip
              getTooltipContainer={() => playerWrapperRef.current}
              overlay={featureUnavailableTooltip}
            >
              <Flex flexDirection="column" alignItems="center">
                <ControlButton disabled>
                  <ControlIcon width="30px" height="30px" as={View} />
                </ControlButton>
              </Flex>
            </Tooltip>
          )}

          {isCameraControlShowing && (
            <Tooltip
              getTooltipContainer={() => playerWrapperRef.current}
              overlay={
                isCameraAvailable
                  ? `${showWebcam ? "Hide" : "Show"} Webcam`
                  : featureUnavailableTooltip
              }
            >
              <Flex flexDirection="column" alignItems="center">
                <ControlButton
                  data-tip
                  data-for="webcam"
                  disabled={!isCameraAvailable}
                  onClick={() => !showOverlay && toggleWebcam()}
                >
                  <ControlIcon
                    width="30px"
                    height="30px"
                    as={Camera}
                    color={showWebcam ? "champYellow.2" : "white"}
                  />
                </ControlButton>
                <H7
                  mt={2}
                  opacity={isCameraAvailable ? 1 : 0.2}
                  textAlign="center"
                  color={showWebcam ? "champYellow.2" : "white"}
                >
                  Camera (W)
                  <br />
                  {showWebcam ? "On" : "Off"}
                </H7>
              </Flex>
            </Tooltip>
          )}
        </Grid>
      </Flex>
    </ControlWrapper>
  );
};

AdvancedControls.defaultProps = {
  isShowingSpeedAdjustment: false,
  partyStarted: false,
  showOverlay: false,
};

AdvancedControls.propTypes = {
  isFrontView: PropTypes.bool.isRequired,
  isShowingSpeedAdjustment: PropTypes.bool,
  isMirrored: PropTypes.bool.isRequired,
  partyStarted: PropTypes.bool,
  playerWrapperRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  showOverlay: PropTypes.bool,
  showWebcam: PropTypes.bool.isRequired,
  toggleLoop: PropTypes.func.isRequired,
  toggleMirror: PropTypes.func.isRequired,
  toggleSpeedAdjustment: PropTypes.func.isRequired,
  toggleView: PropTypes.func.isRequired,
  toggleWebcam: PropTypes.func.isRequired,
  trackSpeedEvent: PropTypes.func.isRequired,
};

export default AdvancedControls;
