import styled from "styled-components";
import Div from "app/components/Div";

export const ThiccLine = styled(Div)`
  margin-top: 12px;
  margin-bottom: 12px;
  border: 0;
  border-top: 2px solid #ebefef;
`;

export const ThinLine = styled(Div)`
  margin-top: 12px;
  margin-bottom: 12px;
  border: 0;
  border-top: 1px solid #ebefef;
`;
