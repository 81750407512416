export const extractContentId = initData => {
  const uint16array = new Uint16Array(initData.buffer);
  let contentId = String.fromCharCode.apply(null, uint16array);
  contentId = contentId.split("skd://")[1];

  return contentId;
};

const stringToArray = string => {
  const buffer = new ArrayBuffer(string.length * 2); // 2 bytes for each char
  const array = new Uint16Array(buffer);
  for (let i = 0, strLen = string.length; i < strLen; i++) {
    array[i] = string.charCodeAt(i);
  }
  return array;
};

export const concatInitDataIdAndCertificate = (initData, paramId, cert) => {
  let id = paramId;
  if (typeof paramId === "string") {
    id = stringToArray(paramId);
  }
  // layout is [initData][4 byte: idLength][idLength byte: id][4 byte:certLength][certLength byte: cert]
  let offset = 0;
  const buffer = new ArrayBuffer(
    initData.byteLength
    + 4 + id.byteLength
    + 4 + cert.byteLength
  );
  const dataView = new DataView(buffer);

  const initDataArray = new Uint8Array(buffer, offset, initData.byteLength);
  initDataArray.set(initData);
  offset += initData.byteLength;
  dataView.setUint32(offset, id.byteLength, true);
  offset += 4;

  const idArray = new Uint16Array(buffer, offset, id.length);
  idArray.set(id);
  offset += idArray.byteLength;
  dataView.setUint32(offset, cert.byteLength, true);
  offset += 4;

  const certArray = new Uint8Array(buffer, offset, cert.byteLength);
  certArray.set(cert);
  return new Uint8Array(buffer, 0, buffer.byteLength);
};
