import RadioInputChecked from "app/assets/svg/radio-input-checked.svg";
import RadioInputUnchecked from "app/assets/svg/radio-input-unchecked.svg";
import React from "react";
import { BillingPeriodUnit, Plan } from "services/graphql";
import { centsToDollars } from "helpers/centsToDollars";
import { AbbreviatedPeriodUnits } from "constants/cart";
import { useCheckout } from "modules/selectors";
import { useDispatch } from "react-redux";
import { setSelectedPlan } from "modules/checkout";
import styles from "./styles.module.scss";
import { BenefitsText } from "../BenefitsText";

interface Props {
  plan: Plan;
}

export function PlanCard({ plan }: Props) {
  const dispatch = useDispatch();
  const abbreviatedPeriodUnit =
    AbbreviatedPeriodUnits[
      plan?.periodUnit as keyof typeof AbbreviatedPeriodUnits
    ];
  const { selectedPlan } = useCheckout();
  const selectPlan = () => dispatch(setSelectedPlan(plan));
  const isToggled = selectedPlan?.id === plan.id;

  return (
    <div
      key={plan.id}
      className={
        isToggled ? styles.toggledPlanCardContainer : styles.planCardContainer
      }
      onClick={selectPlan}
    >
      <div className={styles.planCardHeaderContainer}>
        <header>{plan.accessType}</header>
        {isToggled ? <RadioInputChecked /> : <RadioInputUnchecked />}
      </div>
      <div className={styles.planCardSubContainer}>
        <div>
          <div className={styles.planCardCostContainer}>
            <h2>${centsToDollars(plan.monthlyCostInCents)}/mo</h2>
            {plan?.periodUnit !== BillingPeriodUnit.Month && (
              <p>
                (Billed ${centsToDollars(plan.priceInCents)}/
                {abbreviatedPeriodUnit})
              </p>
            )}
          </div>
          <BenefitsText plan={plan} />
        </div>
      </div>
    </div>
  );
}
