export const PlayerLoadEvent = {
  MOUNTED: "mounted",
  REQUEST_MANIFEST: "request_manifest",
  REQUEST_PLAYLIST: "request_playlist",
  REQUEST_FRAGMENT_START: "request_fragment_start",
  REQUEST_FRAGMENT_END: "request_fragment_end",
  READY: "ready",
};

export default {};
