import React from "react";
import { CheckoutHeaderTotals } from "app/components/Checkout/CheckoutHeaderTotals";
import styles from "./styles.module.scss";

interface Props {
  setIsCheckingOut(isCheckingOut: boolean): void;
}

export function CheckoutHeader({ setIsCheckingOut }: Props) {
  return (
    <div>
      <div>
        <div className={styles.toggleText}>
          <div onClick={() => setIsCheckingOut(false)}>
            <p>Change Plans</p>
          </div>
        </div>
      </div>
      <div className={styles.dividerThin} />
      <CheckoutHeaderTotals />
    </div>
  );
}
