import { useMemo } from "react";
import { useLocation } from "react-router";
import returnCurrentDaypart from "helpers/returnCurrentDaypart";
import { useAccountMode } from "hooks/useAccountMode";
import { useSelector } from "react-redux";
import usePlayerSettings from "hooks/usePlayerSettings";
import queryString from "query-string";
import { getAnalyticsLocationFromPath } from "helpers/analytics";
import {
  View,
  classContinueClass,
  classEndClass,
  classReviewClass,
  classStartClass,
  classTakingClass,
} from "services/typewriter/segment";
import { useCanUserTakeClass } from "hooks/Classes";

interface EventProps {
  classData: any;
  programData: any;
}

export function useClassEventData({ classData, programData }: EventProps) {
  const { canUserTakeClass } = useCanUserTakeClass({
    classId: classData.id,
  });

  const programSubsetData = useMemo(() => {
    if (!programData || !classData.programMetadata) {
      return {};
    }
    const { block, section } = classData.programMetadata;

    return {
      program_slug: programData.slug,
      program_title: programData.title,
      program_block_title: block?.title,
      program_block_index: block?.sortIndex,
      program_section_title: section?.title,
      program_section_index: section?.sortIndex,
    };
  }, [classData.programMetadata, programData]);

  const classEventData = useMemo(
    () => ({
      class_id: classData.id,
      type: classData.type,
      instructor: classData.instructor?.name,
      level: classData.level,
      style: classData.style,
      title: classData.title,
      categories: classData.categories,
      content_locked: !canUserTakeClass,
      ...programSubsetData,
    }),
    [
      classData.id,
      classData.type,
      classData.instructor?.name,
      classData.level,
      classData.style,
      classData.title,
      classData.categories,
      programSubsetData,
      canUserTakeClass,
    ]
  );

  return {
    classEventData,
  };
}

interface LocationState {
  state: {
    previousLocation: {
      pathname: string;
    };
  };
  search: string;
}

export function useBeginClassEvents({ classData, programData }: EventProps) {
  const { classEventData } = useClassEventData({ classData, programData });
  const location: LocationState = useLocation();
  const previousPathname = location.state?.previousLocation?.pathname;
  const { accountMode } = useAccountMode();
  const { playlistId } = queryString.parse(location.search);

  const trackObj = {
    ...classEventData,
    playlist_id: playlistId,
    daypart: returnCurrentDaypart(),
    location: getAnalyticsLocationFromPath(previousPathname),
    account_mode: accountMode,
  };

  return {
    startClassEvent: () => classStartClass(trackObj),
    continueClassEvent: () => classContinueClass(trackObj),
    reviewClassEvent: () => classReviewClass(trackObj),
  };
}

export function useEndClassEvent({ classData, programData }: EventProps) {
  const { classEventData } = useClassEventData({ classData, programData });

  const trackObj = {
    ...classEventData,
    daypart: returnCurrentDaypart(),
  };

  return {
    endClassEvent: ({
      method,
      percent_completed,
    }: {
      method: string;
      percent_completed: number;
    }) => classEndClass({ ...trackObj, method, percent_completed }),
  };
}

export function useTakingClassEvent({ classData, programData }: EventProps) {
  const { classEventData } = useClassEventData({ classData, programData });
  const { looping } = useSelector(
    ({ classPlayer }: { classPlayer: any }) => classPlayer
  );
  const { isFrontView, mirrored, showWebcam } = usePlayerSettings();

  const trackObj = {
    ...classEventData,
    looping,
    mirrored,
    camera: showWebcam,
    daypart: returnCurrentDaypart(),
    view: isFrontView ? View.Front : View.Back,
  };

  return {
    takingClassEvent: ({
      interval,
      percent_progress,
      session_minute_reached,
    }: {
      interval: number;
      percent_progress: number;
      session_minute_reached: number;
    }) =>
      classTakingClass({
        ...trackObj,
        percent_progress,
        interval,
        session_minute_reached,
      }),
  };
}
