import { usePlans, useTieredPlans } from "hooks/Checkout";
import { setSelectedPlan } from "modules/checkout";
import { useCheckout } from "modules/selectors";
import { useEffect, useState, useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  AccessType,
  BillingPeriodUnit,
  ChangePlanType,
} from "services/graphql";
import { SubscribeModalContext } from "./context";

interface Props {
  accessTypeRequired?: AccessType;
}

export function useUpgradePlans({ accessTypeRequired }: Props) {
  const { selectedPlan } = useCheckout();
  const dispatch = useDispatch();
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState(
    BillingPeriodUnit.Year
  );
  const { data: plansData, loading: loadingPlansData } = usePlans();
  const { plans } = plansData || {};
  const { tieredPlans } = useTieredPlans({
    plans,
    billingPeriodUnit: selectedBillingPeriod,
  });

  const upgradePlans = useMemo(
    () =>
      tieredPlans
        ? Object.keys(tieredPlans)
            .map(key => tieredPlans[key as any])
            .filter(
              plan =>
                plan.changePlanEstimate?.changePlanType ===
                ChangePlanType.Upgrade
            )
            .sort((a, b) => a.priceInCents - b.priceInCents)
        : null,
    [tieredPlans]
  );

  useEffect(() => {
    if (!upgradePlans) {
      return;
    }

    let newSelectedPlan;

    if (accessTypeRequired) {
      newSelectedPlan = upgradePlans.find(
        plan => plan.accessType === accessTypeRequired
      );
    } else {
      // @TODO: Best value on the back-end is returning true if plan is yearly, might need to update to return true only if standard or premium
      [newSelectedPlan] = upgradePlans.filter(plan => plan.isBestValue);
    }

    dispatch(setSelectedPlan(newSelectedPlan));
  }, [plans, upgradePlans]);

  // Select new plan when selected billing period changes
  useEffect(() => {
    if (!upgradePlans) {
      return;
    }

    if (!selectedPlan) {
      return;
    }

    const newSelectedPlan = upgradePlans.find(
      plan => plan.accessType === selectedPlan.accessType
    );

    dispatch(setSelectedPlan(newSelectedPlan));
  }, [selectedBillingPeriod]);

  if (loadingPlansData) {
    return {};
  }

  return { upgradePlans, setSelectedBillingPeriod, selectedBillingPeriod };
}

export function useStartSubscriptionPlans({ accessTypeRequired }: Props) {
  const { selectedPlan } = useCheckout();
  const dispatch = useDispatch();
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState(
    BillingPeriodUnit.Year
  );
  const { data: plansData, loading: loadingPlansData } = usePlans();
  const { plans } = plansData || {};
  const { tieredPlans } = useTieredPlans({
    plans,
    billingPeriodUnit: selectedBillingPeriod,
  });

  const startSubscriptionPlans = useMemo(
    () =>
      tieredPlans
        ? Object.keys(tieredPlans)
            .map(key => tieredPlans[key as any])
            .sort((a, b) => a.priceInCents - b.priceInCents)
        : null,
    [tieredPlans]
  );

  // Select initial plan based on accessTypeRequired
  useEffect(() => {
    if (!startSubscriptionPlans) {
      return;
    }

    let newSelectedPlan;

    if (accessTypeRequired) {
      newSelectedPlan = startSubscriptionPlans.find(
        plan => plan.accessType === accessTypeRequired
      );
    } else {
      // @TODO: Best value on the back-end is returning true if plan is yearly, might need to update to return true only if standard or premium
      [newSelectedPlan] = startSubscriptionPlans.filter(
        plan => plan.isBestValue
      );
    }

    dispatch(setSelectedPlan(newSelectedPlan));
  }, [plans]);

  // Select new plan when selected billing period changes
  useEffect(() => {
    if (!startSubscriptionPlans) {
      return;
    }

    const newSelectedPlan = startSubscriptionPlans.find(
      plan => plan.accessType === selectedPlan.accessType
    );

    dispatch(setSelectedPlan(newSelectedPlan));
  }, [selectedBillingPeriod]);

  if (loadingPlansData) {
    return {};
  }

  return {
    startSubscriptionPlans,
    setSelectedBillingPeriod,
    selectedBillingPeriod,
  };
}
export const useSubscribeModalContext = () => useContext(SubscribeModalContext);
