import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CircularProgressBar from "react-circular-progressbar";

const Wrapper = styled.div`
  .CircularProgressbar {
    width: 100%;
  }

  .CircularProgressbar .CircularProgressbar-path {
    stroke: ${props => props.theme.colors.blue};
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
  }

  .CircularProgressbar .CircularProgressbar-trail {
    stroke: ${props => props.theme.colors.lightGrey};
  }

  .CircularProgressbar .CircularProgressbar-text {
    fill: ${props => props.theme.colors.black};
    font-size: 16px;
    dominant-baseline: middle;
    text-anchor: middle;
  }

  .CircularProgressbar .CircularProgressbar-background {
    fill: ${props => props.theme.colors.lightGrey};
  }

  .CircularProgressbar.CircularProgressbar-inverted
    .CircularProgressbar-background {
    fill: ${props => props.theme.colors.blue};
  }

  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
    fill: #fff;
  }

  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
    stroke: #fff;
  }

  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
    stroke: transparent;
  }
`;

const CircularProgressBarComponent = props => (
  <Wrapper>
    <CircularProgressBar {...props} />
  </Wrapper>
);

CircularProgressBarComponent.defaultProps = {
  strokeWidth: 2,
};

CircularProgressBarComponent.propTypes = {
  strokeWidth: PropTypes.number,
};

export default CircularProgressBarComponent;
