/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { isSafari } from "react-device-detect";
import axios from "axios";
import { useAuthHeaders } from "helpers/session/useAuthHeaders";
import { getReactPlayer } from "./getReactPlayer";
import { extractContentId, concatInitDataIdAndCertificate } from "./utils";
import { isClassPreview } from "helpers/isClassPreview";

const CustomDrmHlsReactPlayer = ({
                                   onMount,
                                   onReady,
                                   onPlay,
                                   onBuffer,
                                   onBufferEnd,
                                   onPause,
                                   onEnded,
                                   onError,
                                   onPlayBackRateChange,
                                   onPresentationModeChange,
                                   onEnablePIP,
                                   onDisablePIP,
                                   onSeek,
                                   onLoaded,
                                   playing,
                                   config,
                                   controls,
                                   muted,
                                   loop,
                                   width,
                                   height,
                                   playsinline,
                                 }) => {
  const videoElRef = useRef(null);
  const certificateRef = useRef(null);
  const keySessionRef = useRef(null);

  const { authHeaders, loadingAuthHeaders } = useAuthHeaders();

  const style = {
    // copied from FilePlayer in react-player package
    width: width === "auto" ? width : "100%",
    height: height === "auto" ? height : "100%",
  };

  const onLicenseRequestReady = async e => {
    let resp = null;
    try {
      resp = await axios.post(config.licenseUrl, e.message, {
        headers: {
          "Content-Type": "application/octet-stream",
          ...authHeaders,
        },
        responseType: "arraybuffer",
      });
    } catch (err) {
      /* continue to error */
    }
    if (!resp || !resp.data) {
      onError("DRM Hls: Could not request license");
    }
    const key = new Uint8Array(resp.data);
    keySessionRef.current.update(key);
  };

  const onNeedKey = e => {
    const videoEl = videoElRef.current;
    const { initData } = e;
    const contentId = extractContentId(initData);
    const concatData = concatInitDataIdAndCertificate(
      initData,
      contentId,
      certificateRef.current
    );
    if (
      !videoEl.webkitKeys &&
      !window.WebKitMediaKeys.isTypeSupported("com.apple.fps.1_0", "video/mp4")
    ) {
      onError("DRM Hls: Key system not supported");
      return;
    }
    videoEl.webkitSetMediaKeys(new window.WebKitMediaKeys("com.apple.fps.1_0"));
    if (!videoEl.webkitKeys) {
      onError("DRM Hls: Could not create MediaKeys");
      return;
    }

    keySessionRef.current = videoEl.webkitKeys.createSession(
      "video/mp4",
      concatData
    );
    if (!keySessionRef.current) {
      onError("DRM Hls: Could not create key session");
      return;
    }

    keySessionRef.current.contentId = contentId;
    keySessionRef.current.addEventListener(
      "webkitkeymessage",
      onLicenseRequestReady
    );
    keySessionRef.current.addEventListener("webkitkeyerror", () =>
      onError("DRM Hls: A decryption key error was encountered")
    );
  };

  const loadCertificate = async () => {
    if (!certificateRef.current) {
      let resp = null;
      try {
        resp = await axios.get(config.certificateUrl, {
          responseType: "arraybuffer",
        });
      } catch (e) {
        /* continue to error */
      }
      if (!resp || !resp.data) {
        onError("DRM Hls: Could not retrieve certificate");
        return;
      }
      certificateRef.current = new Uint8Array(resp.data);
    }
    const reactPlayer = getReactPlayer({
      videoEl: videoElRef.current,
      onLoaded,
      onError,
    });
    onMount(reactPlayer);
  };

  useEffect(() => {
    if (loadingAuthHeaders) {
      return;
    }

    const videoEl = videoElRef.current;
    if (videoEl) {
      loadCertificate();

      videoEl.addEventListener("play", onPlay);
      videoEl.addEventListener("waiting", onBuffer);
      videoEl.addEventListener("playing", onBufferEnd);
      videoEl.addEventListener("pause", onPause);
      videoEl.addEventListener("seeked", onSeek);
      videoEl.addEventListener("ended", onEnded);
      videoEl.addEventListener("error", onError);
      videoEl.addEventListener("ratechange", onPlayBackRateChange);
      videoEl.addEventListener("enterpictureinpicture", onEnablePIP);
      videoEl.addEventListener("leavepictureinpicture", onDisablePIP);
      videoEl.addEventListener(
        "webkitpresentationmodechanged",
        onPresentationModeChange
      );
      videoEl.addEventListener("canplay", onReady);
      videoEl.addEventListener("webkitneedkey", onNeedKey);

      if (playsinline) {
        videoEl.setAttribute("playsinline", "");
        videoEl.setAttribute("webkit-playsinline", "");
        videoEl.setAttribute("x5-playsinline", "");
      }
    }

    return () => {
      if (videoEl) {
        videoEl.removeEventListener("play", onPlay);
        videoEl.removeEventListener("waiting", onBuffer);
        videoEl.removeEventListener("playing", onBufferEnd);
        videoEl.removeEventListener("pause", onPause);
        videoEl.removeEventListener("seeked", onSeek);
        videoEl.removeEventListener("ended", onEnded);
        videoEl.removeEventListener("error", onError);
        videoEl.removeEventListener("ratechange", onPlayBackRateChange);
        videoEl.removeEventListener("enterpictureinpicture", onEnablePIP);
        videoEl.removeEventListener("leavepictureinpicture", onDisablePIP);
        videoEl.removeEventListener(
          "webkitpresentationmodechanged",
          onPresentationModeChange
        );
        videoEl.removeEventListener("canplay", onReady);
        videoEl.removeEventListener("webkitneedkey", onNeedKey);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoElRef.current, loadingAuthHeaders]);

  return (
    <video
      ref={videoElRef}
      style={style}
      preload="auto"
      autoPlay={playing || undefined}
      controls={controls}
      muted={muted}
      loop={loop}
    />
  );
};

CustomDrmHlsReactPlayer.propTypes = {
  onMount: PropTypes.func,
  onReady: PropTypes.func,
  onPlay: PropTypes.func,
  onBuffer: PropTypes.func,
  onBufferEnd: PropTypes.func,
  onPause: PropTypes.func,
  onEnded: PropTypes.func,
  onError: PropTypes.func,
  onPlayBackRateChange: PropTypes.func,
  onPresentationModeChange: PropTypes.func,
  onEnablePIP: PropTypes.func,
  onDisablePIP: PropTypes.func,
  onSeek: PropTypes.func,
  onLoaded: PropTypes.func,
  playing: PropTypes.bool,
  config: PropTypes.shape({
    licenseUrl: PropTypes.string,
    certificateUrl: PropTypes.string,
  }),
  controls: PropTypes.bool,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  playsinline: PropTypes.bool,
};
CustomDrmHlsReactPlayer.key = "customdrmhls";
CustomDrmHlsReactPlayer.displayName = "CustomDrmHlsReactPlayer";
CustomDrmHlsReactPlayer.canPlay = (url) => isSafari && !isClassPreview(url);

export default CustomDrmHlsReactPlayer;
