import React from "react";
import Grid from "app/components/Grid";
import { SpeedOption } from "./styles";

const SPEED_OPTIONS = [
  {
    label: "0.5x",
    value: 0.5,
  },
  {
    label: "0.6x",
    value: 0.6,
  },
  {
    label: "0.7x",
    value: 0.7,
  },
  {
    label: "0.8x",
    value: 0.8,
  },
  {
    label: "0.9x",
    value: 0.9,
  },
  {
    label: "1x",
    value: 1,
  },
  {
    label: "1.1x",
    value: 1.1,
  },
  {
    label: "1.2x",
    value: 1.2,
  },
  {
    label: "1.3x",
    value: 1.3,
  },
  {
    label: "1.4x",
    value: 1.4,
  },
  {
    label: "1.5x",
    value: 1.5,
  },
];

interface Props {
  onSpeedSelect: (speed: number) => void;
}

export function SpeedOptions({ onSpeedSelect }: Props) {
  return (
    <Grid gridTemplateColumns={`repeat(${SPEED_OPTIONS.length}, 1fr)`}>
      {SPEED_OPTIONS.map(option => (
        <SpeedOption
          key={option.label}
          onClick={() => onSpeedSelect(option.value)}
        >
          {option.label}
        </SpeedOption>
      ))}
    </Grid>
  );
}
