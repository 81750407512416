import React, { useState } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";
import Flex from "app/components/Flex";
import Icon, { Star } from "app/components/Icon";

const Ratings = ({ onRate, total, rating: ratingProp, ...rest }) => {
  const [rating, setRating] = useState(ratingProp);
  const [lastRating, setLastRating] = useState(ratingProp);

  const setRate = updatedRating => {
    setRating(updatedRating);
    setLastRating(updatedRating);

    if (onRate) {
      onRate(updatedRating);
    }
  };

  return (
    <Flex onMouseLeave={() => setRating(lastRating)} {...rest}>
      {Array(total)
        .fill(null)
        .map((star, index) => (
          <Flex
            // eslint-disable-next-line react/no-array-index-key
            key={`rating-${index}`}
            onClick={() => {
              setRate(index + 1);
            }}
            onMouseEnter={() => setRating(index + 1)}
            mr={2}
            cursor="pointer"
          >
            <Icon
              width="32px"
              height="32px"
              as={Star}
              color={index < rating ? "gold" : "white"}
            />
          </Flex>
        ))}
    </Flex>
  );
};

Ratings.defaultProps = {
  total: 5,
  rating: 0,
  onRate: () => {},
};

Ratings.propTypes = {
  total: PropTypes.number,
  rating: PropTypes.number,
  theme: PropTypes.shape({}).isRequired,
  onRate: PropTypes.func,
};

export default withTheme(Ratings);
