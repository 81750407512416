import React from "react";
import { BillingPeriodUnit, Plan } from "services/graphql";
import { PeriodUnitAdverbs } from "constants/cart";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import styles from "./styles.module.scss";
import { PlanCard } from "./PlanCard";

interface Props {
  headerText: string;
  plans: Plan[];
  selectedBillingPeriod: BillingPeriodUnit;
  setSelectedBillingPeriod(billingPeriod: BillingPeriodUnit): void;
  currentPlan?: Partial<Plan>;
}

export function PlanSelect({
  plans,
  selectedBillingPeriod,
  setSelectedBillingPeriod,
  currentPlan,
}: Props) {
  if (!plans) {
    return <LoaderCentered />;
  }

  return (
    <>
      <div className={styles.planSelectContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.flexBetweenContainer}>
            <h2 className={styles.subheader}>Manage Your Plan Below</h2>
            <div className={styles.billingPeriodButtonContainer}>
              <button
                type="button"
                className={
                  selectedBillingPeriod === BillingPeriodUnit.Month
                    ? styles.selectedBillingPeriodButton
                    : styles.billingPeriodButton
                }
                onClick={() =>
                  setSelectedBillingPeriod(BillingPeriodUnit.Month)
                }
              >
                Monthly
              </button>
              <button
                type="button"
                className={
                  selectedBillingPeriod === BillingPeriodUnit.Year
                    ? styles.selectedBillingPeriodButton
                    : styles.billingPeriodButton
                }
                onClick={() => setSelectedBillingPeriod(BillingPeriodUnit.Year)}
              >
                Yearly
              </button>
            </div>
          </div>
          {currentPlan && (
            <h4 className={styles.h4}>
              Your Current Plan:{" "}
              {
                PeriodUnitAdverbs[
                  currentPlan.periodUnit as keyof typeof PeriodUnitAdverbs
                ]
              }{" "}
              {currentPlan.accessType}
            </h4>
          )}
        </div>
        <div className={styles.plansContainer}>
          {plans &&
            plans.map(plan => {
              return <PlanCard plan={plan} />;
            })}
        </div>
      </div>
    </>
  );
}
