import React from "react";
import PropTypes from "prop-types";
import { useCanUserTakeClass } from "hooks/Classes";
import Player from "./Player";
import Wrapper from "./components/Wrapper";

const ClassPlayer = ({
  classData,
  nextClassData,
  classVideo,
  scrollToClassDetails,
  ...rest
}) => {
  const { canUserTakeClass } = useCanUserTakeClass({
    classId: classData.id,
  });

  return (
    <Wrapper>
      <Player
        classData={classData}
        nextClassData={nextClassData}
        classVideo={classVideo}
        isUserAllowedToAccessClass={canUserTakeClass}
        scrollToClassDetails={scrollToClassDetails}
        {...rest}
      />
    </Wrapper>
  );
};

ClassPlayer.propTypes = {
  classData: PropTypes.shape({}).isRequired,
  nextClassRefId: PropTypes.string.isRequired,
  nextClassId: PropTypes.string.isRequired,
  classVideo: PropTypes.shape({
    id: PropTypes.number,
    sources: PropTypes.shape({
      hlsSource: PropTypes.string,
    }),
  }),
};

export default ClassPlayer;
