import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import env from "helpers/env";
import { Elements as ElementsProvider } from "@stripe/react-stripe-js";
import { useCheckout } from "modules/selectors";
import { PaymentMethodType, PaymentSource } from "services/graphql";
import { usePrimaryPaymentSource } from "hooks/Subscriptions/usePrimaryPaymentSource";
import SpanLink from "app/components/SpanLink";
import zendesk from "helpers/zendesk";
import Button from "app/components/Button";
import useSubscriptionCreateForCustomer from "hooks/Checkout/useSubscriptionCreateForCustomer";
import Alert from "app/components/Alert";
import { UpdatePaymentMethod } from "app/components/Checkout/UpdatePaymentMethod";
import { convertPaymentSourceToString } from "helpers/convertPaymentSourceToString";
import styles from "./styles.module.scss";
import { Payment } from "../Payment";
import { PlanCard } from "../PlanSelect/PlanCard";
import { CheckoutHeader } from "../CheckoutHeader";
import { useSubscribeModalContext } from "../hooks";

const stripePromise = loadStripe(env("PUBLIC_STRIPE_PUBLISHABLE_KEY"));

export function CheckoutFlow({
  setIsCheckingOut,
}: {
  setIsCheckingOut(isCheckingOut: boolean): void;
}) {
  const { selectedPlan } = useCheckout();
  const {
    primaryPaymentSource,
    loadingPrimaryPaymentSource,
  } = usePrimaryPaymentSource();

  return (
    <div>
      <div className={styles.checkoutContainer}>
        <PlanCard plan={selectedPlan} />
        <div className={styles.sectionContainer}>
          <CheckoutHeader setIsCheckingOut={setIsCheckingOut} />
          {!loadingPrimaryPaymentSource && (
            <PaymentMethod primaryPaymentSource={primaryPaymentSource} />
          )}
        </div>
      </div>
    </div>
  );
}

function PaymentMethod({
  primaryPaymentSource,
}: {
  primaryPaymentSource: Partial<PaymentSource>;
}) {
  const { closeModal } = useSubscribeModalContext();
  const {
    subscriptionCreateForCustomer,
    subscriptionCreateForCustomerResults,
  } = useSubscriptionCreateForCustomer({ onSuccess: closeModal });
  const [isUpdatingPaymentMethod, setIsUpdatingPaymentMethod] = useState(false);

  if (!primaryPaymentSource) {
    return (
      <ElementsProvider stripe={stripePromise}>
        <Payment />
      </ElementsProvider>
    );
  }

  if (isUpdatingPaymentMethod) {
    return (
      <div className={styles.paymentContainer}>
        <UpdatePaymentMethod
          onSuccess={() => setIsUpdatingPaymentMethod(false)}
          onCancel={() => setIsUpdatingPaymentMethod(false)}
        />
      </div>
    );
  }

  const { type } = primaryPaymentSource;

  const { paymentMethodCopy, subscribeCopy } = convertPaymentSourceToString(
    primaryPaymentSource
  );

  switch (type) {
    case PaymentMethodType.Card:
    case PaymentMethodType.GooglePay:
    case PaymentMethodType.ApplePay:
    case PaymentMethodType.PaypalExpressCheckout:
      return (
        <div className={styles.paymentContainer}>
          <div className={styles.paymentMethodContainer}>
            <header>Current Payment Method</header>
            <p>
              {paymentMethodCopy}
              <div>
                <SpanLink onClick={() => setIsUpdatingPaymentMethod(true)}>
                  Update Payment Method
                </SpanLink>
              </div>
            </p>
          </div>
          <Button
            disabled={subscriptionCreateForCustomerResults.loading}
            onClick={() => subscriptionCreateForCustomer()}
          >
            {subscribeCopy}
          </Button>
        </div>
      );
    default:
      return (
        <Alert variant="danger">
          Unable to load payment method. Please
          <SpanLink onClick={() => zendesk("webWidget", "open")}>
            {" "}
            contact us for help!
          </SpanLink>
        </Alert>
      );
  }
}
