/* eslint-disable no-param-reassign */
// player api expected from react-player package
export const getReactPlayer = ({ videoEl, onLoaded, onError }) => ({
  load: url => {
    videoEl.src = url;
    onLoaded();
  },
  stop: () => {
    videoEl.removeAttribute("src");
  },
  play: () => {
    const promise = videoEl.play();
    if (promise) {
      promise.catch(onError);
    }
  },
  pause: () => {
    videoEl.pause();
  },
  setVolume: volume => {
    videoEl.volume = volume;
  },
  mute: () => {
    videoEl.muted = true;
  },
  unmute: () => {
    videoEl.muted = false;
  },
  setPlaybackRate: rate => {
    try {
      videoEl.playbackRate = rate;
    } catch (e) {
      onError(e);
    }
  },
  getDuration: () => {
    return videoEl.duration;
  },
  getCurrentTime: () => {
    return videoEl.currentTime;
  },
  getSecondsLoaded: () => {
    const { buffered, duration } = videoEl;
    if (buffered.length === 0) {
      return 0;
    }
    const end = buffered.end(buffered.length - 1);
    if (end > duration) {
      return duration;
    }
    return end;
  },
  seekTo: seconds => {
    videoEl.currentTime = seconds;
  },
  customreacthls: videoEl,
});
