import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import transition from "styled-transition-group";
import convertTimeStringToSeconds from "helpers/convertTimeStringToSeconds";
import { USE_PLAYBACK_CONTROL } from "constants/eventTitles";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { P1, H2, H3 } from "app/components/Typography";
import Icon, { ChevronRight, Loop } from "app/components/Icon";
import Scrollbar from "app/components/Scrollbar";
import IconButton from "app/components/Button/IconButton";

const Wrapper = styled.div`
  top: 0;
  right: 0;
  z-index: 3;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  letter-spacing: 1px;
  height: 100%;
  ${({ showSections }) =>
    showSections &&
    `
    width: 100%;`};
`;

const SectionsWrapper = transition.div`
  width: 350px;
  background: rgba(34, 34, 34, 0.8);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 80%;
  max-height: 80%;
  color: ${({ theme }) => theme.colors.white};
  transition: opacity 150ms ease-in, width 200ms;

  ${Scrollbar}

  &:enter {
    width: 0;
    opacity: 0;
  }

  &:enter-active {
    width: 350px;
    transition: opacity 150ms ease-in, width 200ms;
    opacity: 1;
  }

  &:exit {
    width: 350px;
    opacity: 1;
  }

  &:exit-active {
    width: 0;
    transition: width 200ms ease-in, opacity 150ms;
    opacity: 0;
  }
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const Cuepoint = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.yellow : theme.colors.white};
  padding: 10px 0;
  font-size: 14px;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.gold};
  }
`;

const getNextCuepointSeconds = (
  classDurationInSeconds,
  cuepointIndex,
  sectionIndex,
  sections
) => {
  const section = sections[sectionIndex];
  const nextSection = sections[sectionIndex + 1];
  const nextCuepoint =
    section.cuepoints[cuepointIndex + 1] ||
    (nextSection ? nextSection.cuepoints[0] : null);
  const nextTime = nextCuepoint ? nextCuepoint.time : null;
  return nextTime
    ? convertTimeStringToSeconds(nextTime)
    : classDurationInSeconds;
};

const isCurrentCuepoint = (
  currentTimeInSeconds,
  cuepointSeconds,
  nextCuepointSeconds
) =>
  currentTimeInSeconds >= cuepointSeconds &&
  currentTimeInSeconds < nextCuepointSeconds;

const VideoSections = ({
  classTitle,
  currentTimeInSeconds,
  classDurationInSeconds,
  isLooping,
  seek,
  sections,
  showSections,
  toggleSections,
  setSectionName,
  setCuepointName,
  classId,
  partySessionId,
  isPartyApprovedClass,
}) => (
  <Wrapper showSections={showSections}>
    {showSections && (
      <CloseWrapper onClick={() => toggleSections(!showSections)} />
    )}
    <SectionsWrapper unmountOnExit timeout={1000} in={showSections}>
      <Div justifyContent="space-between" p={3} bg="black">
        <P1 color="monochrome.3">{classTitle}</P1>
        <Flex
          mt={2}
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <H2>Class Sections</H2>
          <IconButton
            Icon={ChevronRight}
            color="white"
            onClick={() => toggleSections(false)}
          />
        </Flex>
      </Div>
      <Div p={3} overflow="auto">
        {sections &&
          sections.map((section, sectionIndex) => (
            <div key={section.name}>
              <H3>{section.name}</H3>
              {section.cuepoints &&
                section.cuepoints.map((cuepoint, cuepointIndex) => {
                  const cuepointSeconds = convertTimeStringToSeconds(
                    cuepoint.time
                  );
                  const nextCuepointSeconds = getNextCuepointSeconds(
                    classDurationInSeconds,
                    cuepointIndex,
                    sectionIndex,
                    sections
                  );
                  const isSelected = isCurrentCuepoint(
                    currentTimeInSeconds,
                    cuepointSeconds,
                    nextCuepointSeconds
                  );

                  if (isSelected) {
                    setSectionName(section.name);
                    setCuepointName(cuepoint.name);
                  }

                  return (
                    <Cuepoint
                      key={cuepoint.time}
                      isSelected={isSelected}
                      onClick={() => {
                        window.analytics.track(USE_PLAYBACK_CONTROL, {
                          class_id: classId,
                          control_used: "SelectSection",
                          party_session_id: partySessionId,
                          in_a_party: isPartyApprovedClass,
                          section_name: section.name,
                          cuepoint_name: cuepoint.name,
                        });
                        seek(cuepointSeconds, nextCuepointSeconds);
                      }}
                    >
                      <Div width="30px">
                        {isSelected && <Icon height="10px" as={ChevronRight} />}
                      </Div>
                      <P1>
                        {cuepoint.time} &mdash; {cuepoint.name}
                      </P1>
                      {isLooping && isSelected && (
                        <Icon ml={3} height="16px" as={Loop} />
                      )}
                    </Cuepoint>
                  );
                })}
            </div>
          ))}
      </Div>
    </SectionsWrapper>
  </Wrapper>
);

VideoSections.defaultProps = {
  isLooping: false,
  sections: null,
};

VideoSections.propTypes = {
  classTitle: PropTypes.string.isRequired,
  currentTimeInSeconds: PropTypes.number.isRequired,
  classDurationInSeconds: PropTypes.number.isRequired,
  isLooping: PropTypes.bool,
  seek: PropTypes.func.isRequired,
  sections: PropTypes.instanceOf(Array),
  showSections: PropTypes.bool.isRequired,
  toggleSections: PropTypes.func.isRequired,
  setSectionName: PropTypes.func.isRequired,
  setCuepointName: PropTypes.func.isRequired,
  classId: PropTypes.string.isRequired,
  partySessionId: PropTypes.string.isRequired,
  isPartyApprovedClass: PropTypes.bool.isRequired,
};

export default VideoSections;
